<template>
  <b-container fluid>
    <!-- Horizontal Category Menu -->
    <b-navbar class="category-navbar mb-3">
      <b-nav>
        <!-- Dropdown for categories with subcategories -->
        <b-nav-item-dropdown
            v-for="category in categoriesWithSubCategories"
            :key="category.id"
            :text="category.description"
            @click="selectCategory(category.id)"
            class="mx-1 category-dropdown"
        >
          <b-dropdown-item
              v-for="subCategory in category.subCategories"
              :key="subCategory.id"
              @click="selectCategory(category.id)"
          >
            {{ subCategory.description }}
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <!-- Simple nav item for categories without subcategories -->
        <b-nav-item
            v-for="category in categoriesWithoutSubCategories"
            :key="category.id"
            @click="selectCategory(category.id)"
            class="mx-1 mb-1 category-nav-item"
        >
          {{ category.description }}
        </b-nav-item>
      </b-nav>
    </b-navbar>
  </b-container>
</template>

<script>

export default {
  props: {
    categories: {
      type: Array,
      required: false,
    },
  },
  computed: {
    sortedCategories() {
      // Check if categories is defined and is an array
      return this.categories && Array.isArray(this.categories)
          ? [...this.categories].sort((a, b) => a.categoryOrder - b.categoryOrder)
          : [];
    },
    categoriesWithSubCategories() {
      return this.sortedCategories.filter(
          (category) => category.subCategories && category.subCategories.length
      );
    },
    categoriesWithoutSubCategories() {
      return this.sortedCategories.filter(
          (category) => !category.subCategories || !category.subCategories.length
      );
    },
  },
  methods: {
    selectCategory(categoryId) {
      this.$emit('category-selected', categoryId);
    }
  },
  mounted() {
    if (this.sortedCategories.length) {
      this.$emit('load-items', this.sortedCategories[0]);
    }
  },
};
</script>

<style scoped>
.category-nav-item {
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 0.25rem;
  width: 100%
}
.category-nav-item:hover {
  background-color: #fff;
  color: #66192D;
}

.category-navbar {
  background-color: var(--main-color);
  border-radius: 0.25rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.category-navbar ul {
  background-color: var(--main-color);
  border-radius: 0.25rem;
}

.category-navbar ul li a {
  color: var(--btn-text-color);
}

.category-navbar ul li a:hover {
  color: var(--main-color);
}

.category-navbar .b-nav-item,
.category-navbar .b-nav-item-dropdown {
  color: #fff;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.category-navbar .b-nav-item:hover,
.category-navbar .b-nav-item-dropdown:hover,
.category-navbar .b-dropdown-item:hover {
  background-color: #fff;
  color: #66192D;
}

.category-navbar .b-nav-item.active {
  font-weight: bold;
  color: #fff;
  background-color: #6c757d;
}

.category-navbar .category-dropdown .b-dropdown-item {
  color: #66192D;
  transition: color 0.3s ease;
}

.category-navbar .category-dropdown .b-dropdown-item:hover {
  color: #fff;
  background-color: #66192D;
}
</style>
