<template>

  <div id="app" class="col-12">
    <div v-show="appIsIdle" @click="refreshPage" style="position:fixed;width:100%; height:100%;top:0;left:0;right:0;bottom:0; background:rgba(108, 122, 137, 0.8) ; color:var(--main-color-highlight); z-index:99999999999999999999999999999999999999999999999999999;">
      <div style="padding-top:15vh;">
        {{ $t('text.sessionTerminated') }}
      </div>
      <img class="appIdleIcon" src="@/assets/images/excl-point.png"/>
    </div>

    <div v-bind:class="{ appIsIdle: appIsIdle}" class="bodyScroller">

      <div style="position: fixed; z-index: 99999; left:2%" ><a class="sr-only sr-only-focusable bg-dark text-light" href="#main">Skip to main content</a></div>

      <header class="noMargin header" role="banner">
        <GlobalHeader />
      </header>
      <div id="scrollingContent">
        <main role="main" id="main">
          <div class="oneWayScroll content col-12" id="contentSection">
            <!-- This is where pages get rendered, managed by the vue-router module -->
            <router-view id="content-wrapper" class="oneWayScroll col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" />
          </div>
        </main>
        <footer id="footer" role="contentinfo" class="noMargin col-12" style="padding:0;margin:0;">
          <!--<GlobalFooter />-->
          <global-footer-old :footerLinks="config.FOOTER.links" :config="config"></global-footer-old>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import 'vue-loading-overlay/dist/vue-loading.css'
import './css/app.scss'
import GlobalHeader from "@/components/layout-components/GlobalHeader.vue";
import GlobalFooterOld from "@/components/layout-components/GlobalFooterOld.vue";
import {mapGetters} from "vuex";
import {SessionTimer, isAppIdle} from '@/store/SessionTimer'

export default {
  name: 'App',
  computed: {
    config() {
      return window.config;
    },
    currentLocale() {
      return this.getLocale();
    },
    appIsIdle(){
      return isAppIdle();
    },
  },
  components: {
    GlobalHeader,
    GlobalFooterOld
  },
  destroyed() {
    this.timer.onUnmount();
    document.getElementById("app").removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapGetters('locale', ['getLocale']),
    refreshPage() {
      document.location.reload();
    },
    handleWheel(event) {
      if (event != undefined)
      {
        if (event.originalEvent.deltaY < 0)
        {
          this.maximizeHeader();
        }
        else if (event.originalEvent.deltaY > 0)
        {
          this.minimizeHeader();
        }
      }
    },
    handleWheelContent(event) {
      if (undefined !== event?.originalEvent) {
        console.log(event.originalEvent.deltaY);
        if (event.originalEvent.deltaY < 0 && $("#content-wrapper").scrollTop() == 0)
        {
          this.maximizeHeader();
        }
        else if (event.originalEvent.deltaY > 0)
        {
          if($(".header").hasClass("header-big"))
          {
            var myDiv = document.getElementById('content-wrapper');

            myDiv.scrollTop = 0;
            this.minimizeHeader();
          }
        }
      }
    },
    maximizeHeader() {
      var config = window.config;
      if(window.location.href.includes('/Shop') && config.SHOW_GLOBAL_HEADER_BANNER_IMAGE)
      {
        $(".header").addClass("header-big");
        $(".header").addClass("animTransition");
        $(".content").addClass("header-big-content-margin");
        $(".content").removeClass("header-small-content-margin");
      }

    },
    minimizeHeader() {
      $(".header").removeClass("header-big");
      $(".header").addClass("animTransition");
      $(".content").removeClass("header-big-content-margin");
      $(".content").addClass("header-small-content-margin");
    },
    handleScroll() {
      if ($("#app").scrollTop() == 0) {
        this.maximizeHeader();
      }
      else {
        this.minimizeHeader();
      }

      // Any code to be executed when the window is scrolled
    },
    handleScrollContent() {
      if ($("#content-wrapper").scrollTop() == 0) {
        this.maximizeHeader();
      }
      else {
        this.minimizeHeader();
      }
    },
    handleSizeChanges(main) {
      // find element with: Array.from(document.all).filter(a=>a.clientHeight < a.scrollHeight) ; when scrollbar active
      var visibleHeight = main.clientHeight;
      var innerHeight = main.scrollHeight;
      if (visibleHeight < innerHeight) {
        main.parentElement.classList.add("scrollbarActive");
      } else {
        main.parentElement.classList.remove("scrollbarActive");
      }
    },
    handleMobileKeyboardChanges(event) {
      const { height } = event.target.boundingRect;
      document.body.style.cssText="--keyboardHeight:"+height+"px;";
      document.querySelector(".fontTitle").innerText=height;
    },
    repeat() {
      var cookiesEnabled = true;
      var cookieEnabled = navigator.cookieEnabled;

      // When cookieEnabled flag is present and false then cookies are disabled.
      if (cookieEnabled === false) {
        cookiesEnabled = false;
      }

      // try to set a test cookie if we can't see any cookies and we're using
      // either a browser that doesn't support navigator.cookieEnabled
      // or IE (which always returns true for navigator.cookieEnabled)
      if (!document.cookie && (cookieEnabled === null || /*@cc_on!@*/false)) {
        document.cookie = "testcookie=1";

        if (!document.cookie) {
          cookiesEnabled = false;
        } else {
          document.cookie = "testcookie=; expires=" + new Date(0).toUTCString();
        }
      }

      if (!cookiesEnabled) {
        console.log("cookies are not enabled");
      }

      if (this.appIsIdle) {
        console.log("Could not find request timestamp, session stopped!");
        clearInterval(this.intervalId);
        return;
      }

      //Update document title
      if (this.config.USE_EXTENDED_PAGETITLE && this.config.IS_PURE_ACCOUNTMANAGER) {
        var remainingTimeMessage = "";

        if (this.config.SHOW_TITLE_LOGOUT_TIMER) {
          var remainingTimeInMillisecounds = this.timer.activeTimer;
          if (remainingTimeInMillisecounds > 0) {
            var timeString = "";
            if ((remainingTimeInMillisecounds / 60000) > 1) {
              timeString = Math.round(remainingTimeInMillisecounds / 60000) + " min";
            } else {
              timeString = "< 1 min";
            }
            remainingTimeMessage = this.$t('text.timeRemaining') + "" + timeString + " - ";
          }
        }

        // TITLE: [text.timeRemaining]30 - [title.webShop] - Home
        document.title = remainingTimeMessage + this.$t('title.webShop') + " - " + this.$route.name;
      }
    },
  },
  data() {
    return {
      isConfigLoaded: false,
      configLoaded: {},
      mobileView: true,
      showNav: false,
      intervalId: 0,
      appIsIdleBlur: "appIdleBlur",
      timer: undefined,
    }
  },
  async mounted() {
    var vueInstance = this;
    var config = window.config;

    this.timer = SessionTimer();
    this.timer.onMount();

    //Version Number:
    console.log("2.0.52");

    // Check cookies:
    if (window.config.COOKIES_ENABLED) {
      if (this.$cookies.isKey('TableMode')) {
        if (this.$cookies.get('TableMode') == true || this.$cookies.get('TableMode').toUpperCase() === "TRUE") {
          window.TableMode = this.$cookies.get('TableMode').toUpperCase() === "TRUE";
        }
        if (window.TableMode) {
          Object.assign(window.config, window.configTablemode);
        }
      }
      if (this.$cookies.isKey('tableId')) {
        window.tableId = this.$cookies.get('tableId');
        window.tableModeTable = this.$cookies.get('tableId');
      } else {
        // RETURN TO TABLE LANDING?
      }
      if (this.$cookies.isKey('shopId')) {
        window.shopId = this.$cookies.get('shopId');
      }
      if (this.$cookies.isKey('orderType')) {
        window.orderType = this.$cookies.get('orderType');
      }
      if (this.$cookies.isKey('immediate')) {
        window.immediate = this.$cookies.get('immediate');
      }
    }

    this.$i18n.locale = this.currentLocale;

    if (this.intervalId !== 0) {
      clearInterval(this.intervalId);
    }
    this.intervalId = setInterval(this.repeat, 1000);

    //BIND EVENTS
    $("#app").bind("scroll", function () {
      vueInstance.handleScroll();
    });

    $("#siteHeader").bind("wheel", function (event) {
      vueInstance.handleWheel(event);
    });

    $("#content-wrapper").bind("wheel", function (event) {
      vueInstance.handleWheelContent(event);
    });

    $("#content-wrapper").bind("scroll", function (event) {
      vueInstance.handleScrollContent(event);
    });

    /* region Resize Observer - ScrollBar Searcher */
    if (this.resizeObserver !== undefined) {
      this.resizeObserver.disconnect();
    }
    this.resizeObserver = new ResizeObserver(function (entries) {
      vueInstance.handleSizeChanges(entries[0].target);
    });

    this.resizeObserver.observe(document.querySelector("#scrollingContent"),{box:"content-box"});

    if ("virtualKeyboard" in navigator) {
      navigator.virtualKeyboard.overlaysContent=true;
      navigator.virtualKeyboard.addEventListener("geometrychange",vueInstance.handleMobileKeyboardChanges);
    }

    /* endregion */

    if (config.SHOW_GLOBAL_HEADER_BANNER_IMAGE == true) {
      $("#siteHeader").bind("click", function () {
        if ($('#siteHeader').hasClass('header-big')) {
          vueInstance.minimizeHeader();
        } else {
          vueInstance.maximizeHeader();
        }
      });
    }

    if (!window.location.href.includes('/loginSSO') && !window.location.href.includes('/errorSSO') && !window.location.href.includes('/studentAccounts') && !window.location.href.includes('/ErrorSSO') && !window.location.href.includes('/PaymentEnd') && !window.location.href.includes('/Login') && !window.location.href.includes('/QRLanding') && !window.location.href.includes('/Confirm')) {
      // AP 20220509 T60190W WAM redirect to Account instead of /Shop
      if (!config.IS_PURE_ACCOUNTMANAGER) {
        if (config.TABLE_CHECKOUT.enabled) {
          if (window.shopId != undefined && window.immediate) {
            let path = "/QRLanding?s=" + window.shopId + "&i=" + window.immediate;
            await vueInstance.$router.push({path: path});
          } else if (window.shopId != undefined && window.tableId != undefined) {
            //shop und tableid vorhanden?
            if (window.jwt != undefined || window.ssoCode != undefined) {
              //mit shop tableid und dem  logincde zum ding senden
              let path = "/QRLanding?s=" + window.shopId + "&t=" + window.tableId;
              if (window.jwt != undefined) {
                path = path + "&id_token=" + window.jwt;
              } else if (window.ssoCode != undefined) {
                path = path + "&code=" + window.ssoCode;
              }

              window.jwt = undefined;
              window.ssoCode = undefined;
              await vueInstance.$router.push({path: path});
            } else {
              //nur mit shop und tableid senden
              let path = "/QRLanding?s=" + window.shopId + "&t=" + window.tableId;
              await vueInstance.$router.push({path: path});
            }
          } else {
            await vueInstance.$router.push({path: "/" && vueInstance.config.TABLE_CHECKOUT.homepage });
          }
        }
      } else {
        if (config.RELOAD_START_PAGE.enabled) {
          if (!vueInstance.$route.path == config.RELOAD_START_PAGE.path ) {
            await vueInstance.$router.push({ path: config.RELOAD_START_PAGE.path });
          }
        } else {
          await vueInstance.$router.push({ path: '/Account' });
        }
      }
    }
  },
  async updated() {
    this.handleSizeChanges(document.querySelector("#scrollingContent"));
  }
}
</script>
<style scoped>
</style>
