<template>
  <div class="flex-spin-button" role="spinbutton"
       :aria-valuemin="min"
       :aria-valuemax="max"
       :aria-valuenow="value"
       tabindex="0"
       @keydown="onKeydown">
    <button class="btn btn-minus" @click="decrement" aria-label="Decrease value" :disabled="value <= min">
      <span aria-hidden="true">−</span>
    </button>

    <input
        type="text"
        :value="value"
        @input="onInput"
        aria-valuemin="min"
        aria-valuemax="max"
        aria-valuenow="value"
        aria-label="Spin Button Value"
    />

    <button class="btn btn-plus" @click="increment" aria-label="Increase value" :disabled="value >= max">
      <span aria-hidden="true">+</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'FlexSpinButton',
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    step: {
      type: Number,
      default: 1,
    }
  },
  data() {
    return {
      value: this.modelValue,
    };
  },
  watch: {
    modelValue(newVal) {
      this.value = newVal;
    }
  },
  methods: {
    increment() {
      if (this.value < this.max) {
        this.value = Math.min(this.value + this.step, this.max);
        this.$emit('update:modelValue', this.value);
      }
    },
    decrement() {
      if (this.value > this.min) {
        this.value = Math.max(this.value - this.step, this.min);
        this.$emit('update:modelValue', this.value);
      }
    },
    onKeydown(e) {
      if (e.key === 'ArrowUp') {
        this.increment();
      } else if (e.key === 'ArrowDown') {
        this.decrement();
      }
    },
    onInput(e) {
      const newValue = parseInt(e.target.value, 10);
      if (!isNaN(newValue) && newValue >= this.min && newValue <= this.max) {
        this.value = newValue;
        this.$emit('update:modelValue', this.value);
      }
    }
  }
};
</script>

<style scoped>
.flex-spin-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

input {
  text-align: center;
  width: 60px;
  padding: 5px;
  margin: 0 10px;
  font-size: 16px;
}

button {
  font-size: 20px;
  padding: 5px 10px;
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 4px;
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>