<template>

  <b-container fluid>
    <div class="dashboard">
      <div class="dashboard-content grid-layout">

        <button v-for="item in links" :key="item.key" class="btn btn-primary btn-lg btnSubmit mx-0 w-100 noMargin dashboard-item" @click="item.action()">
          <div class="icon">
            <font-awesome-icon :icon="['fas', item.icon]" />
          </div>
          <div class="title">
            <h2>{{ item.name() }}</h2>
          </div>
        </button>

        <!--
        <button class="dashboard-item" @click="navigateToTemplate">
          <div class="icon">
            <font-awesome-icon :icon="['fas', 'user']" />
          </div>
          <div class="title">
            <h2>Template</h2>
          </div>
        </button>

        <button class="dashboard-item" @click="navigateToAccount">
          <div class="icon">
            <font-awesome-icon :icon="['fas', 'user']" />
          </div>
          <div class="title">
            <h2>{{ $t('title.account') }}</h2>
          </div>
        </button>

        <button v-if="config.ORDER.enabled" class="dashboard-item" @click="navigateToShop">
          <div class="icon">
            <font-awesome-icon :icon="['fas', 'burger']" />
          </div>
          <div class="title">
            <h2>{{ $t('title.order') }}</h2>
          </div>
        </button>

        <button v-if="config.MEAL_PLAN.enabled" class="dashboard-item" @click="navigateToShopOverview(true)">
          <div class="icon">
            <font-awesome-icon :icon="['fas', 'calendar-days']" />
          </div>
          <div class="title">
            <h2>{{ $t('title.mealPlan') }}</h2>
          </div>
        </button>

        <button class="dashboard-item" @click="navigateToCustomerCard">
          <div class="icon">
            <font-awesome-icon :icon="['fas', 'qrcode']" />
          </div>
          <div class="title">
            <h2>{{ $t('title.customerCard') }}</h2>
          </div>
        </button>

        <button v-if="config.ORDER_HISTORY.enabled" class="dashboard-item" @click="navigateToOrderHistory">
          <div class="icon">
            <font-awesome-icon :icon="['fas', 'rectangle-list']" />
          </div>
          <div class="title">
            <h2>{{ $t('title.orderHistory') }}</h2>
          </div>
        </button>

        <button v-if="config.TRANSACTION_HISTORY.enabled" class="dashboard-item" @click="navigateToTransactionHistory">
          <div class="icon">
            <font-awesome-icon :icon="['fas', 'receipt']" />
          </div>
          <div class="title">
            <h2>{{ $t('title.transactionHistory') }}</h2>
          </div>
        </button>

        <button v-if="config.STUDENT_ACCOUNTS.enabled" class="dashboard-item" @click="navigateToStudentAccounts">
          <div class="icon">
            <font-awesome-icon :icon="['fas', 'user-graduate']" />
          </div>
          <div class="title">
            <h2>{{ $t('title.studentAccounts') }}</h2>
          </div>
        </button>-->
      </div>
    </div>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      items:[
        //{key: 0, name: ()=>'Template', action: this.navigateToTemplate, icon: 'user'},
        {key: 1, name: ()=>this.$t('title.customerCard'), action: this.navigateToCustomerCard, icon: 'qrcode'},
        {key: 2, name: ()=>this.$t('title.account'), action: this.navigateToAccount, icon: 'user'},
        {key: 3, name: ()=>this.$t('title.order'), action: this.navigateToShop, icon: 'burger', visible: ()=>this.config.ORDER.enabled},
        {key: 4, name: ()=>this.$t('title.mealPlan'), action: this.navigateToShopOverview.bind(this, true), icon: 'calendar-days', visible: ()=>this.config.MEAL_PLAN.enabled},
        {key: 5, name: ()=>this.$t('title.orderHistory'), action: this.navigateToOrderHistory, icon: 'rectangle-list', visible: ()=>this.config.ORDER_HISTORY.enabled},
        {key: 6, name: ()=>this.$t('title.transactionHistory'), action: this.navigateToTransactionHistory, icon: 'receipt', visible: ()=>this.config.TRANSACTION_HISTORY.enabled},
        {key: 7, name: ()=>this.$t('title.studentAccounts'), action: this.navigateToStudentAccounts, icon: 'user-graduate', visible: ()=>this.config.STUDENT_ACCOUNTS.enabled},
      ],
    };
  },
  computed: {
    config() {
      return window.config;
    },
    links(){
      return this.$data.items.filter(function(item){return item.visible===undefined || item.visible();});
    },
  },
  methods: {
    navigateToTemplate() {
      this.$navigateToTemplate();
    },
    navigateToAccount() {
      this.$navigateToAccount();
    },
    navigateToShop() {
      this.$navigateToShop();
    },
    navigateToShopOverview(isMealPlan) {
      this.$navigateToShopOverview(isMealPlan);
    },
    navigateToCustomerCard() {
      this.$navigateToCustomerCard();
    },
    navigateToTransactionHistory() {
      this.$navigateToTransactionHistory();
    },
    navigateToOrderHistory() {
      this.$navigateToOrderHistory();
    },
    navigateToStudentAccounts() {
      this.$navigateToStudentAccounts();
    },
    isLoggedin() {
      return null!==window.customerLoggedIn && undefined !== window.customerLoggedIn;
    }
  },
  mounted() {
    if (!this.isLoggedin() && this.config.FORCE_LOGIN_BEFORE_USE) {
      this.$router.push({name: 'Login'})
    }
  },
};
</script>

<style scoped>
.dashboard {
  padding: 20px 0;
  //background-color: #f9f9f9;
}

.dashboard-content {
  //display: flex;
  //flex-wrap: wrap;
  //gap: 20px; /* Add spacing between items */
  //margin-top: 10px;
}

.dashboard-item {
  flex: 1 1 calc(30% - 20px); /* Responsive width with some margin */
  background-color: var(--dashboard-item-background-color);
  color: var(--dashboard-item-text-color);
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  align-items: center; /* Vertically center icon and title */
  cursor: pointer;
  transition: background-color 0.3s;
  gap: 20px;
  &:hover {
    color: var(--dashboard-item-background-color);
  }
}

.dashboard-item:hover {
  background-color: #f0f0f0; /* Change background on hover */
}

.icon {
  //margin-right: 20px; /* Space between icon and title */
  font-size: 24px;
  color: currentColor;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 1.2em;
}

h2 {
  margin: 0;
  font-size: 1.2rem;
}
</style>