import { render, staticRenderFns } from "./TransactionDetails.vue?vue&type=template&id=ff563dc6&scoped=true&"
import script from "./TransactionDetails.vue?vue&type=script&lang=js&"
export * from "./TransactionDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff563dc6",
  null
  
)

export default component.exports