// src/store/modules/shops.js
import {shopService} from "@/services/api/shopService";

const state = {
    shops: [], // List of shops
    selectedShop: null, // Selected shop
};

const getters = {
    getShops: (state) => state.shops,
    selectedShop: (state) => state.selectedShop,
};

const actions = {
    // Action to fetch the list of shops
    async fetchShops({ commit }) {
        const shops = await shopService.getShops(false);
        commit('setShops', shops);
    },

    // Action to select a shop
    selectShop({ commit }, shop) {
        commit('setSelectedShop', shop);
    },
};

const mutations = {
    setShops: (state, shops) => (state.shops = shops),
    setSelectedShop: (state, shop) => (state.selectedShop = shop),
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};