import i18n from "@/i18n";
import {customerService} from "@/services/api/customerService";

const state = {
    locale: window.config.LANGUAGE_LIST[0].value, // Default locale
};

const getters = {
    getLocale: (state) => state.locale,
};

const mutations = {
    SET_LOCALE(state, newLocale) {
        state.locale = newLocale;
    },
};

const actions = {
    async setLocale({commit}, newLocale) {
        try {
            // Update the locale on the server (if needed)
            await customerService.changeCustomerLocale(newLocale);

            // Update the Vue i18n instance
            i18n.locale = newLocale; // Update the i18n instance directly
            // Optionally, update the document's language attribute
            document.documentElement.lang = newLocale.replace('_', '-'); // Update the document language
            // Commit the new locale to the state
            commit('SET_LOCALE', newLocale);
        } catch (error) {
            console.error("Failed to change locale:", error); // Error handling
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};