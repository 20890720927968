<template>
  <div
      :class="['checkbox-input', customClass]"
      :aria-checked="isChecked.toString()"
  :tabindex="disabled ? -1 : 0"
  role="checkbox"
  :aria-disabled="disabled.toString()"
  @click="toggleCheck"
  @keydown.space.prevent="toggleCheck"
  @keydown.enter.prevent="toggleCheck"
  ref="checkboxWrapper"
  style="outline: none"
  >
  <input
      type="checkbox"
      v-model="isChecked"
      :disabled="disabled"
      :aria-label="ariaLabel || label"
      :aria-describedby="description ? `${checkboxId}-description` : null"
  class="checkbox-input-input"
  :id="checkboxId"
  @change="toggleCheck"
  :required="required"
  ref="checkboxInput"
  />
  <label :for="checkboxId" class="checkbox-input-label">
      <span class="checkbox-input-control">
        <!-- Tick symbol that appears when checkbox is checked -->
        <span v-if="isChecked" class="tick-symbol">✓</span>
      </span>
    <div class="checkbox-input-label-text">
      {{ label }}
      <br v-if="description" />
      <small v-if="description" :id="`${checkboxId}-description`">{{ description }}</small>
    </div>
  </label>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    ariaLabel: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    checkboxId: {
      type: String,
      default: null,
    },
    customClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isChecked: this.modelValue,
    };
  },
  watch: {
    modelValue(newVal) {
      this.isChecked = newVal;
    },
  },
  methods: {
    handleChange() {
      this.$emit('update:modelValue', this.isChecked);
    },
    toggleCheck() {
      if (!this.disabled) {
        this.isChecked = !this.isChecked;  // Toggle the checkbox state
        this.handleChange();                // Emit the change
      }
    },
  },
};
</script>

<style scoped>
.checkbox-input {
  display: flex;
  align-items: flex-start;
  padding: 10px;
  user-select: none;
  transition: background-color 0.3s;
  cursor: pointer;
}

.checkbox-input-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox-input-control {
  background-color: var(--checkbox-background-color, white);
  border-style: solid;
  border-width: var(--checkbox-border-width, 2px);
  border-color: var(--checkbox-border-color, #007BFF);
  border-radius: var(--checkbox-border-radius, 2px);
  height: var(--checkbox-control-size, 16px);
  width: var(--checkbox-control-size, 16px);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s, border-color 0.3s;
  cursor: pointer;
}

/* When checkbox is checked, change background and border color */
.checkbox-input-input:checked + .checkbox-input-label .checkbox-input-control {
  background-color: var(--checkbox-background-color-checked, #007BFF);
  border-color: var(--checkbox-border-color-checked, #007BFF);
}

/* Tick symbol */
.tick-symbol {
  font-size: var(--checkbox-icon-size, 12px);
  color: var(--checkbox-icon-color, white);
}

.checkbox-input-label {
  font-size: var(--checkbox-label-font-size, 16px);
  line-height: var(--checkbox-label-line-height, 1.5);
  font-weight: var(--checkbox-label-font-weight, 400);
  color: var(--checkbox-label-color, #333);
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox-input-label-text {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.checkbox-input-input:focus-visible + .checkbox-input-label {
  outline: 4px solid var(--checkbox-focus-color);
  outline-offset: 2px;
}

.checkbox-input-input:focus:not(:focus-visible) + .checkbox-input-label {
  outline: none;
}

.checkbox-input-input:disabled + .checkbox-input-label .checkbox-input-control {
  background-color: var(--checkbox-background-color-disabled, #e9ecef);
  border-color: var(--checkbox-border-color-disabled, #ced4da);
}

.checkbox-input-input:disabled + .checkbox-input-label {
  cursor: not-allowed;
  color: var(--checkbox-label-color-disabled, #adb5bd);
}
</style>