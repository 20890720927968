<template>

    <splide :options="options">
        <splide-slide v-for="shop in shops" :key="shop.id" class="carouselElement">
            <div class="shopCarousel" :style="'background-image:url(' + shop.image + ');'">
                <div class="shopCarouselText">
                    <div class="textOnPicture">
                        <div class="shopCarouselTextTitle">{{shop.name}}</div>
                        <div v-html="shop.addresses[0].formattedAddress"></div>                    
                
                    </div>
                   <b-button class="btn btn-primary selectButton" @click="callbackSelect(shop.id, shop.custom2)">{{$t('button.select')}}</b-button>
                </div>

             
            </div>
        </splide-slide>
    </splide>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
// import SplideSlide from '@splidejs/vue-splide';
export default {
    props:{
        shops:{
            Type:Array
        },
        callbackSelect: {
            Type:Function
        }
    },   
    components:{
        'splide': Splide,
        'splide-slide': SplideSlide
    },
    data() {
        return {
            options: {
                rewind : true,
                gap    : '0.5rem',
                pagination:false                        
            }
        };
    },
}
</script>

<style scoped>
.logoButton {
    width: 80px;
    height: auto;   
    background-size:100% 100%;
}

.splide {
    width:80%;
    margin-left:10%;
}

.carouselElement{
    background-color: var(--background-color);
}

.shopCarousel{
    height:300px;
    background-size:cover;
    padding:0px;
}

.selectButton{
    min-width:115px;
    background-color:rgba(0,0,0,0.5);
    color:var(--main-color-highlight);
    float:right;   
    border:none; 
    border-radius: var(--border-radius);
}

.shopCarouselText{
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    height:inherit;
    align-items: center;
}

.textOnPicture{
    background-color:rgba(0,0,0,0.5);
    border-radius:var(--border-radius);
    color:var(--main-color-highlight);
    min-height:100px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    padding:20px;
}

ul {
    opacity:0.5 !important;
}
</style>