<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="true"
             :is-full-page="true"
             loader='bars'></loading>

    <div>
      <TitledCard v-if="isChild" :title="$t('subTitle.customerInfos')" class="gap10">
        <div class="d-flex flex-column noPadding text-left">
          <p><strong>{{ $t('text.customerAccount') }}:</strong> {{ fullName(wondCustomer) }}</p>
        </div>
      </TitledCard>

      <date-search-table id="dateSearchTable" :callback="getTransactions" style="" />
      <b-container fluid>
        <b-row class="d-none d-md-flex">
          <b-table small class="transactionTable"
                   :items="recentTransactions"
                   :fields="fields"
                   responsive
                   striped
                   hover
                   head-variant="dark"
                   tbody-class="text-left"
                   :label-sort-asc="$t('ariaLabel.sortAsc')"
                   :label-sort-desc="$t('ariaLabel.sortDesc')"
                   :label-sort-clear="$t('ariaLabel.sortClear')"
                   @sort-changed="onSortChanged"
                   @row-clicked="showTransactionDetails"
                   :tbody-tr-attr="rowIdentifier">
            <template #cell(amount)="data">
              <span>{{ data.item.prepayment ? data.item.formattedPaymentTotal : data.item.formattedTotal }}</span>
            </template>
            <template #cell(details)="data">
              <b-button class="btn btn-block btn-sm"
                        v-b-tooltip :title="$t('button.details')" :aria-label="$t('ariaLabel.transactionDetails') + data.item.formattedDate"
                        @click="showTransactionDetails(data.item)">
                <font-awesome-icon class="addIconMenu" :icon="['fas', 'circle-info']" />
              </b-button>
            </template>
            <template v-slot:empty>
              <b-tr>
                <b-td class="text-left" colspan="6">{{ $t('table.noData') }}</b-td>
              </b-tr>
            </template>
            <template #custom-foot v-if="config.TRANSACTION_HISTORY.showTotal">
              <b-tr>
                <b-td class="text-left" variant="secondary" colspan="4"><b>{{ $t('table.sum') }}</b></b-td>
                <b-td class="text-right" variant="secondary" colspan="1"><b>{{ transactionsFormattedTotal }}</b></b-td>
                <b-td variant="secondary"/>
              </b-tr>
            </template>
          </b-table>
        </b-row>

        <b-row class="d-md-none">
          <b-table small
                   head-variant="dark"
                   :items="recentTransactions"
                   :fields="mobileFields"
                   responsive
                   striped
                   hover
                   tbody-class="text-left"
                   :label-sort-asc="$t('ariaLabel.sortAsc')"
                   :label-sort-desc="$t('ariaLabel.sortDesc')"
                   :label-sort-clear="$t('ariaLabel.sortClear')"
                   @sort-changed="onSortChanged"
                   @row-clicked="showTransactionDetails"
                   :tbody-tr-attr="rowIdentifier">
            <template #cell(amount)="data">
              <span>{{ data.item.prepayment ? data.item.formattedPaymentTotal : data.item.formattedTotal }}</span>
            </template>
            <template #cell(details)="data">
              <b-button class="btn btn-block btn-sm"
                        v-b-tooltip :title="$t('button.details')" :aria-label="$t('ariaLabel.transactionDetails') + data.item.formattedDate"
                        @click="showTransactionDetails(data.item)">
                <font-awesome-icon class="addIconMenu" :icon="['fas', 'circle-info']" />
              </b-button>
            </template>
            <template v-slot:empty>
              <b-tr>
                <b-td class="text-left" colspan="4">{{ $t('table.noData') }}</b-td>
              </b-tr>
            </template>
          </b-table>
        </b-row>

        <b-row v-if="config.TRANSACTION_HISTORY.showPaymentDetails">
          <b-table
              head-variant="light"
              small
              :items="paymentsListTotal"
              :fields="paymentFields"
              responsive
              striped
              hover
              :label-sort-asc="$t('ariaLabel.sortAsc')"
              :label-sort-desc="$t('ariaLabel.sortDesc')"
              :label-sort-clear="$t('ariaLabel.sortClear')"
              :tbody-tr-attr="rowIdentifier">
            <template v-slot:empty>
              <b-tr>
                <b-td class="text-left" colspan="7">{{ $t('table.noData') }}</b-td>
              </b-tr>
            </template>
          </b-table>
        </b-row>
      </b-container>
    </div>

    <!-- Modal for transaction details -->
    <b-modal v-if="currentTransactionSelected" id="transaction-details-modal" ref="transactionDetailsModal" :title="$t('title.transaction') + ' ' + currentTransactionSelected.formattedDate" hide-footer>
      <transaction-details :transaction="currentTransactionSelected" />
    </b-modal>
  </div>
</template>

<script>
import TransactionDetails from "../sub-components/TransactionDetails.vue";
import DateSearchTable from "@/components/main-components/DateSearchTable.vue";
import Utils from "@/utils/utils";
import VueLoading from "vue-loading-overlay";
import {accountService} from "@/services/api/accountService";
import TitledCard from "@/components/barrier-free-template-components/TitledCard.vue";

export default {
  components: {
    'loading': VueLoading,
    'transaction-details': TransactionDetails,
    'date-search-table': DateSearchTable,
    TitledCard,
  },
  computed: {
    config() {
      return window.config;
    },
    isChild() {
      return this.$route.query.isChild;
    },
  },
  props: {
    customerId: {
      type: [String, Number],
    }
  },
  data() {
    return {
      isLoading: false,
      wondCustomer: {},
      recentTransactions: [],
      transactionsFormattedTotal: 0.00,
      paymentsListTotal: [],
      currentTransactionSelected: {},
      fields: [
        { key: 'formattedDate', label: this.$t('table.date'), sortable: true },
        { key: 'transNum', label: this.$t('table.transId'), sortable: true },
        { key: 'shopDescription', label: this.$t('table.shop'), sortable: true, tdClass: 'text-left' },
        { key: 'tillDescription', label: this.$t('table.till'), sortable: true },
        // Sort by numericAmount, display formattedTotal
        { key: 'numericAmount', label: this.$t('table.amount'), sortable: true, tdClass: 'text-right', formatter: (value, key, item) => item.prepayment ? item.formattedPaymentTotal : item.formattedTotal },
        { key: 'details', label: '', tdClass: 'text-right' }
      ],
      mobileFields: [
        { key: 'formattedDate', label: this.$t('table.date'), sortable: true, class: 'text-left' },
        { key: 'shopDescription', label: this.$t('table.shop'), sortable: true, class: 'text-left' },
        // Sort by numericAmount, display formattedTotal
        { key: 'numericAmount', label: this.$t('table.amount'), sortable: true, class: 'text-right', formatter: (value, key, item) => item.prepayment ? item.formattedPaymentTotal : item.formattedTotal },
        { key: 'details', label: '', class: 'text-center' }
      ],
      paymentFields: [
        { key: 'paymentDescription', label: this.$t('table.paymentsList'), class: 'text-left', colspan: 5 },
        { key: 'formattedAmount', label: this.$t('table.amount'), class: 'text-right', colspan: 2 }
      ]
    };
  },
  methods: {
    fullName(customer) {
      const nameParts = [];

      if (customer.description) {
        nameParts.push(customer.description);
      }

      if (customer.firstName) {
        nameParts.push(customer.firstName);
      }

      return nameParts.join(' ').trim();
    },
    async selectChild(id) {
      this.isLoading = true;
      const result = await accountService.selectChild(id);
      this.isLoading = false;

      if (result.requiresLogin) {
        this.$navigateToLogin();
      }

      if (result.exception !== undefined || result.errorCode < 0) {
        this.makeToast('danger', this.$t('notification.selectChildError'));
        return;
      }

      return result.wondChild;
    },
    async getTransactions(dateFrom, dateTo) {
      this.isLoading = true;

      const result = await this.doGet('TCPGetTransactionsJ.action', {
        json: "true",
        showDetails: "true",
        dateFromStr: dateFrom,
        dateToStr: dateTo,
      });

      if (result.requiresLogin) {
        this.$navigateToLogin();
      }

      // Prepare the transactions with numericAmount for sorting
      this.recentTransactions = result.transactions.map(transaction => {
        const numericAmount = parseFloat(transaction.formattedTotal.replace(/[^\d.-]/g, ''));
        const numericPaymentTotal = parseFloat(transaction.formattedPaymentTotal.replace(/[^\d.-]/g, ''));

        return {
          ...transaction,
          numericAmount: transaction.prepayment ? numericPaymentTotal : numericAmount
        };
      });

      this.transactionsFormattedTotal = result.transactionsFormattedTotal;
      this.paymentsListTotal = result.paymentsListTotal;

      this.isLoading = false;
    },
    showTransactionDetails(item) {
      this.currentTransactionSelected = item;
      this.$nextTick(() => {
        if (this.$refs.transactionDetailsModal) {
          this.$refs.transactionDetailsModal.show();  // Open the modal
        } else {
          console.error('Modal reference is not defined.');
        }
      });
    },
    onSortChanged({ sortBy, sortDesc }) {
      this.sortBy = sortBy;
      this.sortDesc = sortDesc;
    },
    rowIdentifier(item, type) {
      if (type != 'row') {
        return {};
      }
      return {
        'title': this.$t('text.transactionFrom') + " " + `${item.formattedDate}`
      }
    }
  },
  async mounted() {
    if (this.isChild) {
      this.wondCustomer = await this.selectChild(this.customerId);
    }
    await this.getTransactions(Utils.getStartOfMonth(), Utils.getEndOfMonth());
  }
};
</script>
<style scoped>
  .transactionTable {
    text-align: left; /* Align header text to the left */
  }
</style>
<style>
  .container-fluid .table td {
    vertical-align: middle; /* Center Table Cells Vertically */
  }
</style>