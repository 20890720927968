// store/modules/payment.js
const state = {
    paymentWindow: null,
    paymentStatus: null, // 'pending', 'success', or 'failed'
    paymentUrl: null, // URL for the Saferpay payment page
    paymentType: null, // Type of payment (e.g., 'credit_card', 'paypal', etc.)
};

const mutations = {
    SET_PAYMENT_URL(state, url) {
        state.paymentUrl = url;
    },
    SET_PAYMENT_STATUS(state, status) {
        state.paymentStatus = status;
    },
    SET_PAYMENT_WINDOW(state, window) {
        state.paymentWindow = window;
    },
    SET_PAYMENT_TYPE(state, paymentType) {
        state.paymentType = paymentType;
    },
    CLEAR_PAYMENT(state) {
        state.paymentWindow = null;
        state.paymentStatus = null;
        state.paymentUrl = null;
        state.paymentType = null; // Clear payment type as well
    }
};

const actions = {
    async initiatePayment({ commit, dispatch }, externalUrl) {
        try {
            if (externalUrl) {
                commit('SET_PAYMENT_URL', externalUrl);

                // Open the payment window and store its reference
                const paymentWindow = window.open(externalUrl, '_self', 'width=800,height=600');
                commit('SET_PAYMENT_WINDOW', paymentWindow);

                // Start monitoring the payment window
                dispatch('monitorPaymentWindow');
            }
        } catch (error) {
            console.error("Error initiating payment:", error);
            commit('SET_PAYMENT_STATUS', 'failed');
        }
    },
    setPaymentType({ commit }, paymentType) {
        commit('SET_PAYMENT_TYPE', paymentType);
    },
    monitorPaymentWindow({ state, commit }) {
        const checkInterval = setInterval(() => {
            if (state.paymentWindow && state.paymentWindow.closed) {
                clearInterval(checkInterval);
                commit('SET_PAYMENT_STATUS', 'success'); // You can adjust this based on your logic
                commit('CLEAR_PAYMENT'); // Clear payment state after checking status
            }
        }, 500);
    },
};

const getters = {
    paymentStatus: (state) => state.paymentStatus,
    paymentUrl: (state) => state.paymentUrl,
    paymentType: (state) => state.paymentType, // Getter for payment type
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};