// src/store/modules/mealplans.js

const state = {
    mealPlans: [],                  // List of meal plans
    selectedMealPlanId: null,        // Currently selected meal plan ID
    orders: [],                      // List of orders (each has reservationDate, mealPlanId, and products)
};

const getters = {
    allMealPlans: (state) => state.mealPlans,  // Get all meal plans
    selectedMealPlanId: (state) => state.selectedMealPlanId, // Get the selected meal plan ID
    selectedMealPlan: (state) =>
        state.mealPlans.find((plan) => plan.id === state.selectedMealPlanId) || null, // Get the selected meal plan

    // Get orders by meal plan ID and reservation date
    ordersForMealPlanAndDate: (state) => (mealPlanId, reservationDate) =>
        state.orders.find(order => order.mealPlanId === mealPlanId && order.reservationDate === reservationDate) || null,

    // Get all products for a specific meal plan and reservation date
    productsForMealPlanAndDate: (state) => (mealPlanId, reservationDate) => {
        const order = state.orders.find(order => order.mealPlanId === mealPlanId && order.reservationDate === reservationDate);
        return order ? order.products : [];
    },
};

const actions = {
    // Fetch meal plans from an API or any data source
    async fetchMealPlans({ commit }) {
        const mealPlans = [
            { id: 1, name: 'Vegan Plan', meals: 5 },
            { id: 2, name: 'Keto Plan', meals: 7 },
            { id: 3, name: 'Paleo Plan', meals: 6 },
        ];
        commit('setMealPlans', mealPlans);
    },

    // Set the selected meal plan ID
    setMealPlanId({ commit }, mealPlanId) {
        commit('setSelectedMealPlanId', mealPlanId);
    },

    // Add a product to a specific reservation date and meal plan
    addProductToOrder({ commit, state }, { mealPlanId, reservationDate, product }) {
        const order = state.orders.find(order => order.mealPlanId === mealPlanId && order.reservationDate === reservationDate);
        if (order) {
            // If the product already exists in the order, update the quantity
            const existingProduct = order.products.find(p => p.id === product.id);
            if (existingProduct) {
                commit('updateProductQuantity', { order, productId: product.id, quantity: product.quantity });
            } else {
                // If the product does not exist, add it to the order
                commit('addProductToExistingOrder', { order, product });
            }
        } else {
            // If no order exists for this reservationDate and mealPlanId, create a new order
            const newOrder = {
                mealPlanId,
                reservationDate,
                products: [{ ...product }]
            };
            commit('addNewOrder', newOrder);
        }
    },

    // Remove a product from a specific reservation date and meal plan
    removeProductFromOrder({ commit, state }, { mealPlanId, reservationDate, productId }) {
        const order = state.orders.find(order => order.mealPlanId === mealPlanId && order.reservationDate === reservationDate);
        if (order) {
            commit('removeProductFromOrder', { order, productId });
        }
    },
};

const mutations = {
    setMealPlans: (state, mealPlans) => {
        state.mealPlans = mealPlans;
    },

    setSelectedMealPlanId: (state, mealPlanId) => {
        state.selectedMealPlanId = mealPlanId;
    },

    addNewOrder: (state, newOrder) => {
        state.orders.push(newOrder);
    },

    addProductToExistingOrder: (state, { order, product }) => {
        order.products.push(product);
    },

    updateProductQuantity: (state, { order, productId, quantity }) => {
        const product = order.products.find(p => p.id === productId);
        if (product) {
            product.quantity = quantity;
        }
    },

    removeProductFromOrder: (state, { order, productId }) => {
        order.products = order.products.filter(product => product.id !== productId);
        // If the order has no more products, remove the entire order
        if (order.products.length === 0) {
            state.orders = state.orders.filter(o => o !== order);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};