<template>
  <div id="template">
    <FlexContainer>
      <!-- Custom titles and content for each container -->
      <template #title1>Custom Title for First Container</template>
      <template #content1>
        <p>This is the custom content for the first container.</p>
      </template>

      <template #title2>Custom Title for Second Container</template>
      <template #content2>
        <p>This is the custom content for the second container.</p>
      </template>

      <template #title3>Custom Title for Second Container</template>
      <template #content3>
        <p>This is the custom content for the second container.</p>
      </template>
    </FlexContainer>

    <FlexDropdown
        label="Select an Option"
        :options="dropdownOptions"
        v-model="selectedOption"
        :required="true"
        placeholder="Choose an option..."
    />

    <div id="checkboxes">
      <Checkbox
          v-model="isChecked"
          label="I agree to the terms and conditions"
          checkboxSize="medium"
          :checkboxId="'termsCheckbox'"
      />
    </div>

    <div id="checkboxes">
      <MultiSelectDropdown :options="dropdownOptions" @update-options="handleUpdateOptions" />
    </div>

    <div id="alerts">
      <FlexAlert
          type="success"
          message="This is a success alert!"
          @close="handleClose('success')"
      />
      <FlexAlert
          type="error"
          message="This is an error alert!"
          @close="handleClose('error')"
      />
      <FlexAlert
          type="warning"
          message="This is a warning alert!"
          @close="handleClose('warning')"
      />
      <FlexAlert
          type="info"
          message="This is an info alert!"
          @close="handleClose('info')"
      />
    </div>
  </div>
</template>

<script>

import FlexContainer from "@/components/page-components/templates/FlexContainer.vue";
import FlexAlert from "@/components/page-components/templates/FlexAlert.vue";
import FlexDropdown from "@/components/page-components/templates/FlexDropdown.vue";
import Checkbox from "@/components/page-components/templates/Checkbox.vue";
import MultiSelectDropdown from "@/components/page-components/templates/MultiSelectDropdown.vue";

export default {
  components: {
    FlexContainer,
    FlexAlert,
    FlexDropdown,
    Checkbox,
    MultiSelectDropdown,
  },
  data() {
    return {
      groupLabel: 'Choose one of the options below',
      radioOptions: [
        { label: 'Option 1', value: 'option1' },
        { label: 'Option 2', value: 'option2' },
        { label: 'Option 3', value: 'option3' },
      ],
      selectedOption: '',
      dropdownOptions: [
        { value: 'option1', label: 'Option 1', selected: false },
        { value: 'option2', label: 'Option 2', selected: false },
        // Other options...
      ],
      options: [
        { value: 'option1', label: 'Option 1', selected: false },
        { value: 'option2', label: 'Option 2', selected: false },
        { value: 'option3', label: 'Option 3', selected: false },
      ],
      isChecked: false,
    };
  },
  methods: {
    handleClose(type) {
      console.log(`Closed ${type} alert`);
    },
    handleUpdateOptions(updatedOptions) {
      this.dropdownOptions = updatedOptions;
    },
  },
}
</script>