<template>
    <div>
        <VueLoading v-if="isLoading" />
        <div class="d-flex flex-column flex-wrap gap10">

            <b-button type="button" v-if="!isAddressPrimary && !isAddressNew" @click="deleteAddress" class="btn btn-danger floatRight noMargin w-100">
                <font-awesome-icon :icon="['fas', 'trash-can']" />
                {{ $t('button.deleteAddress') }}
            </b-button>

            <address-form :addressId="address.id"
                          :addressModel="address"
                          :isEdit="!isAddressNew"
                          :callbackClose="close"
                          :customer="customer"
                          :submit-text="message"
                          class="row noPadding"></address-form>

        </div>
    </div>
</template>
<script>
import AddressForm from "@/components/main-components/AddressForm.vue";

export default {
    props: {
        address: {
            type: Object,
            required: true,
        },
        customer: {
            type: Object,
            required: true,
        },
        isAddressPrimary: {
            type: Boolean,
            required: true,
        },
        isAddressNew: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        message(){
            return this.isAddressNew ? this.$t('button.create') : this.$t('button.save')
        },
    },
    components: {
        'address-form': AddressForm,
    },
    methods:{
        close(){
            if (this.onClose == null) {
                this.$router.back();
            } else {
                this.onClose();
            }
        },
        deleteAddress(){
            const _self = this;
            this.doGet('TCPDeleteAddrJ.action', {
                json: "true",
                addrId: this.address.id,
            }).then(async function(){
                _self.makeToast('success', _self.$t('notification.deleteSuccessful'));
                // todo : add popup here to confirm this
                await _self.doPost('TCPDeleteAddrSubmitJ.action', {
                    json: "true",
                    xsrfToken: window.xtoken != undefined ? btoa(window.xtoken) : undefined,
                });

                // does not make anything....
                _self.close();
            });
        },
    },
}
</script>
<style scoped>

</style>