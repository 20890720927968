<template>
  <div class="week-slider-container">
    <button
        class="nav"
        @click="moveToPreviousWeek"
        aria-label="Previous week"
        v-if="isLargerScreen"
    >
      <font-awesome-icon :icon="['fas', 'arrow-left-long']" />
    </button>

    <div
        class="week-slider"
        @mousedown="startDrag"
        @mouseup="stopDrag"
        @mouseleave="stopDrag"
        @mousemove="drag"
        @touchstart="startDrag"
        @touchend="stopDrag"
        @touchmove="drag"
        @keydown="handleKeyDown"
        tabindex="0"
        role="slider"
        aria-valuemin="0"
        :aria-valuemax="daysToShow - 1"
        :aria-valuenow="visibleDays.indexOf(activeDay)"
        :aria-label="`Current selected day: ${formatDate(activeDay)}`"
        :style="{ transform: `translateX(${translateX}px)` }"
    >
      <div
          v-for="(day, index) in visibleDays"
          :key="index"
          :class="{ active: day.toDateString() === activeDay.toDateString() }"
          class="day-button"
          @click="selectDay(day)"
          @keydown.enter="selectDay(day)"
          role="button"
          tabindex="0"
          aria-label="Select day"
      >
        <div class="date-vertical">
          <span class="weekday">{{ new Intl.DateTimeFormat(currentLocale, { weekday: 'short' }).format(day) }}</span>
          <span class="day">{{ day.getDate() }}</span>
          <span class="month">{{ new Intl.DateTimeFormat(currentLocale, { month: 'short' }).format(day) }}</span>
        </div>
      </div>
    </div>

    <button
        class="nav"
        @click="moveToNextWeek"
        aria-label="Next week"
        v-if="isLargerScreen"
    >
      <font-awesome-icon :icon="['fas', 'arrow-right-long']" />
    </button>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        daysToShow: window.config.MEAL_PLAN.weekDays || 5,
        currentWeekStart: this.getMonday(new Date()), // Start of the current week (Monday)
        visibleDays: [], // Array to store the days of the current week
        activeDay: new Date(), // Track the selected day
        isDragging: false,
        startX: 0,
        translateX: 0, // Store the translation for the slider
        dragThreshold: 50, // Minimum distance to trigger a week switch
        dragDistance: 0, // Distance dragged
      };
    },
    created() {
      this.updateVisibleDays();
    },
    computed: {
      config() {
        return window.config;
      },
      currentLocale() {
        return this.$i18n.locale.replace('_', '-');
      },
      isLargerScreen() {
        return window.innerWidth > 768; // Adjust this value for your breakpoint
      },
    },
    methods: {
      // Update visible days and set the active day
      updateVisibleDays() {
        this.visibleDays = Array.from({ length: this.daysToShow }, (_, i) =>
            new Date(this.currentWeekStart.getTime() + i * 24 * 60 * 60 * 1000)
        );

        // Ensure that the active day is set after visibleDays are populated
        this.$nextTick(() => {
          this.setActiveDayToToday();
        });
      },

      // Automatically set today's date as active if it's within the visible week
      setActiveDayToToday() {
        const today = new Date();
        const todayInVisibleDays = this.visibleDays.find(day => day.toDateString() === today.toDateString());

        if (todayInVisibleDays) {
          this.activeDay = todayInVisibleDays;  // Set today's date as the active day if it's in visibleDays
        } else {
          this.activeDay = this.visibleDays[0];  // Default to the first visible day
        }

        // Emit the selected day event for external use
        this.$emit('daySelected', this.activeDay);
      },

      // Handle day selection
      selectDay(day) {
        this.activeDay = new Date(day);  // Ensure reactivity by using a new Date object
        this.$emit('daySelected', this.activeDay);  // Emit the selected day
      },
      // Get Monday of the current week
      getMonday(date) {
        const d = new Date(date);
        const day = d.getDay();
        const diff = d.getDate() - day + (day === 0 ? -6 : 1); // Adjust when day is Sunday
        return new Date(d.setDate(diff));
      },
      formatDate(date) {
        return new Intl.DateTimeFormat(this.currentLocale, { weekday: 'short', day: 'numeric', month: 'short' }).format(date);
      },
      // Start dragging
      startDrag(event) {
        this.isDragging = true;
        this.startX = event.pageX || event.touches[0].pageX;
        this.dragDistance = 0; // Reset drag distance
      },
      // Stop dragging
      stopDrag() {
        this.isDragging = false;
        this.dragDistance = 0; // Reset drag distance
      },
      // Handle dragging
      drag(event) {
        if (!this.isDragging) return;
        const x = event.pageX || event.touches[0].pageX;
        this.dragDistance = x - this.startX;
        this.translateX = this.dragDistance; // Update translation

        // Check if the drag distance surpasses the threshold to trigger a week switch
        if (this.dragDistance < -this.dragThreshold) {
          // Change condition: dragDistance < -dragThreshold moves to NEXT week
          this.moveToNextWeek();
          this.startX = x; // Reset start position
        } else if (this.dragDistance > this.dragThreshold) {
          // Change condition: dragDistance > dragThreshold moves to PREVIOUS week
          this.moveToPreviousWeek();
          this.startX = x; // Reset start position
        }
      },
      // Move to the next week
      moveToNextWeek() {
        const nextWeek = new Date(this.currentWeekStart);
        nextWeek.setDate(nextWeek.getDate() + this.config.MEAL_PLAN.weekDays);
        this.currentWeekStart = nextWeek;

        this.updateVisibleDays();
        this.selectDay(this.visibleDays[0]);
      },
      moveToPreviousWeek() {
        const prevWeek = new Date(this.currentWeekStart);
        prevWeek.setDate(prevWeek.getDate() - this.config.MEAL_PLAN.weekDays);
        this.currentWeekStart = prevWeek;

        this.updateVisibleDays();
        this.selectDay(this.visibleDays[0]);
      },
      // Handle keyboard navigation
      handleKeyDown(event) {
        switch (event.key) {
          case 'ArrowLeft':
            this.moveToPreviousWeek();
            break;
          case 'ArrowRight':
            this.moveToNextWeek();
            break;
        }
      },
    },
  };
</script>

<style scoped>
  .week-slider-container {
    width: 100%;
    white-space: nowrap;
    cursor: grab;
    user-select: none; /* Prevent text selection while dragging */
    display: flex;
    justify-content: space-between; /* Space between navigation and slider */
    padding: 10px 0;
  }

  .week-slider {
    display: flex;
    flex-direction: row;
    justify-content: center; /* Center the day buttons */
    align-items: center;
    transition: transform 0.3s ease;
    flex: 1; /* Allow the slider to take available space */
  }

  .nav {
    padding: 10px 0; /* Adjust padding for better touch targets */
    margin: 0 1px; /* Small margin between buttons */
    border: none;
    border-radius: 10px;
    background-color: var(--toolbar-background);
    color: var(--toolbar-text);
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s; /* Add transition for scaling */
    display: flex; /* Allow flex for vertical alignment */
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    min-width: 5%;
  }

  .day-button {
    flex: 1; /* Each day button fills equal space */
    padding: 10px 0; /* Adjust padding for better touch targets */
    margin: 0 1px; /* Small margin between buttons */
    border: none;
    border-radius: 10px;
    background-color: var(--toolbar-background);
    color: var(--toolbar-text);
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s; /* Add transition for scaling */
    display: flex; /* Allow flex for vertical alignment */
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
  }

  .day-button.active {
    background-color: var(--toolbar-active);
    color: var(--toolbar-text);
  }

  .day-button:hover {
    background-color: var(--toolbar-active);
    color: var(--toolbar-text);
    transform: scale(1.05); /* Scale effect on hover */
  }

  .date-vertical {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center items horizontally */
  }

  .day {
    font-size: 16px;
  }

  .weekday,
  .month {
    font-size: 14px;
  }

  /* Responsive styles */
  @media (max-width: 768px) {
    .day-button {
      padding: 10px 0; /* Keep padding consistent on mobile */
      font-size: 14px; /* Adjust font size for mobile */
    }

    .day {
      font-size: 1.2em; /* Adjust day size for mobile */
    }

    /* Hide navigation buttons on smaller screens */
    .nav {
      display: none; /* Hide navigation buttons on mobile */
    }
  }

  @media (min-width: 769px) {
    .day-button {
      padding: 12px 0; /* Larger padding for larger screens */
      font-size: 16px; /* Adjust font size for larger screens */
    }

    .day {
      font-size: 1.5em; /* Adjust day size for larger screens */
    }
  }
</style>