<template>
  <div class="qrLandingPageWrapper">
    <!-- Or set an image as the background -->
    <img id="myImage" :src="backgroundImagePath">

    <div class="qrLandingPageBody" style="padding-top:15%">

      <div class="col-12 fontTitle fontLandingTitle">
        {{ $t('title.SSOLoginError') }}
      </div>

      <div class="col-12 fontContent fontLandingContent">
        {{ $t('notification.SSOLoginError') }}
      </div>

      <div class="col-12 btnLandingWrapper" v-if="config.SHOW_ERROR_SSO_TO_LOGIN_PAGE">
        <b-button class="btn btn-primary btnLanding" @click="goToLogin">{{ $t('button.backToShop') }}</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    config() {
      return window.config;
    },
    backgroundImagePath() {
      return process.env.BASE_URL + 'settings/resources/ErrorSSOLandingBackground.jpg';
    },
    logoPath() {
      return process.env.BASE_URL + 'settings/resources/logo.png';
    },
  },
  methods: {
    goToLogin() {
      this.$navigateToLogin();
    }
  }
};
</script>

<style>
#myImage {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
  max-width: 100%;
}

.fontLandingTitle {
  font-family: var(--fonts-promo-font-family);
  text-align: center;
  font-size: 35px;
  color: var(--landing-text-color);
  margin-top: 3vh;
}

.fontLandingContent {
  color: var(--landing-text-color);
  margin-top: 3vh;
}

.btnLanding {
  width: 100%;
  max-width: 600px;
  height: 55px;
}

.qrLandingPageBody {
  z-index: 1000001;
}
</style>
