export const AlertMixin = {
    methods: {
        makeToast(variant, message) {
            this.$bvToast.toast(message, {
                title: this.$t("subTitle." + variant),
                variant: variant,
                solid: true,
                noAutoHide: !window.config.ALERT.autoHide,
                autoHideDelay: window.config.ALERT.autoHideDelay,
            })
        },
    }
}