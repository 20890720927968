import axiosClient from "@/apiClient";
import {refreshTimerBase} from '@/store/SessionTimer'

export const apiUtils = {
    doGet(api, params) {
        refreshTimerBase();
        window.REQUEST_TIMESTAMP = new Date().getTime();
        return axiosClient.get(api, { params })
            .then(response => response.data)
            .catch(function (error) {
                console.error("Error fetching data:", error);
                throw error;
            });
    },
    doPost(api, params) {
        refreshTimerBase();
        window.REQUEST_TIMESTAMP = new Date().getTime();
        return axiosClient.post(api, params)
            .then(response => response.data)
            .catch(function (error) {
                console.error("Error posting data:", error);
                throw error;
            });
    }
}
