<template>
  <div class="filter-container">
    <div id="allergenDietaryFilter" class="d-flex gap10 flex-column">
      <div class="meal-container">
        <div class="meal-group">
          <h1 class="meal-group-title">{{ $t('subTitle.properties') }}</h1>
        </div>
        <div class="grid-layout">
          <div class="dietary-list">
            <div
                v-for="property in properties"
                :key="property.id"
                class="filter-item"
                @click="toggleSelection(property, 'properties')"
                :class="{ selected: isSelected(property, 'properties') }"
            >
              <img :src="property.image" :alt="property.name" class="filter-icon" />
              <p>{{ property.name }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="meal-container">
        <div class="meal-group">
          <h1 class="meal-group-title">{{ $t('subTitle.allergens') }}</h1>
        </div>
        <div class="grid-layout">
          <div class="allergens-list">
            <div
                v-for="allergen in allergens"
                :key="allergen.id"
                class="filter-item"
                @click="toggleSelection(allergen, 'allergens')"
                :class="{ selected: isSelected(allergen, 'allergens') }"
            >
              <div>
                <img :src="allergen.image" :alt="allergen.name" class="filter-icon" />
                <p>{{ allergen.name }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-button class="btn btn-accordion btn-primary" @click="editCustomerDietaryAttributes">
      <font-awesome-icon :icon="['fas', 'floppy-disk']" /> {{ $t('button.save') }}
    </b-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      allergens: [],
      properties: [],
      selectedAllergens: [],
      selectedProperties: [],
      attributes: [] // Add attributes array to hold the selected attributes
    };
  },
  async created() {
    this.isLoading = true;
    const result = await this.doGet('TCPGetDietaryInfoTypesJ.action', {
      json: "true",
    });
    this.isLoading = false;

    this.allergens = result.allergens;
    this.properties = result.properties;
  },
  methods: {
    // Check if an allergen or dietary property is selected
    isSelected(item, type) {
      if (type === 'allergens') {
        return this.selectedAllergens.includes(item.id);
      } else if (type === 'properties') {
        return this.selectedProperties.includes(item.id);
      }
    },
    // Toggle the selection of allergens or dietary filters
    toggleSelection(item, type) {
      if (type === 'allergens') {
        const index = this.selectedAllergens.indexOf(item.id);
        if (index > -1) {
          this.selectedAllergens.splice(index, 1); // Remove from selected
        } else {
          this.selectedAllergens.push(item.id); // Add to selected
        }
      } else if (type === 'properties') {
        const index = this.selectedProperties.indexOf(item.id);
        if (index > -1) {
          this.selectedProperties.splice(index, 1); // Remove from selected
        } else {
          this.selectedProperties.push(item.id); // Add to selected
        }
      }
    },
    // Build the attributes array based on selected properties and allergens
    buildAttributes() {
      this.attributes = []; // Clear previous attributes

      // Iterate over selected properties
      this.selectedProperties.forEach(propertyId => {
        this.attributes.push({attributeId: propertyId, value: 1}); // Mark as selected
      });

      // Iterate over selected allergens
      this.selectedAllergens.forEach(allergenId => {
        this.attributes.push({attributeId: allergenId, value: 1}); // Mark as selected
      });

      // Add non-selected properties and allergens with value 0
      this.properties.forEach(property => {
        if (!this.selectedProperties.includes(property.id)) {
          this.attributes.push({attributeId: property.id, value: 0}); // Mark as not selected
        }
      });

      this.allergens.forEach(allergen => {
        if (!this.selectedAllergens.includes(allergen.id)) {
          this.attributes.push({attributeId: allergen.id, value: 0}); // Mark as not selected
        }
      });
    },
    editCustomerDietaryAttributes() {
      this.buildAttributes(); // Build the attributes array before submitting

      this.$emit('edit-customer-dietary-attributes', this.attributes);

      this.closeModal();
    },
    closeModal() {
      this.$emit('close');
    },
  }
};
</script>

<style scoped>
/* Overall Filter Container */
.filter-container {
  display: flex;
  flex-direction: column;
}

.meal-container {
  background-color: var(--page-container-background);
  border-radius: var(--button-border-radius);
  margin-top: 10px;
}

.meal-item {
  display: flex;
  flex: 1 0 360px;
  flex-wrap: wrap;
  gap: 10px;
  text-align: left;
  align-items: stretch;
  padding: 10px;
  border-radius: var(--button-border-radius);
  background-color: var(--page-group-item);
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition effect */
  height: 100%;
}

.filter-section h2 {
  margin-bottom: 10px;
  font-size: 1.25rem;
}

/* List of allergens and dietary options */
.allergens-list,
.dietary-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

/* Individual Allergen and Dietary Item */
.filter-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border: 2px solid transparent;
  transition: border 0.3s;
}

.filter-item.selected {
  border-color: #007bff;
}

.filter-icon {
  width: 60px;
  height: 60px;
  object-fit: contain;
  margin-bottom: 5px;
}

.filter-item p {
  margin: 0;
  text-align: center;
  font-size: 0.875rem;
  color: #333;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .filter-item {
    width: 80px;
  }

  .filter-icon {
    width: 50px;
    height: 50px;
  }

  .filter-item p {
    font-size: 0.75rem;
  }
}
</style>