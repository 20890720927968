<template>
  <div class="titledCard" :aria-label="title" role="group">
    <h2>{{ title }}</h2>
    <slot></slot>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        Type: String,
        required: false
      },
    },
  }
</script>