<template>
  <div style="padding-top: 10vh;">
    <div v-if="isTopUp">
      <div v-if="paymentFailed">{{ $t('notification.chargeError') }}</div>
      <div v-else>{{ $t('notification.chargeSuccessful') }}</div>
    </div>
    <div v-else>
      <div v-if="paymentFailed">{{ $t('notification.paymentError') }}</div>
      <b-button v-if="paymentFailed" @click="returnToOrder()" class="btn btn-primary">{{ $t('button.paymentEndBack') }}</b-button>
    </div>
    <!-- Timer message -->
    <div v-if="redirectTimer > 0">
      <p>{{ $t('text.redirectTimer') }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      paymentFailed: false,
      redirectTimer: 3, // Set the initial countdown time (in seconds)
      countdownInterval: null, // This will hold the interval ID
    };
  },
  props: {
    payment: {
      Type: String,
    },
    result: {
      Type: String,
    },
  },
  computed: {
    ...mapGetters('payments', ['paymentType']),

    isTopUp() {
      return this.paymentType === 'TOPUP';
    }
  },
  methods: {
    returnToOrder() {
      this.$router.push({ path: '/OrderConfirmation' });
    },
    startRedirectTimer() {
      this.countdownInterval = setInterval(() => {
        if (this.redirectTimer > 0) {
          this.redirectTimer--;
        } else {
          clearInterval(this.countdownInterval);
          this.redirectTo(); // Redirect when timer hits zero
        }
      }, 1000);
    },
    redirectTo() {
      history.replaceState(null, '', location.href)

      const config = window.config;
      if (!config.COOKIES_ENABLED && config.TABLE_CHECKOUT.enabled) {
        window.location = config.TABLE_CHECKOUT.homepage;
      }

      if (this.isTopUp) {
        this.$navigateToStudentAccounts(false);
      }
    },
  },
  beforeMount() {
    window.shoppingCart = null;
  },
  async mounted() {
    const result = await this.doGet('TCPAfterLoginJ.action', {
      json: "true",
    });

    this.customer = result.customer;
    if (result.customer != undefined) {
      if (result.customer.type != undefined) {
        if (result.customer.type == 2) {
          window.isGuestCheckout = true;
        }
      }
    }

    console.log("POSTING TOP MESSAGE: " + this.result);
    window.top.postMessage(this.result, '*');

    if (this.payment == "novalnet" && this.result == "S") {
      this.isLoading = false;
      this.$navigateToCheckoutFinished();
    }

    if (this.result == "S") {
      if (!this.isTopUp) {
        this.$navigateToCheckoutFinished();
      } else {
        this.startRedirectTimer();
      }
    } else if (this.result == "F") {
      this.paymentFailed = true;
      this.startRedirectTimer(); // Start the timer when payment fails
    } else if (this.result == "A") {
      this.paymentFailed = true;
      this.startRedirectTimer(); // Start the timer for authorization failure
    }
  },
  beforeDestroy() {
    // Clear the interval when the component is destroyed
    clearInterval(this.countdownInterval);
  },
};
</script>