<template>
  <div>
    <h2 v-if="selectedCategory">{{ selectedCategory.description }}</h2> <!-- Display the selected category name -->

    <div id="menuProducts" class="articleGroupBody">
      <ProductCard
          v-for="product in categoryItems"
          :key="product.kkProdId"
          :product="product"
          @view-details="viewProductDetails"
          @add-to-basket="addToBasket"
          @remove-from-basket="removeFromBasket"
      />
    </div>
  </div>
</template>

<script>

import ProductCard from "@/components/main-components/ProductCard.vue";

export default {
  components: {
    ProductCard
  },
  props: {
    categoryId: Number,  // ID of the selected category
    categories: Array    // Array of categories passed as a prop
  },
  data() {
    return {
      selectedCategory: null  // To store the category object based on the ID
    };
  },
  computed: {
    // Computed property to handle both "products" and "items"
    categoryItems() {
      if (this.selectedCategory) {
        // Return 'products' if it exists, otherwise return 'items'
        return this.selectedCategory.products || this.selectedCategory.items || [];
      } else {
        let categoryItems = [];
        for (let category of this.categories) {
          if (category.products) {
            categoryItems = categoryItems.concat(category.products); // Add products to categoryItems
          }
          if (category.items) {
            categoryItems = categoryItems.concat(category.items); // Add items to categoryItems
          }
        }
        return categoryItems;
      }
    }
  },
  methods: {
    viewProductDetails(product) {
      this.$emit('product-selected', product);
    },
    addToBasket(product) {
      this.$emit('add-to-basket', product);
    },
    removeFromBasket(product) {
      this.$emit('remove-from-basket', product);
    },
    fetchCategoryById(categoryId) {
      if (this.categories && categoryId) {
        // Find the category by ID from the categories array
        this.selectedCategory = this.categories.find(category => category.id === categoryId);
      }
    },
  },
  watch: {
    // Watch for changes in categoryId and fetch the corresponding category
    categoryId(newCategoryId) {
      this.fetchCategoryById(newCategoryId);
    }
  },
  mounted() {
    // Fetch category by ID when component mounts
    if (this.categoryId) {
      this.fetchCategoryById(this.categoryId);
    }
  }
};
</script>
