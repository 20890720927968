import { apiUtils } from "@/utils/apiUtils";

export const basketService = {
    async addProductToBasket(productId, orderType, calculateOrder, quantity, mealPlanId) {
        try {
            const result = await apiUtils.doGet("/TCPAddMenuItemToCartJ.action", {
                productId: productId,
                json: "true",
                orderType: orderType,
                // TODO freeText: comment,
                calculateOrder: calculateOrder,
                // TODO weightOrVolume: this.decimalQuantity ? this.weightOfArticle / 1000 : undefined,
                quantity: quantity,
                mealPlanId: mealPlanId
            });

            return result;
        } catch (error) {
            console.error("Error adding product to basket:", error);
            throw error;
        }
    },
    async removeProductFromBasket(productId, orderType) {
        try {
            const result = apiUtils.doPost('/TCPRemoveMenuItemFromCartJ.action', {
                id: productId,
                orderType: orderType
            });

            return result;
        } catch (error) {
            console.error("Error removing product from basket:", error);
            throw error;
        }
    }
};