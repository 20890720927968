<template>
  <div class="qrLandingPageWrapper">
    <loading :active.sync="isLoading"
             :can-cancel="true"
             :is-full-page="true"
             loader='bars'></loading>
        <!-- Play short looped video as background -->
        <!-- <video autoplay muted loop id="myVideo">
            <source src="@/assets/videos/landing-page.mp4" type="video/mp4">
        </video> -->

    <!-- Or set an image as the background -->
    <img id="myImage" :src="backgroundImagePath">

    <div class="qrLandingPageBody">
      <div class="col-12" style="padding-top:15%">
        <div v-if="!showHomeMode">
          <img style="max-width:150px;width:100%" :src="logoPath"/>
        </div>
        <div v-else>
          <img @click="goToShop()" style="max-width:150px;width:100%" :src="logoPath"/>
        </div>
      </div>

      <div class="col-12 fontTitle fontLandingTitle" v-if="landSuccess || showHomeMode">
        {{ $t('title.qrLandingPage') }}
      </div>

      <div class="col-12 fontContent fontLandingContent" v-if="landSuccess && !immediate">
        {{ $t('text.qrLandingPageBody1') }}{{ table }}{{ $t('text.qrLandingPageBody2') }}
      </div>

      <div class="col-12 fontContent fontLandingContent" v-if="landSuccess && immediate && ordersPossible">
        {{ $t('text.qrLandingPageBody1') }}{{ $t('text.qrLandingPageBody2') }}
      </div>

      <div class="col-12 fontContent fontLandingContent" v-if="landSuccess && immediate && !ordersPossible">
        {{ $t('text.qrLandingPageBlockedText') }}
      </div>

      <div class="col-12 fontContent fontLandingContent" v-if="showHomeMode">
        {{ $t('text.qrLandingPageHomeText') }}
      </div>

      <div class="col-12 btnLandingWrapper" v-if="landSuccess && (!immediate || (immediate && ordersPossible))">
        <b-button class="btn btn-primary btnLanding" v-if="openTableExists" @click="payOrder">{{ $t('button.pay') }}</b-button>
        <b-button class="btn btn-primary btnLanding" @click="startOrder">{{ $t('button.landingOrder') }}</b-button>
      </div>

      <h2 v-if="orderDetails != null && orderDetails.order != undefined" style="color:white;"> Unbezahlt: </h2>
            <table class="shoppingCartItemsTable"  style="table-layout:fixed" v-if="orderDetails != null && orderDetails.order != undefined">

        <thead>
        <th class="shoppingCartHeaderBig">{{ $t('text.article') }}</th>
        <!-- <th class="shoppingCartHeaderSmall"></th> -->
        <th class="shoppingCartHeaderBig">{{ $t('text.sum') }}</th>
        </thead>

        <tr v-for="item in orderDetails.order.menuItems" v-bind:key="item.kkProdId">
          <td class="shoppingCartLine">{{ item.name }}</td>
                    <td class="shoppingCartLine mainColor" style="font-weight:600;">{{item.weightOrVolume != undefined ? item.formattedPrice : item.formattedPrice}} </td>
        </tr>

        <tr style="border-top:2px solid var(--main-color)">
          <td class="shoppingCartLine" style="font-weight:600;">{{ $t('sum') }}</td>
          <td class="shoppingCartLine mainColor" style="font-weight:800;">{{ orderDetails.order.formattedTotal }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import VueLoading from 'vue-loading-overlay'
import moment from "moment/moment";
import Utils from "@/utils/utils";

export default {
  data() {
    return {
      landSuccess: false,
      openTableExists: false,
      openTable: {
        Type: Object,
        default: null
      },
      orderDetails: {
        Type: Object,
        default: null
      },
      showHomeMode: false,
      isLoading: false,
      ordersPossible: false,
    }
  },
  computed: {
    backgroundImagePath() {
      return process.env.BASE_URL + 'settings/resources/QRLandingBackground.jpg';
    },
    logoPath() {
      return process.env.BASE_URL + 'settings/resources/logo.png';
    },
  },
  props: {
    shop: {
      Type: String
    },
    table: {
      Type: String
    },
    home: {
      Type: Boolean
    },
    jwt: {
      Type: String,
      Default: "",
    },
    code: {
      Type: String,
      Default: "",
    },
    immediate: {
      Type: Boolean
    }
  },
  components: {
    'loading': VueLoading,
  },
  methods: {
    payOrder() {
      window.shopSelected = true;
      window.TableMode = true;
      if (window.config.COOKIES_ENABLED) {
        let duration = 60;
        if (window.config.TABLEMODE_COOKIE_DURATION > 0) {
          duration = window.config.TABLEMODE_COOKIE_DURATION
        }
        this.$cookies.set('TableMode', true, duration * 60);
      }
      this.TableMode = true;
      this.$router.push({path: '/TablePay'});
    },
    startOrder() {
      window.shopSelected = true;
      window.TableMode = true;
      if (window.config.COOKIES_ENABLED) {
        let duration = 60;
        if (window.config.TABLEMODE_COOKIE_DURATION > 0) {
          duration = window.config.TABLEMODE_COOKIE_DURATION
        }
        this.$cookies.set('TableMode', true, duration * 60);
      }
      window.timeSet = true;
      this.TableMode = true;
      this.$router.push({path: '/Menu'});
    },
    async getOrderDetails() {
      var vueInstance = this;

      const result = await vueInstance.doGet('TCPOrderDetailsJ.action', {
        guid: window.openTable.guid,
        json: "true",
        shopId: window.openTable.shopId
      });

      if ((result.exception !== undefined) || (result.item !== undefined)) {
        vueInstance.makeToast('danger', vueInstance.$t('notification.getItemFailed'));
        window.isLoading = false;
        return;
      }

      vueInstance.orderDetails = result;
    },
    async doCheckIfOrdersAreCurrentlyPossible() {
      let vueInstance = this;
      let hasTimeSlotForDay = false;

      const result = await vueInstance.doGet('TCPTimeJ.action', {
        json: "true",
        enableCaching: false,
        crcIn: "e046f966"
      });

      const availabilities = result.availabilityList;
      if (availabilities === undefined) {
        vueInstance.makeToast('danger', vueInstance.$t('notification.noTimeslotsAvailable'));
        window.isLoading = false;
      } else {
        const mapDayWithTimeslots = new Map(
            availabilities.map(obj => {
              return [new Date(obj.date).toLocaleDateString(), obj.timeslots];
            })
        );

        const currentDate = new Date();
        if (mapDayWithTimeslots.size > 0) {
          const timeslots = mapDayWithTimeslots.get(currentDate.toLocaleDateString());
          if (timeslots.length > 0) {
            timeslots.some(timeslot => {
              const dateFrom = Utils.parseTimeHHMMSS(timeslot.fromtime);
              const dateTo = Utils.parseTimeHHMMSS(timeslot.totime);
              if (currentDate <= dateTo && currentDate >= dateFrom) {
                hasTimeSlotForDay = true;
              }
            });
          }
        }

            if (hasTimeSlotForDay)
            {
          hasTimeSlotForDay = false;

          const resultOcc = await vueInstance.doGet('TCPGetTimeSlotOccupancyJ.action', {
            json: "true",
            orderType: 1,
            day: moment(currentDate.day).format("YYYY-MM-DD")
          });

          const occupancies = resultOcc.timeSlots;
          if (occupancies === undefined) {
            vueInstance.makeToast('danger', vueInstance.$t('notification.noTimeslotsAvailable'));
            window.isLoading = false;
          } else {
            occupancies.some(timeslot => {
              const dateFrom = Utils.parseTimeHHMMSS(timeslot.fromTime);
              const dateTo = Utils.parseTimeHHMMSS(timeslot.toTime);
              console.log(dateFrom + " " + dateTo + " " + currentDate);
              if (currentDate <= dateTo && currentDate >= dateFrom && timeslot.slots > 0 && timeslot.usable > 0) {
                hasTimeSlotForDay = true;
              }
            });
          }

          if (hasTimeSlotForDay) {
            vueInstance.ordersPossible = true;
          }
        }
      }
    },
    async doImmediateOrderByQRCode() {
      var vueInstance = this;

      const result = await vueInstance.doPost('TCPImmediateOrderJ.action', {
        "json": "true",
        "shopCode": window.tableModeShop,
      });

      if (result.exception != undefined) {
        vueInstance.makeToast('danger', vueInstance.$t('notification.failedGeneric'));
        window.isLoading = false;
        return;
      }

      window.shopObj = {name: result.shopCode};
      window.orderType = "dinein";
      window.isGuestCheckout = true;
      vueInstance.landSuccess = true;
      vueInstance.isLoading = false;
    },
    async selectTableByQRCode() {
      var config = window.config;
      var vueInstance = this;

      const result = await vueInstance.doGet('TCPSelectTableByQRCodeJ.action', {
        "json": "true",
        "billNum": "1",
        "shopCode": window.tableModeShop,
        "tableCode": window.tableModeTable
      });

      if (result.exception != undefined) {
        vueInstance.makeToast('danger', vueInstance.$t('notification.failedGeneric'));
        window.isLoading = false;
        return;
      }

      window.shopObj = {name: result.shopCode};
      window.orderType = "dinein";
      window.tableId = result.tableId;
      vueInstance.landSuccess = true;

      if (result.table != undefined && config.QRLANDING_OPEN_TABLES_ENABLE == true) {
        window.shopObj.id = result.table.shopId;
        vueInstance.openTableExists = true;
        vueInstance.openTable = result.table;
        window.openTable = result.table;
        vueInstance.getOrderDetails();
      }

      if (!config.SHOW_WELCOME_PAGE_ON_LANDING_PAGE) {
        vueInstance.openTableExists ? vueInstance.payOrder() : vueInstance.startOrder();
      }

      vueInstance.isLoading = false;
    },
    goToShop() {
      if(window.config.TABLE_CHECKOUT.enabled)
      {
        window.location = window.config.TABLE_CHECKOUT.homepage;
        return;
      }
    },
    async checkCustomer() {
      var vueInstance = this;

      const result = await vueInstance.doGet('TCPAfterLoginJ.action', {
        json: "true",
      });

      if (result.exception !== undefined) {
        console.log(result.exception);
        return;
      }

      if (result.requiresLogin == true) {
        if (window.immediate) {
          await vueInstance.$router.push({path: "/Shop"});
        } else {
          await vueInstance.$router.push({path: "/Login"});
        }
      }

      if (result.requiresLogin == false) {
        if (window.immediate) {
          await vueInstance.doImmediateOrderByQRCode();
          await vueInstance.doCheckIfOrdersAreCurrentlyPossible();
        } else {
          await vueInstance.selectTableByQRCode();
        }
      }

      if (result.customer != undefined) {
        if (result.customer.type != undefined) {
          if (result.customer.type == 2) {
            window.isGuestCheckout = true;
          }
        }
      }
    },
    async getToken() {
      var vueInstance = this;
      const result = await vueInstance.doGet('TCPLoginJ.action', {
        json: "true"
      });

      window.xtoken = result.xsrfToken != undefined ? atob(result.xsrfToken) : undefined;
    },
    async forceLogin() {
      var vueInstance = this;
      const result = await vueInstance.doGet('TCPAfterLoginJ.action', {
        json: "true"
      });

      if (result.requiresLogin == true) {
        console.log("Routing to LOGIN");
        await vueInstance.$router.push({path: "/Login"});
        return;
      }

      vueInstance.customer = result.customer;

      if (result.customer != undefined) {
        if (result.customer.type != undefined) {
          if (result.customer.type == 2) {
            window.isGuestCheckout = true;
          }
        }
      }
    },
    async checkJWT() {
      console.log("checkJWT");
      var config = window.config;
      var vueInstance = this;
      vueInstance.isLoading = true;
      vueInstance.jwtString = vueInstance.jwt;
      if ((vueInstance.jwtString != "" && vueInstance.jwtString != undefined && vueInstance.jwtString != null) || (vueInstance.code != "" && vueInstance.code != undefined && vueInstance.code != null)) {
        await vueInstance.getToken();

        const result = await vueInstance.doPost('TCPLoginWithJWTJ.action', {
          jwt: vueInstance.jwtString,
          code: vueInstance.code,
          returnCustomerData: "true"
        });

        vueInstance.isLoading = false;
        vueInstance.showChoice = true;

        if (result.exception !== undefined) {
          console.log("SSO Login Failed - Routing to LOGIN");
          vueInstance.makeToast('danger', vueInstance.$t('notification.SSOLoginError'));
          await vueInstance.$router.push({path: "/Login"});
          return;
        }

        if (result.requiresLogin == true) {
          console.log("Routing to LOGIN");
          await vueInstance.$router.push({path: "/Login"});
          return;
        }

        if (result.customer == undefined) {
          console.log("JWT Login unsuccesful!");
          if (result.requiresLogin == false) {
            console.log("A user is already logged in, JWT ignored!");
            await vueInstance.checkCustomer();
            return;
          }
          if (config.FORCE_LOGIN_BEFORE_USE) {
            console.log("Routing to LOGIN");
            await vueInstance.$router.push({path: "/Login"});
            return;
          }
        }

        vueInstance.customer = result.customer;
        window.customerLoggedIn = true;
        await vueInstance.checkCustomer();
      } else {
        vueInstance.isLoading = false;
      }
    },
  },
  async mounted() {
    $("#siteHeader").css({"display": "none"});
    $("#global-footer").css({"display": "none"});
    $("#ControlGroupButtonClusterFixed").css({"display": "none"});

    if (this.home) {
      this.showHomeMode = true;
      return;
    } else {
      this.isLoading = true;
    }

    window.TableMode = true;
    if (window.config.COOKIES_ENABLED) {
      let duration = 60;
      if (window.config.TABLEMODE_COOKIE_DURATION > 0) {
        duration = window.config.TABLEMODE_COOKIE_DURATION
      }
      this.$cookies.set('TableMode', true, duration * 60);
      if (this.tableId != undefined) {
        this.$cookies.set('tableId', this.table, duration * 60);
      }
      this.$cookies.set('shopId', this.shop, duration * 60);
      this.$cookies.set('orderType', "dinein");
      if (this.immediate != undefined) {
        this.$cookies.set('immediate', this.immediate, duration * 60);
      }
    }

    window.tableModeShop = this.shop;
    window.tableModeTable = this.table;
    window.shopId = this.shop;
    window.tableId = this.table;
    window.immediate = this.immediate;

    if (window.config.AGE_VERIFICATION_SHOPCODES.includes(this.shop)) {
      window.ageVerification = true;
    } else {
      window.ageVerification = false;
    }

    if (!window.TableModeDataSaved) {
      if (this.jwt != undefined || this.code != undefined) {
        await this.checkJWT();
      } else {
        await this.checkCustomer();
      }
    } else if (window.immediate) {
      await this.doImmediateOrderByQRCode();
      await this.doCheckIfOrdersAreCurrentlyPossible();
    } else if (!window.immediate) {
      await this.selectTableByQRCode();
    }

    window.TableModeDataSaved = true;
  },
  // eslint-disable-next-line no-unused-vars
  beforeRouteLeave(to, from, next) {
    $("#siteHeader").css({"display": "block"});
    $(".globalFooter").css({"display": "block"});
    $("#ControlGroupButtonClusterFixed").css({"display": "block"});
    $(".content").addClass("header-small-content-margin");
    next();
  },
  // eslint-disable-next-line no-unused-vars
  beforeRouteEnter(to, from, next) {
    Object.assign(window.config, window.configTablemode);
    $("#siteHeader").css({"display": "none"});
    $(".globalFooter").css({"display": "none"});
    $("#ControlGroupButtonClusterFixed").css({"display": "none"});
    $(".content").removeClass("header-small-content-margin");
    next();
  },
}
</script>

<style scoped>

#myImage {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
  max-width: 100%;
}

#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
}

.fontLandingTitle {
  font-family: var(--fonts-promo-font-family);
  text-align: center;
  font-size: 35px;
  color: var(--landing-text-color);
  margin-top: 3vh;
}

.fontLandingContent {
  /* font-family: var(--fonts-promo-font-family); */
  color: var(--landing-text-color);
  margin-top: 3vh;
}

.btnLanding {
  width: 100%;
  max-width: 600px;
  height: 55px;
}

table {
  color: var(--landing-text-color);
}

.btnLandingWrapper {
  /* margin-top:30vh; */
}

.qrLandingPageBody {
  z-index: 1000001;
}

.shoppingCartLine {
  height: 40px;
}

.shoppingCartHeaderBig {
  width: 50%;
}
</style>