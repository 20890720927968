export const Currencies = Object.freeze({
    CHF: 'CHF',
    EUR: 'EUR',
});

export const OrderTypes = Object.freeze({
    TAKEAWAY: 'takeaway',
    DELIVERY: 'delivery',
    DINE_IN: 'dinein',
});

export const UserTypes = Object.freeze({
    DEFAULT: '0',
    CHILD: '1',
    PARENT: '2',
});