import { apiUtils } from "@/utils/apiUtils";

export const shopService = {
    async selectShop(shopId) {
        try {
            const result = await apiUtils.doGet('/TCPSelectShopJ.action', {
                id: shopId,
                json: "true"
            });

            return result;
        } catch (error) {
            console.error("Error on selecting a shop:", error);
            throw error;
        }
    },
    async getShops(emptyBasket) {
        try {
            const result = await apiUtils.doGet('/TCPShopsJ.action', {
                json: "true",
                emptyBasket: emptyBasket,
            });

            return result;
        } catch (error) {
            console.error("Error fetching shops:", error);
            throw error;
        }
    }
};
