import Vue from 'vue';
import Router from 'vue-router';
import AccountPage from "@/components/page-components/AccountPage.vue";
import LoginPage from "@/components/page-components/LoginPage.vue";
import ForgotPasswordPage from "@/components/page-components/ForgotPasswordPage.vue";
import ShopPage from "@/components/page-components/ShopPage.vue";
import OrderOptionsPage from "@/components/page-components/OrderOptionsPage.vue";
import MenuPage from "@/components/page-components/MenuPage.vue";
import ShoppingCart from "@/components/page-components/ShoppingCartPage.vue";
import RegisterPage from "@/components/page-components/RegisterPage.vue";
import RegisterAsParentPage from "@/components/page-components/RegisterAsParentPage.vue";
import RegisterAsChildPage from "@/components/page-components/RegisterAsChildPage.vue";
import GuestCheckout from "@/components/page-components/GuestCheckoutPage.vue";
import QRLandingPage from "@/components/page-components/QRLandingPage.vue";
import ErrorSSOLandingPage from "@/components/page-components/ErrorSSOLandingPage.vue";
import ArticlePage from "@/components/page-components/ArticlePage.vue";
import PasswordResetPage from "@/components/page-components/PasswordResetPage.vue";
import AddressSelectionPage from "@/components/page-components/AddressSelectionPage.vue";
import EditCustomerPage from "@/components/page-components/EditCustomerPage.vue";
import TopUp from "@/components/page-components/TopUp.vue";
import OrderHistoryPage from "@/components/page-components/OrderHistoryPage.vue";
import TransactionHistoryPage from "@/components/page-components/TransactionHistoryPage.vue";
import StudentAccounts from "@/components/page-components/StudentAccounts.vue";
import AccountConfirm from "@/components/page-components/AccountConfirm.vue";
import TablePayPage from "@/components/page-components/TablePayPage.vue";
import OrderConfirmationPage from "@/components/page-components/OrderConfirmationPage.vue";
import CheckoutFinishedPage from "@/components/page-components/CheckoutFinishedPage.vue";
import PaymentEndPage from "@/components/page-components/PaymentEndPage.vue";
import PaymentPage from "@/components/page-components/PaymentPage.vue";
import LoginSSOPage from "@/components/page-components/LoginSSOPage.vue";
import MealPlanMeals from "@/components/page-components/MealPlanMeals.vue";
import ShopOverview from "@/components/page-components/ShopOverview.vue";
import MealPlanner from "@/components/page-components/MealPlanner/MealPlanner.vue";
import CustomerCard from "@/components/page-components/CustomerCard.vue";
import Dashboard from "@/components/page-components/Home/Dashboard.vue";
import Template from "@/components/page-components/templates/Template.vue";
import AddressMutationPage from "@/components/page-components/AddressMutationPage.vue";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: '/dist/',
    routes: [
        {
            path: '*',
            redirect: '/home' // or a dedicated NotFound component
        },
        {
            path: '/template',
            name: 'Template',
            component: Template,
            meta: { title: 'Template' }
        },
        {
            path: '/home',
            name: 'Home',
            component: Dashboard,
            meta: { title: 'title.home' }
        },
        {
            path: '/register',
            name: 'Register',
            component: RegisterPage,
            meta: { title: 'title.register', showBackButton: true }
        },
        {
            path: '/registerAsParent',
            name: 'RegisterAsParent',
            component: RegisterAsParentPage,
            meta: { title: 'title.registerAsParent', showBackButton: true }
        },
        {
            path: '/guest',
            name: 'Guest',
            component: GuestCheckout,
            meta: { title: 'title.registerGuest', showBackButton: false }
        },
        {
            path: '/forgotPassword',
            name: 'ForgotPassword',
            component: ForgotPasswordPage,
            meta: { title: 'title.passwordForgot', showBackButton: true }
        },
        {
            path: '/shop',
            name: 'Shop',
            component: ShopPage,
            meta: { title: 'title.shop', showBackButton: false },
            props: route => ({ code: route.query.code, jwt: route.query.id_token, shopId: route.query.s, validationType: route.query.validationType, isMealPlan: route.query.isMealPlan })
        },
        {
            path: '/orderOptions',
            name: 'OrderOptions',
            component: OrderOptionsPage,
        },
        {
            path: '/menu',
            name: 'Menu',
            component: MenuPage,
            meta: { title: 'title.menu', showBackButton: true }
        },
        {
            path: '/article',
            name: 'Article',
            component: ArticlePage,
            props: route => ({ article: route.query.a })
        },
        {
            path: '/cart',
            name: 'Cart',
            component: ShoppingCart,
        },
        {
            path: '/tablePay',
            name: 'TablePay',
            component: TablePayPage
        },
        // Pages with required login
        {
            path: '/account',
            name: 'Account',
            component: AccountPage,
            meta: { title: 'title.account', showBackButton: false }
        },
        {
            path: '/account/passwordReset',
            name: 'PasswordReset',
            component: PasswordResetPage,
            meta: { title: 'title.passwordReset', showBackButton: true }
        },
        {
            path: '/account/address',
            name: 'Address',
            component: AddressSelectionPage,
            meta: { title: 'title.address', showBackButton: true }
        },
        {
            path: '/account/editAddress',
            name: 'EditAddress',
            component: AddressMutationPage, // issue: since no data is provided after "F5"(reload), the page will apear empty
            props: true,
            meta: { title: 'title.address', showBackButton: true }
        },
        {
            path: '/account/editCustomer',
            name: 'EditCustomer',
            component: EditCustomerPage,
            meta: { title: 'title.editCustomer', showBackButton: true }
        },
        {
            path: '/customerCard',
            name: 'CustomerCard',
            component: CustomerCard,
            meta: { title: 'title.customerCard' },
        },
        {
            path: '/topup/:customerId',
            name: 'TopUp',
            component: TopUp,
            meta: { title: 'title.charge', showBackButton: true },
            props: true
        },
        {
            path: '/orderHistory',
            name: 'OrderHistory',
            component: OrderHistoryPage,
            meta: { title: 'title.orderHistory' }
        },
        {
            path: '/transactionHistory/:customerId',
            name: 'TransactionHistory',
            component: TransactionHistoryPage,
            meta: { title: 'title.transactionHistory', showBackButton: false },
            props: true
        },
        {
            path: '/shopOverview',
            name: 'ShopOverview',
            component: ShopOverview,
            meta: { title: 'title.shop', showBackButton: false },
            props: route => ({ isMealPlan: route.query.isMealPlan })
        },
        {
            path: '/mealPlanner',
            name: 'MealPlanner',
            component: MealPlanner,
            meta: { title: 'title.mealPlan', showBackButton: false },
            props: route => ({ isMealPlan: route.query.isMealPlan })
        },
        {
            path: '/mealPlan/meals',
            name: 'MealPlanMeals',
            component: MealPlanMeals,
            meta: { requiresLogin: true },
            props: true,
        },
        {
            path: '/studentAccounts',
            name: 'StudentAccounts',
            component: StudentAccounts,
            meta: { title: 'title.studentAccounts' },
        },
        {
            path: '/registerAsChild',
            name: 'RegisterAsChild',
            component: RegisterAsChildPage,
            meta: { title: 'title.registerAsChild', showBackButton: true }
        },
        {
            path: '/orderConfirmation',
            name: 'OrderConfirmation',
            component: OrderConfirmationPage,
            meta: { title: 'title.orderConfirmation', showBackButton: true }
        },
        {
            path: '/payment',
            name: 'Payment',
            component: PaymentPage,
            meta: { requiresLogin: true, title: 'title.payment', showBackButton: true }
        },

        // Pages to redirect and replace browser history
        {
            path: '/confirm',
            name: 'Confirm',
            component: AccountConfirm,
            props: route => ({ k: route.query.key, m: route.query.m, i: route.query.i})
        },
        {
            path: '/qrLanding',
            name: 'QRLanding',
            component: QRLandingPage,
            props: route => ({ table: route.query.t, shop: route.query.s, home: route.query.h, code: route.query.code, jwt: route.query.id_token, immediate: route.query.i })
        },
        {
            path: '/errorSSO',
            name: 'ErrorSSO',
            component: ErrorSSOLandingPage
        },
        {
            path: '/checkoutFinished',
            name: 'CheckoutFinished',
            component: CheckoutFinishedPage,
            meta: { requiresLogin: true },
        },
        {
            path: '/paymentEnd',
            name: 'PaymentEnd',
            component: PaymentEndPage,
            meta: { requiresLogin: true },
            props: route => ({ payment: route.query.p, result: route.query.r })
        },
        {
            path: '/login',
            name: 'Login',
            component: LoginPage,
            meta: { title: 'title.login' }
        },
        {
            path: '/loginSSO',
            name: 'LoginSSO',
            component: LoginSSOPage,
            props: route => ({ jwt: route.query.id_token, validationType: route.query.validationType, immediate: route.query.i, shopId: route.query.s })
        },
    ],
});

export default router;
