<template>
    <div class="registrationPageWrapper">        
        <loading :active.sync="isLoading" 
            :can-cancel="true"        
            :is-full-page="true"
            loader='bars'></loading>

      <div>
        <b-container fluid>
          <b-form @submit.stop.prevent>
            <b-form-row v-show="config.GUEST_CHECKOUT.FIELDS.firstName.visible || config.GUEST_CHECKOUT.FIELDS.lastName.visible" >
              <b-col cols="12" md="6">
                <b-form-group class="form-group fontField" label-for="firstName">
                  <template v-slot:label>
                    {{ $t('field.firstName') }} <span v-if="config.GUEST_CHECKOUT.FIELDS.firstName.required" class="text-danger">*</span>
                  </template>
                  <b-form-input class="form-control form-control-lg"
                                id="firstName" name="firstName"
                                type="text"
                                v-model="$v.form.firstName.$model"
                                :state="validateState('firstName')"
                                aria-describedby="firstName-feedback"
                                :maxLength="config.GUEST_CHECKOUT.FIELDS.firstName.maxLength" />
                  <b-form-invalid-feedback class="text-left" id="firstName-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group class="form-group fontField" label-for="lastName">
                  <template v-slot:label>
                    {{ $t('field.lastName') }} <span v-if="config.GUEST_CHECKOUT.FIELDS.lastName.required" class="text-danger">*</span>
                  </template>
                  <b-form-input class="form-control form-control-lg"
                                id="lastName"
                                name="lastName"
                                type="text"
                                v-model="$v.form.lastName.$model"
                                :state="validateState('lastName')"
                                aria-describedby="lastName-feedback"
                                :maxLength="config.GUEST_CHECKOUT.FIELDS.lastName.maxLength" />
                  <b-form-invalid-feedback class="text-left" id="lastName-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row v-show="config.GUEST_CHECKOUT.FIELDS.emailAddr.visible">
              <b-col cols="12" md="6">
                <b-form-group class="form-group fontField" label-for="emailAddr">
                  <template v-slot:label>
                    {{ $t('field.email') }} <span v-if="config.GUEST_CHECKOUT.FIELDS.emailAddr.required" class="text-danger">*</span>
                  </template>
                  <b-form-input class="form-control form-control-lg" id="emailAddr" name="emailAddr" type="email" v-model="$v.form.emailAddr.$model"
                                :state="validateState('emailAddr')" aria-describedby="emailAddr-feedback" :maxLength="config.GUEST_CHECKOUT.FIELDS.emailAddr.maxLength"/>
                  <b-form-invalid-feedback class="text-left" id="emailAddr-feedback">{{ $t('validation.invalidEmail') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row v-show="config.GUEST_CHECKOUT.FIELDS.cardNumber.visible || config.GUEST_CHECKOUT.FIELDS.customerCode.visible" >
              <b-col cols="12" md="6">
                <b-form-group class="form-group fontField" label-for="cardNumber">
                  <template v-slot:label>
                    {{ $t('field.cardNumber') }} <span v-if="config.GUEST_CHECKOUT.FIELDS.cardNumber.required" class="text-danger">*</span>
                  </template>
                  <b-form-input class="form-control form-control-lg" id="cardNumber" name="cardNumber" type="text" v-model="$v.form.cardNumber.$model"
                                :state="validateState('cardNumber')" aria-describedby="cardNumber-feedback" :maxLength="config.GUEST_CHECKOUT.FIELDS.cardNumber.maxLength"/>
                  <b-form-invalid-feedback class="text-left" id="cardNumber-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group class="form-group fontField" label-for="customerCode">
                  <template v-slot:label>
                    {{ $t('field.customerCode') }} <span v-if="config.GUEST_CHECKOUT.FIELDS.customerCode.required" class="text-danger">*</span>
                  </template>
                  <b-form-input class="form-control form-control-lg" id="customerCode" name="customerCode" type="text" v-model="$v.form.customerCode.$model"
                                :state="validateState('customerCode')" aria-describedby="customerCode-feedback" :maxLength="config.GUEST_CHECKOUT.FIELDS.customerCode.maxLength"/>
                  <b-form-invalid-feedback class="text-left" id="customerCode-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row v-show="config.GUEST_CHECKOUT.FIELDS.streetAddress.visible">
              <b-col cols="12" md="6">
                <b-form-group class="form-group fontField" label-for="streetAddress">
                  <template v-slot:label>
                    {{ $t('field.street') }} <span v-if="config.GUEST_CHECKOUT.FIELDS.streetAddress.required" class="text-danger">*</span>
                  </template>
                  <b-form-input class="form-control form-control-lg"
                                id="streetAddress"
                                name="streetAddress" type="text"
                                v-model="$v.form.streetAddress.$model"
                                :state="validateState('streetAddress')"
                                aria-describedby="streetAddress-feedback"
                                :maxLength="config.GUEST_CHECKOUT.FIELDS.streetAddress.maxLength"/>
                  <b-form-invalid-feedback class="text-left" id="streetAddress-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row v-show="config.GUEST_CHECKOUT.FIELDS.postcode.visible || config.GUEST_CHECKOUT.FIELDS.city.visible">
              <b-col cols="12" md="6">
                <b-form-group class="form-group fontField" label-for="postcode">
                  <template v-slot:label>
                    {{ $t('field.postalCode') }} <span v-if="config.GUEST_CHECKOUT.FIELDS.postcode.required" class="text-danger">*</span>
                  </template>
                  <b-form-input class="form-control form-control-lg"
                                id="postcode"
                                name="postcode"
                                type="text"
                                v-model="$v.form.postcode.$model"
                                :state="validateState('postcode')"
                                aria-describedby="postcode-feedback"
                                :maxLength="config.GUEST_CHECKOUT.FIELDS.postcode.maxLength"/>
                  <b-form-invalid-feedback class="text-left" id="postcode-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group class="form-group fontField" label-for="city">
                  <template v-slot:label>
                    {{ $t('field.city') }} <span v-if="config.GUEST_CHECKOUT.FIELDS.city.required" class="text-danger">*</span>
                  </template>
                  <b-form-input class="form-control form-control-lg"
                                id="city"
                                name="city"
                                type="text"
                                v-model="$v.form.city.$model"
                                :state="validateState('city')"
                                aria-describedby="city-feedback"
                                :maxLength="config.GUEST_CHECKOUT.FIELDS.city.maxLength" />
                  <b-form-invalid-feedback class="text-left" id="city-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row v-show="config.GUEST_CHECKOUT.FIELDS.countryId.visible">
              <b-col cols="12" md="6">
                <b-form-group class="form-group fontField" label-for="countryId">
                  <template v-slot:label>
                    {{ $t('field.country') }} <span v-if="config.GUEST_CHECKOUT.FIELDS.countryId.required" class="text-danger">*</span>
                  </template>
                  <select-picker-country
                      id="customerCountry"
                      name="customerCountry"
                      class="countrySelect"
                      v-if="countriesLoaded"
                      :options="countries"
                      :callback="selectCountry"
                      :preselectedId="selectedCountryId"
                      aria-describedby="countryId-feedback"
                      :state="validateState('countryId')" />
                  <b-form-invalid-feedback class="text-left" id="countryId-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row v-show="config.GUEST_CHECKOUT.FIELDS.telephoneNumber.visible" >
              <b-col cols="12" md="6">
                <b-form-group class="form-group fontField" label-for="telephoneNumber">
                  <template v-slot:label>
                    {{ $t('field.telephoneNumber') }} <span v-if="config.GUEST_CHECKOUT.FIELDS.telephoneNumber.required" class="text-danger">*</span>
                  </template>
                  <b-form-input class="form-control form-control-lg" id="telephoneNumber" name="telephoneNumber" type="text" v-model="$v.form.telephoneNumber.$model"
                                :state="validateState('telephoneNumber')" aria-describedby="telephoneNumber-feedback"/>
                  <b-form-invalid-feedback class="text-left" id="telephoneNumber-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row v-show="config.GUEST_CHECKOUT.FIELDS.notes1.visible || config.GUEST_CHECKOUT.FIELDS.notes2.visible" >
              <b-col cols="12" md="6">
                <b-form-group class="form-group fontField" label-for="notes1">
                  <template v-slot:label>
                    {{ $t('field.notes1') }} <span v-if="config.GUEST_CHECKOUT.FIELDS.notes1.required" class="text-danger">*</span>
                  </template>
                  <b-form-input class="form-control form-control-lg" id="notes1" name="notes1" type="text" v-model="$v.form.notes1.$model"
                                :state="validateState('notes1')" aria-describedby="notes1-feedback" :maxLength="config.GUEST_CHECKOUT.FIELDS.notes1.maxLength"/>
                  <b-form-invalid-feedback class="text-left" id="notes1-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group class="form-group fontField" label-for="notes2">
                  <template v-slot:label>
                    {{ $t('field.notes2') }} <span v-if="config.GUEST_CHECKOUT.FIELDS.notes2.required" class="text-danger">*</span>
                  </template>
                  <b-form-input class="form-control form-control-lg" id="notes2" name="notes2" type="text" v-model="$v.form.notes2.$model"
                                :state="validateState('notes2')" aria-describedby="notes2-feedback" :maxLength="config.GUEST_CHECKOUT.FIELDS.notes2.maxLength"/>
                  <b-form-invalid-feedback class="text-left" id="notes2-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row v-show="config.GUEST_CHECKOUT.FIELDS.notes3.visible || config.GUEST_CHECKOUT.FIELDS.notes4.visible">
              <b-col cols="12" md="6">
                <b-form-group class="form-group fontField" label-for="notes3">
                  <template v-slot:label>
                    {{ $t('field.notes3') }} <span v-if="config.GUEST_CHECKOUT.FIELDS.notes3.required" class="text-danger">*</span>
                  </template>
                  <b-form-input class="form-control form-control-lg" id="notes3" name="notes3" type="text" v-model="$v.form.notes3.$model"
                                :state="validateState('notes3')" aria-describedby="notes3-feedback" :maxLength="config.GUEST_CHECKOUT.FIELDS.notes3.maxLength"/>
                  <b-form-invalid-feedback class="text-left" id="notes3-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group class="form-group fontField" label-for="notes4">
                  <template v-slot:label>
                    {{ $t('field.notes4') }} <span v-if="config.GUEST_CHECKOUT.FIELDS.notes4.required" class="text-danger">*</span>
                  </template>
                  <b-form-input class="form-control form-control-lg" id="notes4" name="notes4" type="text" v-model="$v.form.notes4.$model"
                                :state="validateState('notes4')" aria-describedby="notes4-feedback" :maxLength="config.GUEST_CHECKOUT.FIELDS.notes4.maxLength"/>
                  <b-form-invalid-feedback class="text-left" id="notes4-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row v-show="config.GUEST_CHECKOUT.FIELDS.otherInfos.visible">
              <b-col cols="12" md="12">
                <b-form-group class="form-group fontField" label-for="otherInfos">
                  <template v-slot:label>
                    {{ $t('field.otherInfos') }} <span v-if="config.GUEST_CHECKOUT.FIELDS.otherInfos.required" class="text-danger">*</span>
                  </template>
                  <b-form-input class="form-control form-control-lg" id="otherInfos" name="otherInfos" type="text" v-model="$v.form.otherInfos.$model"
                                :state="validateState('otherInfos')" aria-describedby="otherInfos-feedback" :maxLength="config.GUEST_CHECKOUT.FIELDS.otherInfos.maxLength"/>
                  <b-form-invalid-feedback class="text-left" id="otherInfos-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-form-row>

            <b-form-row>
              <b-col cols="12" md="12">
                <i18n class="text-left" path="text.acceptTermsAndPrivacy" tag="p">
                  <template v-slot:terms>
                    <a :href="config.COMPANY.links.terms" target="_blank">{{ $t('link.terms') }}</a>
                  </template>
                  <template v-slot:privacy>
                    <a :href="config.COMPANY.links.privacy" target="_blank">{{ $t('link.privacy') }}</a>
                  </template>
                </i18n>
              </b-col>
            </b-form-row>
            <b-form-row cols="1">
              <b-button type="submit"  @click.stop="onClickCaptcha" :disabled="submitStatus === 'PENDING'" class="btn btn-primary btn-lg btn-block btnSubmit g-recaptcha">{{ $t('button.submit') }}</b-button>
            </b-form-row>
          </b-form>
        </b-container>
      </div>
    </div>
</template>

<script>
import {email, requiredIf} from 'vuelidate/lib/validators'
import VueLoading from 'vue-loading-overlay'
import {en, de, fr} from 'vuejs-datepicker/dist/locale'
import selectPickerCountry from '../sub-components/SelectPickerCountry.vue'

export default {
    data() {
        return {
            submitStatus: null,
            form: {
              firstName: undefined,
              lastName: undefined,
              streetAddress: undefined,
              postcode: undefined,
              city: undefined,
              countryId: undefined,
              telephoneNumber: undefined,
              emailAddr: undefined,
              cardNumber: undefined,
              customerCode: undefined,
              notes1: undefined,
              notes2: undefined,
              notes3: undefined,
              notes4: undefined,
              otherInfos: undefined,
            },
            isLoading:false,
            en: en,
            de: de, 
            fr: fr,
            countriesLoaded: false,
            countries: {
                Type: Array
            },
             selectedCountry: {
                Type:Object
            },
            zones: {
                Type: Array
            },
            selectedCountryId: 0,
            reponseToken:""
        }	
	},
    computed: {
        isTableMode(){
            if(window.TableMode == true)
                return true;
            else
                return false;
        },
        isGuestCheckout(){
            if(window.isGuestCheckout == true)
                return true;
            else
                return false;
        },
        config() {
            return window.config;
        },
    },
    components:{
        'loading' : VueLoading,
        'select-picker-country': selectPickerCountry,
    },
  validations: { // STANDARD
    form: {
      firstName: {
        required: requiredIf(() => window.config.GUEST_CHECKOUT.FIELDS.firstName.required),
      },
      lastName:{
        required: requiredIf(() => window.config.GUEST_CHECKOUT.FIELDS.lastName.required),
      },
      streetAddress:{
        required: requiredIf(() => window.config.GUEST_CHECKOUT.FIELDS.streetAddress.required),
      },
      postcode:{
        required: requiredIf(() => window.config.GUEST_CHECKOUT.FIELDS.postcode.required),
      },
      city:{
        required: requiredIf(() => window.config.GUEST_CHECKOUT.FIELDS.city.required),
      },
      countryId:{
        required: requiredIf(() => window.config.GUEST_CHECKOUT.FIELDS.countryId.required),
      },
      telephoneNumber:{
        required: requiredIf(() => window.config.GUEST_CHECKOUT.FIELDS.telephoneNumber.required),
      },
      emailAddr:{
        required: requiredIf(() => window.config.GUEST_CHECKOUT.FIELDS.emailAddr.required),
        email,
      },
      cardNumber:{
        required: requiredIf(() => window.config.GUEST_CHECKOUT.FIELDS.cardNumber.required),
      },
      customerCode: {
        required: requiredIf(() => window.config.GUEST_CHECKOUT.FIELDS.customerCode.required),
      },
      notes1:{
        required: requiredIf(() => window.config.GUEST_CHECKOUT.FIELDS.notes1.required),
      },
      notes2:{
        required: requiredIf(() => window.config.GUEST_CHECKOUT.FIELDS.notes2.required),
      },
      notes3:{
        required: requiredIf(() => window.config.GUEST_CHECKOUT.FIELDS.notes3.required),
      },
      notes4:{
        required: requiredIf(() => window.config.GUEST_CHECKOUT.FIELDS.notes4.required),
      },
      otherInfos:{
        required: requiredIf(() => window.config.GUEST_CHECKOUT.FIELDS.otherInfos.required),
      },
    }
  },
    methods: {
        validateState(name) {
          const {$dirty, $error} = this.$v.form[name];
          return $dirty ? !$error : null;
        },
          // eslint-disable-next-line no-undef
        onClickCaptcha(e) {
            var vueInstance = this;
            console.log("onClickCaptcha");
            e.preventDefault();
            if(window.config.G_CAPTCHA.enabled == true)
            {
                // eslint-disable-next-line no-undef
                grecaptcha.ready(function() {
                    // eslint-disable-next-line no-undef
                    grecaptcha.execute(window.config.G_CAPTCHA.siteKey, {action: 'submit'}).then(function(token) {
                        vueInstance.responseToken = token;
                        vueInstance.onSubmit(e);             
                    });
                });
            }
            else
            {
                vueInstance.onSubmit(e);
            }
       
        },
        selectCountry(countryId) {
            this.form.countryId = countryId;      
        },
        goBack() {
            let pages = -1;
            if (window.immediate)
            {
              pages = -2;
            }
            this.$router.go(pages);
        },
        async onSubmit() {
          let vueInstance = this;
          vueInstance.$v.$touch();

          if (vueInstance.$v.$invalid) {
            vueInstance.makeToast('danger', vueInstance.$t('notification.fillAllRequiredFields'));
            return;
          }

          const result = await vueInstance.doPost('TCPCustomerRegistrationSubmitJ.action', {
            json: "true",
            allowNoRegister: "true",
            xsrfToken: window.xtoken != undefined ? btoa(window.xtoken) : undefined,
            responseToken: vueInstance.responseToken != undefined ? vueInstance.responseToken : undefined,
            gender: vueInstance.form.gender != undefined ? vueInstance.form.gender : '-',
            firstName: vueInstance.form.firstName != undefined ? vueInstance.form.firstName : '---',
            lastName: vueInstance.form.lastName != undefined ? vueInstance.form.lastName : '---',
            emailAddr: vueInstance.form.emailAddr,
            cardNumber: vueInstance.form.cardNumber != undefined ? vueInstance.form.cardNumber : undefined,
            customerCode: vueInstance.form.customerCode != undefined ? vueInstance.form.customerCode : undefined,
            streetAddress: vueInstance.form.streetAddress != undefined ? vueInstance.form.streetAddress : '---',
            postcode: vueInstance.form.postcode != undefined ? vueInstance.form.postcode : '---',
            city: vueInstance.form.city != undefined ? vueInstance.form.city : '---',
            countryId: vueInstance.form.countryId != undefined ? vueInstance.form.countryId : this.config.GUEST_CHECKOUT.FIELDS.countryId.default,
            telephoneNumber: vueInstance.form.telephoneNumber != undefined ? vueInstance.form.telephoneNumber : '---',
            notes1: vueInstance.form.notes1 != undefined ? vueInstance.form.notes1 : undefined,
            notes2: vueInstance.form.notes2 != undefined ? vueInstance.form.notes2 : undefined,
            notes3: vueInstance.form.notes3 != undefined ? vueInstance.form.notes3 : undefined,
            notes4: vueInstance.form.notes4 != undefined ? vueInstance.form.notes4 : undefined,
            otherInfos: vueInstance.form.otherInfos != undefined ? vueInstance.form.otherInfos : undefined,
            password: "xxxxxxxx",
            privacy: '1',
            tc: '1',
          });

          if (result.errorCode > 0) {
            vueInstance.makeToast('danger', vueInstance.$t("notification.registrationError"));
          } else {
            if (result.errorCode == 0) {
              window.customer = result;

              if (vueInstance.isTableMode) {
                let path;
                if (window.immediate) {
                  path = "/OrderConfirmation";
                } else {
                  path = "/QRLanding?s=" + window.tableModeShop + "&t=" + window.tableModeTable;
                }
                await vueInstance.$router.push({path: path});
              } else {
                await vueInstance.$router.push({path: "/OrderConfirmation"});
              }

              return;
            }

            if (result.exception !== undefined) {
              vueInstance.makeToast('danger', vueInstance.$t('notification.registrationError'));
              return;
            }
          }
        }
    },
    mounted()
    {
        if(window.customerLoggedIn)
        {
          this.$router.push({ path: '/Menu' });
          return;
        }
    },
    async created() {
      let vueInstance = this;

      const result = await vueInstance.doGet('TCPCustomerRegistrationJ.action', {
        json: "true",
        countryChange: 1
      });

      vueInstance.countries = result.countries;
      if (result.selectedCountry != undefined) {
        vueInstance.selectedCountry = result.selectedCountry;
        vueInstance.form.countryId = result.selectedCountry.id;
        vueInstance.selectedCountryId = result.selectedCountry.id;
      } else {
        if (vueInstance.countries.length > 0)
          vueInstance.selectCountry(vueInstance.countries[0].id);
      }

      vueInstance.countriesLoaded = true;
    },
}
</script>
<style scoped>
</style>