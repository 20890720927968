<template>
  <div class="editCustomerPageWrapper" style="padding-left: 15px; padding-right: 15px;">
    <loading :active.sync="isLoading"
             :can-cancel="true"
             :is-full-page="true"
             loader='bars'></loading>

    <div>
      <b-button v-if="config.REGISTER_CHILD.enabled" class="btn btn-primary btn-lg btn-block" @click="goToRegisterAsChild()" style="margin-left: 0; margin-right: 0;">
        <font-awesome-icon :icon="['fas', 'plus']" /> {{ $t('button.registerAsChild') }}
      </b-button>
      <b-container fluid>
        <b-row class="d-none d-md-flex">
          <b-table-simple small borderless responsive striped hover>
            <b-thead head-variant="dark">
              <b-tr>
                <b-th class="text-left">{{ $t('table.description') }}</b-th>
                <b-th class="text-left">{{ $t('table.cardNumber') }}</b-th>
                <b-th class="text-right">{{ $t('table.prepayBalanceCash') }}</b-th>
                <b-th class="text-left" />
              </b-tr>
            </b-thead>
             <b-tbody v-if="!Utils.isEmpty(children)" class="va-middle">
              <b-tr v-for="child in children" v-bind:key="child.id">
                <b-td class="text-left">{{ fullName(child) }}</b-td>
                <b-td class="text-left">{{ child.cardNum }}</b-td>
                <b-td class="text-right">{{ Utils.formatCurrency(getPrepayBalance(child), config.ACCOUNT_BALANCE_CURRENCY) }}</b-td>
                <b-td class="d-flex align-items-center justify-content-sm-center">
                  <b-button-group class="btn btn-block btn-sm">
                    <b-button v-b-tooltip :title="$t('button.charge')"  @click="openModalTopUp(child.id)"><font-awesome-icon :icon="['fas', 'credit-card']" /></b-button>
                    <b-button v-b-tooltip :title="$t('button.transactionHistory')" @click="showTransactionHistory(child.id)"><font-awesome-icon :icon="['fas', 'clock-rotate-left']" /></b-button>
                  </b-button-group>
                </b-td>
              </b-tr>
            </b-tbody>
            <b-tbody v-else>
              <b-tr>
                <b-td class="text-left" colspan="4">{{ $t('table.noData') }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-row>
        <b-row class="d-md-none">
          <b-table-simple small borderless responsive striped hover>
            <b-thead head-variant="dark">
              <b-tr>
                <b-th class="text-left">{{ $t('table.description') }}</b-th>
                <b-th class="text-right">{{ $t('table.prepayBalanceCash') }}</b-th>
                <b-th class="text-left" />
              </b-tr>
            </b-thead>
            <b-tbody v-if="!Utils.isEmpty(children)" class="va-middle">
              <b-tr v-for="child in children" v-bind:key="child.id">
                <b-td class="text-left">{{ fullName(child) }}</b-td>
                <b-td class="text-right">{{ Utils.formatCurrency(getPrepayBalance(child), config.ACCOUNT_BALANCE_CURRENCY) }}</b-td>
                <b-td class="d-flex align-items-center justify-content-center">
                  <b-button-group class="btn btn-block btn-sm">
                    <b-button v-b-tooltip :title="$t('button.charge')"  @click="openModalTopUp(child.id)"><font-awesome-icon :icon="['fas', 'credit-card']" /></b-button>
                    <b-button v-b-tooltip :title="$t('button.transactionHistory')" @click="showTransactionHistory(child.id)"><font-awesome-icon :icon="['fas', 'clock-rotate-left']" /></b-button>
                  </b-button-group>
                </b-td>
              </b-tr>
            </b-tbody>
            <b-tbody v-else>
              <b-tr>
                <b-td class="text-left" colspan="3">{{ $t('table.noData') }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import Utils from "@/utils/utils";
import loading from "vue-loading-overlay";

export default {
  components: {
    loading
  },
  data() {
    return {
      isLoading: false,
      children: {
        Type: Array,
        default: []
      },
    }
  },
  computed: {
    Utils() {
      return Utils
    },
    config() {
      return window.config;
    },
  },
  methods: {
    fullName(child) {
      const nameParts = [];

      if (child.description) {
        nameParts.push(child.description);
      }

      if (child.firstName) {
        nameParts.push(child.firstName);
      }

      return nameParts.join(' ').trim();
    },
    getPrepayBalance(child) {
      let separator = Utils.getDecimalSeparator(child.formattedPrepayBalanceCash);
      return (child.prepayBalanceCash + child.prepayBalanceVoucher + child.prepayBalanceBonus).toFixed(this.config.CUSTOMER_BALANCE_DECIMAL_PLACES).replace('.', separator);
    },
    async openModalTopUp(id) {
      this.$navigateToTopUp(true, id);
    },
    async showTransactionHistory(id) {
      this.$navigateToTransactionHistory(true, id);
    },
    async getStudentAccounts() {
      this.isLoading = true;
      const result = await this.doGet('TCPAfterLoginJ.action', {
        json: "true",
      });
      this.isLoading = false;

      if (result.requiresLogin) {
        this.$navigateToLogin();
      }

      if (result.exception !== undefined || result.wondCustomer === undefined) {
        console.log(result.exception);
        return;
      }

      this.children = result.wondCustomer.children || [];
    },
    goToRegisterAsChild(){
      this.$navigateToRegisterAsChild();
    },
  },
  async created() {
    await this.getStudentAccounts();
  },
}
</script>
<style scoped>


.va-middle td{
  vertical-align: middle;
}

#content-wrapper
{
  overflow-y: hidden;
}
</style>