import { apiUtils } from "@/utils/apiUtils";

export const accountService = {
    async logout() {
        try {
            const result = await apiUtils.doGet('TCPLogoutJ.action', {});
            return result;
        } catch (error) {
            console.error("Error on logout", error);
            throw error;
        }
    },
    async selectChild(id) {
        try {
            const result = await apiUtils.doGet('/TCPSelectChildJ.action', {
                json: "true",
                wondChildId: id
            });

            return result;
        } catch (error) {
            console.error("Error select child:", error);
            throw error;
        }
    }
};
