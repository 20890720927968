<template>
  <div>
    <flow :currentPoint="2"> </flow>

    <loading :active.sync="isLoading"
        :can-cancel="true"
        :is-full-page="true"
        loader='bars'></loading>

    <div class="col-12 checkoutPadding">
        <ul class="col-12" style="padding-left: 0px;padding-right: 0px; padding-top:0px;">
            <div style="margin-left:10%; margin-right:10%;">
                <div v-for="item in pointCampaigns" v-bind:key="item.campaignId"  style="text-align:right; padding: 5px; border-bottom:0.2px solid lightgray">
                    <span style="font-size:20px;float:left;">{{ getCampaignName(item.campaignId) }}</span>

                    <span v-if="item.gainedPoints > 0" style="display: inline-block; width: 30%; text-align: center; ">
                        <span style="font-size:15px;"> {{ $t('text.rewardPointsGained') }} </span>
                        <span style="color:var(--main-color); font-size:20px;"> {{ item.gainedPoints }} </span>
                    </span>

                    <span v-if="item.usedPoints > 0" style="display: inline-block; width: 30%; text-align: center; ">
                        <span style="font-size:15px;"> {{ $t('text.rewardPointsBurned') }} </span>
                        <span style="color:var(--main-color); font-size:20px;"> {{ item.usedPoints }} </span>
                    </span>
                </div>
            </div>
            <br/>

          <div v-if="basketItems.length > 0" class="shoppingCartItemsContainer">
            <div v-for="item in basketItems" :key="item.id" class="shoppingCartEntry">
              <cart-entry
                  :item="item"
                  :callbackAddNormal="addArticle"
                  :callbackEdit="changeItemQuantity"
                  :callbackAddWeight="weightAlert"
                  :callbackDelete="deleteItem"
                  :callbackRemove="removeArticle"
              ></cart-entry>
            </div>
          </div>


            <div class="shoppingCartDiscounts" v-if="discounts.length > 0">
                <div class="shoppingCartDiscountsTitle">{{ $t('text.discounts') }}</div>
                <div v-for="discount in discounts" v-bind:key="discount.id">
                    <div class="shoppingCartDiscountsEntry">
                        <span class="shoppingCartDiscountsEntryName">{{discount.description}}</span>
                        <span class="shoppingCartDiscountsEntryValue">{{formatDiscount(discount.value)}}</span>
                    </div>
                </div>
            </div>
        </ul>

        <div class="col-12">
            <div class="col-6" style="font-size:20px; text-align:left;">{{$t('text.sum')}}:</div>
            <div class="col-6" style="font-size:30px; text-align:right;padding-left: 0; padding-right: 0;">{{basketTotal}} </div>
        </div>

        <div class="col-12" v-if="paymentLists.length > 0">
            <!-- <div class="shoppingCartDiscountsTitle">{{ $t('discounts') }}</div> -->
            <!-- <div v-for="paymentList in paymentLists" v-bind:key="paymentList.paymentId" class="col-12">
                    <span class="col-6 shoppingCartDiscountsEntryName" style="font-size:20px; text-align:left;">{{$t('subTitle.subsidy')}}</span>
                    <span class="col-6 shoppingCartDiscountsEntryValue" style="font-size:30px; text-align:right;">{{formatDiscount(paymentList.paymentAmount)}}</span>
            </div> -->
            <span class="col-6" style="font-size:16px; text-align:left;">{{$t('subTitle.subsidy')}}</span>
            <span class="col-6" style="font-size:22px; text-align:right; color:gray;">{{formatCurrency(paymentLists[0].paymentAmount)}}</span>
        </div>

        <div class="col-12" v-if="customerLoggedIn == true && promotionsEnabled == true">
            <input type="text" class="col-12 commentField" v-model="promoBarcode" :placeholder="$t('field.promotionBarcode')" />
            <b-button @click="onPromotionBarcodeSubmit" class=" col-2 btn btn-primary floatRight" style="">{{ $t('button.promotionBarcodeCheck') }}</b-button>
        </div>

        <input v-if="config.SHOW_DESCRIPTION_TEXT_INPUTS" type="text" style="margin-bottom: 10px;" class="col-12 commentField" v-model="comment" :placeholder="$t('field.comment')" />


        <div class="col-12" v-if="config.SHOW_ORDERCONFIRMATION_TC_CONSENT">
          <i18n class="text-left" path="text.acceptTermsAndPrivacy" tag="p">
            <template v-slot:terms>
              <a :href="config.COMPANY.links.privacy" target="_blank">{{ $t('link.terms') }}</a>
            </template>
            <template v-slot:privacy>
              <a :href="config.COMPANY.links.terms" target="_blank">{{ $t('link.privacy') }}</a>
            </template>
          </i18n>
        </div>
        <div class="col-12" v-if="ageVerification">
          <p class="text-left">{{ $t('text.acceptCustomerAge') }}</p>
        </div>

        <div class="forwardBackward">
          <b-button type="button" @click="onSubmit" class="btn btn-primary" style="margin-right: 0">{{$t('button.continue')}}</b-button>
        </div>

    </div>
</div>
</template>

<script>
import FlowIndicator from '../main-components/FlowIndicator.vue'
import VueLoading from 'vue-loading-overlay'
import ShoppingCartEntry from '../sub-components/ShoppingCartEntry.vue'
import {mapGetters} from "vuex";
import {OrderTypes} from "@/constants/constants";

export default {
    data(){
        return {
            comment:"",
            basketItems: {
                Type: Array
            },
            basketTotal: "",
            quantityButtonDisabled: 'qtyBtnDisabled',
            pointCampaigns: [],   
            discounts: [],
            paymentLists: [],
            pointCampaignsDefinitions: [],
            isLoading: false,
            delivery_fee_article_prod_id: 0,
            customerLoggedIn: false,   
            promoBarcode:""  
        }
    },
    computed: {
      ...mapGetters('orders', ['getOrderType']),
      orderType() {
        return this.getOrderType;
      },
      config() {
          return window.config;
      },
      ageVerification(){
          return window.ageVerification;
      } ,
      promotionsEnabled(){
          return window.config.PROMOTION_CODE_ENABLED;
      }
    },
    components: {
        'loading' : VueLoading,
        'flow' : FlowIndicator,
        'cart-entry' : ShoppingCartEntry,
    },
    mounted(){
        window.paymentOrigin = "Shop";
    },
    async beforeMount() {
      this.isLoading = true;

      const result = await this.doGet('TCPAfterLoginJ.action', {json: "true"});

      this.isLoading = false;

      if (result.requiresLogin == true) {
        let path = window.immediate ? "/Guest" : "/Login";
        this.$router.push(path);
        return;
      }

      this.customer = result.customer;
      if (result.wondCustomer != undefined) {
        this.pointCampaignsDefinitions = result.wondCustomer.pointCampaigns;
      }

      if (result.customer != undefined) {
        if (result.customer.type != undefined) {
          if (result.customer.type == 2) {
            window.isGuestCheckout = true;
          }
        }
      }

      window.pointCampaignsDefinitions = this.pointCampaignsDefinitions;
      this.customerLoggedIn = true;
      window.customerLoggedIn = true;
      this.calculateOrder();
    },
    methods: { 
        async onPromotionBarcodeSubmit() {
          this.isLoading = true;
          if (this.promoBarcode == "") {
            console.log(this.$t('emptyPromotionCode'));
          }

          window.promotionBarcode = this.promoBarcode;
          const result = await this.doPost('TCPCalculateOrderJ.action', {
            orderType: this.orderType,
            json: "true",
            promotionBarcode: this.promoBarcode,
            comment: this.comment
          });

          this.isLoading = false;
          if (result.exception != undefined) {
            if (result.exception.code == 611) {
              this.makeToast('danger', this.$t('invalidPromotionCode'));
            }

            this.promoBarcode = "";
            window.promotionBarcode = "";
          }

          this.basketItems = result.basketTile.basketItems;
          this.basketTile = result.basketTile;
          this.basketTotal = result.basketTile.formattedTotal;
          window.shoppingCart = result.basketTile.basketItems;
          window.subsidy = result.basketTile.paymentsList != undefined ? this.formatCurrency(result.basketTile.paymentsList[0].paymentAmount) : "";

          this.dataCounter++;
          await this.updateCart();
          this.$forceUpdate();
        },
        formatDiscount(value){
            var formatter = new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: window.config.SUBSIDY_CURRENCY,

            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
            });

            return formatter.format(value); /* $2,500.00 */
        },
        formatCurrency(value) {
            // switch (this.config.) {
            //     case value:

            //         break;

            //     default:
            //         break;
            // }
            // var formatter = new Intl.NumberFormat('de-DE', { maximumSignificantDigits: 4});
            // return formatter.format(value);
            var formatter = new Intl.NumberFormat('de-DE', {style: 'currency', currency: window.config.SUBSIDY_CURRENCY});
            return formatter.format(value);
        },
        getCampaignName(campaignId){
            for(var i  = 0; i < this.pointCampaignsDefinitions.length; i++)
            {
                if(this.pointCampaignsDefinitions[i].id == campaignId)
                {
                    return this.pointCampaignsDefinitions[i].description;
                }
            }

            return "";
        },
        isNumeric(str) {
            if (typeof str != "string") return false // we only process strings!  
            return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
                    !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
        },
        async changeItemQuantity(element, articleId) {
          var value = element.srcElement.value;
          if (!this.isNumeric(value)) {
            this.makeToast('danger', this.$t('notification.inputQuantityNotValid'));
            element.srcElement.value = "1";
            return;
          }

          this.isLoading = true;

          const result = await this.doPost('TCPEditCartSubmitJ.action', {
            basketId: articleId,
            quantity: value,
            orderType: this.orderType,
            xsrfToken: window.xtoken != undefined ? btoa(window.xtoken) : undefined
          });

          if (value == 0) {
            await this.deleteItem(articleId);
            return;
          }

          this.isLoading = false;
          window.shoppingCart = result.basketTile.basketItems;
          window.subsidy = result.basketTile.paymentsList != undefined ? this.formatCurrency(result.basketTile.paymentsList[0].paymentAmount) : "";
          this.basketItems = result.basketTile.basketItems;
          this.basketTotal = result.basketTile.formattedTotal;
          this.discounts = result.basketTile.discounts != undefined ? result.basketTile.discounts : [];
          this.paymentLists = result.basketTile.paymentsList != undefined ? result.basketTile.paymentsList : [];

          if (result.exception !== undefined) {
            if (result.exception.code === 64) {
              this.makeToast('danger', this.$t('notification.changeItemMinError'));
              return;
            } else if (result.exception.code === 65) {
              this.makeToast('danger', this.$t('notification.changeItemMaxError'));
              return;
            }
            this.makeToast('danger', this.$t('notification.changeItemQtyFailed'));
            return;
          }
        },  
        async addItemsToOrder() {
          if (window.TableMode == true && window.openTable != undefined) {
            this.isLoading = true;

            const result = await this.doPost('TCPAddItemsToOrderJ.action', {
              json: "true",
              emptyBasket: true
            });

            this.isLoading = false;
            window.openTable = result.order;
          }
        },
        async calculateOrder() {
          this.isLoading = true;

          const result = await this.doPost('TCPCalculateOrderJ.action', {
            orderType: this.orderType,
            json: "true",
            promotionBarcode: window.promotionBarcode
          });

          this.isLoading = false;

          if (result.requiresLogin == true) {
            this.$router.push({path: "/Login"});
          }

          if (result.exception !== undefined) {
            this.makeToast('danger', this.$t("exceptionMessage"));
            return;
          }

          if (result.errorCode > 0) {
            this.makeToast('danger', result.erroMsg);
            return;
          }

          window.shoppingCart = result.basketTile.basketItems;
          this.basketItems = result.basketTile.basketItems;
          window.subsidy = result.basketTile.paymentsList != undefined ? this.formatCurrency(result.basketTile.paymentsList[0].paymentAmount) : "";
          this.basketTotal = result.basketTile.formattedTotal;
          this.pointCampaigns = result.basketTile.pointCampaigns != undefined ? result.basketTile.pointCampaigns : [];
          this.discounts = result.basketTile.discounts != undefined ? result.basketTile.discounts : [];
          this.paymentLists = result.basketTile.paymentsList != undefined ? result.basketTile.paymentsList : [];

          if (result.basketTile.basketItems == undefined || result.basketTile.basketItems.length == 0) {
            this.$router.push({path: "/Menu"});
            this.makeToast('danger', this.$t('notification.noProducts'));
          }
        },
        weightAlert(){
            this.makeToast('danger', this.$t('notification.weightArticleQuantityChange'))
        },
        async deleteItem(article) {
          this.isLoading = true;

          const result = await this.doPost('TCPRemoveMenuItemFromCartJ.action', {
            id: article.id,
            orderType: this.orderType,
          });

          this.isLoading = false;
          if (result.exception !== undefined) {
            this.makeToast('danger', this.$t('notification.removeItemFailed'));
            return;
          }

          await this.updateCart();
          window.shoppingCart = result.basketTile.basketItems;
          window.subsidy = result.basketTile.paymentsList != undefined ? this.formatCurrency(result.basketTile.paymentsList[0].paymentAmount) : "";

          if (result.basketTile.basketItems.length < 1) {
            this.$router.push({path: '/Menu'});
            return;
          }

          this.basketTotal = result.basketTile.formattedTotal;
          this.discounts = result.basketTile.discounts != undefined ? result.basketTile.discounts : [];
          this.paymentLists = result.basketTile.paymentsList != undefined ? result.basketTile.paymentsList : [];

          this.$forceUpdate();
          this.dataCounter++;
        },
        async addArticle(article) {
          this.isLoading = true;

          const result = await this.doPost('TCPEditCartSubmitJ.action', {
            basketId: article.id,
            quantity: article.quantity + 1,
            orderType: this.orderType,
            xsrfToken: window.xtoken != undefined ? btoa(window.xtoken) : undefined
          });

          if (article.quantity + 1 == 0) {
            await this.deleteItem(article);
            return;
          }

          this.isLoading = false;

          window.shoppingCart = result.basketTile.basketItems;
          window.subsidy = result.basketTile.paymentsList != undefined ? this.formatCurrency(result.basketTile.paymentsList[0].paymentAmount) : "";

          if (result.basketTile.basketItems.length < 1) {
            this.$router.push({path: '/Menu'});
            return;
          }

          this.discounts = result.basketTile.discounts != undefined ? result.basketTile.discounts : [];
          this.paymentLists = result.basketTile.paymentsList != undefined ? result.basketTile.paymentsList : [];

          this.$forceUpdate();
          await this.updateCart();
          this.dataCounter++;

          if (result.exception !== undefined) {
            if (result.exception.code === 64) {
              this.makeToast('danger', this.$t('notification.removeItemMinError'));
              return;
            } else if (result.exception.code === 65) {
              this.makeToast('danger', this.$t('removeItemMaxError'));
              return;
            }
            this.makeToast('danger', this.$t('notification.removeItemFailed'));
            return;
          }
        },
          async updateCart() {
            this.isLoading = true;

            const result = await this.doPost('TCPConfirmOrderJ.action', {
              calculateOrder: window.customerLoggedIn ? true : false,
              orderType: window.customerLoggedIn ? this.orderType : undefined,
              promotionBarcode: this.promoBarcode != undefined ? this.promoBarcode : undefined
            });

            this.isLoading = false;

            if (result.exception !== undefined) {
              this.makeToast('danger', this.$t("exceptionMessage"));
              return;
            }

            if (result.errorCode > 0) {
              this.makeToast('danger', result.erroMsg);
              return;
            }

            window.xtoken = result.xsrfToken != undefined ? atob(result.xsrfToken) : undefined;
            window.shoppingCart = result.basketTile.basketItems;
            window.subsidy = result.basketTile.paymentsList != undefined ? this.formatCurrency(result.basketTile.paymentsList[0].paymentAmount) : "";
            this.basketItems = result.basketTile.basketItems;
            this.basketTotal = result.basketTile.formattedTotal;
          },
        async removeArticle(article) {
          this.isLoading = true;

          const result = await this.doPost('TCPEditCartSubmitJ.action', {
            basketId: article.id,
            quantity: article.quantity - 1,
            orderType: this.orderType,
            xsrfToken: window.xtoken != undefined ? btoa(window.xtoken) : undefined
          });

          if (article.quantity - 1 == 0) {
            this.deleteItem(article);
            return;
          }

          this.isLoading = false;

          if (result.basketTile.basketItems.length < 1) {
            this.$router.push({path: '/Menu'});
            return;
          }

          window.shoppingCart = result.basketTile.basketItems;
          window.subsidy = result.basketTile.paymentsList != undefined ? this.formatCurrency(result.basketTile.paymentsList[0].paymentAmount) : "";
          this.discounts = result.basketTile.discounts != undefined ? result.basketTile.discounts : [];
          this.paymentLists = result.basketTile.paymentsList != undefined ? result.basketTile.paymentsList : [];

          this.$forceUpdate();
          await this.updateCart();
          this.dataCounter++;

          if (result.exception !== undefined) {
            if (result.exception.code === 64) {
              this.makeToast('danger', this.$t('notification.removeItemMinError'));
              return;
            } else if (result.exception.code === 65) {
              this.makeToast('danger', this.$t('removeItemMaxError'));
              return;
            }
            this.makeToast('danger', this.$t('notification.removeItemFailed'));
            return;
          }
        },
        onSubmit() {
            window.comment = this.comment;
            this.addItemsToOrder();

            if(window.TableMode == true)
            {
                if(window.openTable != undefined)
                  this.$navigateToTablePay();
                else
                  this.$navigateToPayment();
            }
            else
            {
              if (this.getOrderType === OrderTypes.DELIVERY) {
                this.$navigateToAddress();
              } else {
                this.$navigateToPayment();
              }
            }
        }  
    },
}
</script>

<style scoped>

.shoppingCartItemsTable {
    color: var(--text-color);
    width:100%;
}

.commentField {
    width:100%;
    height:100%;
    border: 0.2px solid var(--main-color);
}


.shoppingCartDiscounts {
    border-bottom: 1px solid rgba(236,236,236,1);
    /* border-top: 1px solid rgba(236,236,236,1); */
    padding:20px;
    text-align:left;    
}

.shoppingCartDiscountsEntry {
    text-align:left;    
    padding-left:5%;
}

.shoppingCartDiscountsTitle {
    padding: 0px;
    text-align:left;
    margin-bottom:10px;
    font-weight:600;
    font-size:15px;
    color: var(--main-color);
}

.shoppingCartDiscountsEntryName{
    width:40%;
    display: inline-block;
}

.shoppingCartDiscountsEntryValue{
    margin-left:10%;
}

.forwardBackward {
  border-top: 1px solid var(--main-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

/* Container for shopping cart items */
.shoppingCartItemsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* Individual cart item entry */
.shoppingCartEntry {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 10px 0;
  width: 100%;
}
</style>