import {apiUtils} from "@/utils/apiUtils";

export const orderService = {
    async cancelOrder(shopId, guid, returnPrepayBalance) {
        try {
            const result = await apiUtils.doPost('/TCPDeleteOrderSubmitJ.action', {
                json: "true",
                shopId: shopId,
                guid: guid,
                operation: "D",  // Operation 'D' to delete the order
                returnPrepayBalance: returnPrepayBalance,
            });

            return result;
        } catch (error) {
            console.error("Error cancelling the order:", error);
            throw error; // Re-throw the error to handle it in the component
        }
    }
};