import { apiUtils } from "@/utils/apiUtils";

export const productService = {
    async getMealPlans(dateFrom, dateTo) {
        try {
            const result = await apiUtils.doGet("/TCPGetMealPlansJ.action", {
                json: true,
                dateFrom: dateFrom,
                dateTo: dateTo,
                includeReservations: true,
                includeAllergenIds: true,
                includePropertyIds: true,
                includeNutriValues: true,
                includePropertyList: true
            });

            return result;
        } catch (error) {
            console.error("Error fetching meal plans:", error);
            throw error;
        }
    },
    async getProductDetails(productId, includeChildren, addChildren, includeAllergenIds, includePropertyIds, includeNutriValues) {
        try {
            const result = await apiUtils.doGet("/TCPGetProductDetailsJ.action", {
                kkProdId: productId,
                json: true,
                includeChildren: includeChildren,
                addChildren: addChildren,
                includeAllergenIds: includeAllergenIds,
                includePropertyIds: includePropertyIds,
                includeNutriValues: includeNutriValues
            });

            return result;
        } catch (error) {
            console.error("Error fetching product details:", error);
            throw error;
        }
    }
};
