<template>
    <div class="col-12">
      <div v-if="config.SHOW_ACCOUNT_BARCODE">
          <qr-code :value="order.barcode"></qr-code>
      </div>
      <div class="col-12">
        <b-table-simple small borderless caption-top responsive>
          <caption>{{ $t('table.date') }}: {{ order.formattedDate.split(' ')[0] }}</caption>
          <b-thead head-variant="dark">
            <b-tr>
              <b-th class="text-left" colspan="3">{{ $t('table.article') }}</b-th>
              <b-th class="text-right" colspan="2">{{ $t('table.quantity') }}</b-th>
              <b-th class="text-right" colspan="2">{{ $t('table.sum') }}</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="item in order.menuItems" v-bind:key="item.articleId">
              <b-td class="text-left" colspan="3">{{ item.name }}</b-td>
              <b-td class="text-right" colspan="2">{{ item.quantity }}</b-td>
              <b-td v-if="item.formattedDiscountedPrice == undefined" class="text-right" colspan="2">{{ item.formattedPrice }}</b-td>
              <b-td v-if="item.formattedDiscountedPrice != undefined" class="text-right" colspan="2">{{ item.formattedDiscountedPrice }}</b-td>
            </b-tr>
          </b-tbody>
          <b-tfoot foot-variant="dark">
            <b-tr>
              <b-td class="text-left" variant="secondary" colspan="5"><b>{{ $t('table.sum') }}</b></b-td>
              <b-td class="text-right" variant="secondary" colspan="2"><b>{{ order.formattedTotal }}</b></b-td>
            </b-tr>
          </b-tfoot>
        </b-table-simple>
      </div>
    </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

export default {
    setup() {
        
    },
    data(){
        return {
        }
    },    
    computed:{
        config(){
            return window.config;
        }
    },
    components:{
        'qr-code' : QrcodeVue,
    },
    props:{
        order:{
            Type:Object,
            required:true
        },
        guid: {
            Type:String
        }
    }
}
</script> 

<style scoped>

</style>