<template>
  <div class="shop-page">

    <CategoryTree :categories="mealPlan.groups" @category-selected="loadCategoryItems" />
    <CategoryItems
        :categoryId="selectedCategoryId"
        :categories="mealPlan.groups"
        @product-selected="showProductDetails"
        @add-to-basket="addToBasket"
        @remove-from-basket="removeFromBasket"
    />

    <!-- Modal for product details -->
    <b-modal v-if="selectedProduct" id="product-details-modal" ref="productDetailsModal" hide-footer :title="selectedProduct.name">
      <ProductDetails :productId="selectedProduct.kkProdId" @add-to-basket="addToBasket" />
    </b-modal>

    <!-- Show GoToBasketButton only when productCount > 0 -->
    <GoToBasketButton
        :mealPlan="mealPlan"
        :productCount="productCount"
        :totalPrice="totalPrice"
    />
  </div>
</template>

<script>
import CategoryTree from "@/components/sub-components/CategoryTree.vue";
import CategoryItems from "@/components/sub-components/CategoryItems.vue";
import ProductDetails from "@/components/sub-components/ProductDetails.vue";
import GoToBasketButton from "@/components/sub-components/GoToBasketButton.vue";
import Utils from "@/utils/utils";
import {basketService} from "@/services/api/basketService";

export default {
  components: {
    GoToBasketButton,
    CategoryTree,
    CategoryItems,
    ProductDetails,
  },
  props: {
    mealPlan: {
      type: Object,
      required: true
    }
  },
  computed: {
    currentLocale() {
      return this.$i18n.locale.replace('_', '-');
    },
    formattedTitle() {
      return `${this.mealPlan.description} (${Utils.formatDate(this.mealPlan.dateValid, this.$i18n.locale)}, ${this.mealPlan.formattedStart} - ${this.mealPlan.formattedEnd})`;
    }
  },
  data() {
    return {
      selectedCategoryId: null,  // For fetching category items
      selectedProduct: null,     // For product to display in modal
      productCount: 0,
      totalPrice: 0,
    };
  },
  mounted() {
    if (history.state && history.state.mealPlan) {
      this.mealPlan = history.state.mealPlan;
    }

    window.orderType = 'dinein';
    window.reservationDateTime = Utils.addMinutesToDate(this.mealPlan.dateValid, this.mealPlan.startMins);
  },
  methods: {
    updateMealPlanMealsHistory(mealPlan) {
      if (mealPlan) {
        history.replaceState({ mealPlan: mealPlan }, '', this.$route.fullPath);
      }
    },
    goBack(){
      this.$navigateToMealPlanner();
    },
    loadCategoryItems(categoryId) {
      this.selectedCategoryId = categoryId;
    },
    showProductDetails(product) {
      this.selectedProduct = product;
      this.$nextTick(() => {
        if (this.$refs.productDetailsModal) {
          this.$refs.productDetailsModal.show();  // Open the modal
        } else {
          console.error('Modal reference is not defined.');
        }
      });
    },
    async addToBasket(basketItems) {
      const {product, selectedSubitems} = basketItems;
      console.log('Product:', product);
      console.log('Selected Subitems:', selectedSubitems);

      this.isLoading = true;
      const result = basketService.addProductToBasket(product);
      this.isLoading = false;

      if ((result.exception !== undefined)) {
        if (result.exception.code === 64) {
          this.makeToast('danger', this.$t('notification.addItemMinError'));
        } else if (result.exception.code === 65) {
          this.makeToast('danger', this.$t('notification.addItemMaxError'));
        } else {
          this.makeToast('danger', this.$t('notification.addItemFailed'));
        }
        return;
      }

      this.productCount = result.basketTile.numberOfItems;
      this.totalPrice = result.basketTile.basketTotal;

      this.$refs.productDetailsModal.hide();
      this.makeToast('success', this.$t('notification.addItemSuccess'));
    },
    async removeFromBasket(product) {
      this.isLoading = true;
      const result = await this.doPost('TCPRemoveMenuItemFromCartJ.action', {
        id: product.kkProdId,
        orderType: window.orderType
      });
      this.isLoading = false;

      if (result.exception !== undefined) {
        this.makeToast('danger', this.$t('notification.removeItemFailed'));
        return;
      }

      // Remove or update the product in the cart
      const productIndex = this.cart.findIndex(item => item.kkProdId === product.kkProdId);
      if (productIndex !== -1) {
        if (this.cart[productIndex].quantity > 1) {
          this.cart[productIndex].quantity -= 1;  // Decrease the quantity
        } else {
          this.cart.splice(productIndex, 1);  // Remove product from the cart
        }
      }

      this.makeToast('success', this.$t('notification.removeItemSuccess'));
    },
  }
};
</script>
