export function loadCss(url) {
    return new Promise((resolve, reject) => {
        // Check if the CSS file is already loaded
        const existingLink = document.querySelector(`link[href="${url}"]`);
        if (existingLink) {
            resolve();
            return;
        }

        // Remove any previously loaded theme CSS files
        const previousLinks = document.querySelectorAll('link[data-theme]');
        previousLinks.forEach(link => document.head.removeChild(link));

        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = url;
        link.setAttribute('data-theme', 'true'); // Mark this link as a theme
        link.onload = () => resolve();
        link.onerror = () => reject(new Error(`Failed to load CSS: ${url}`));
        document.head.appendChild(link);
    });
}