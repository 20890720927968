<template>
  <b-container fluid class="d-flex flex-column">
    <b-table-simple small borderless caption-top responsive>
      <b-thead head-variant="dark">
        <b-tr>
          <b-th class="text-left" colspan="3">{{ $t('table.article') }}</b-th>
          <b-th class="text-right" colspan="2">{{ $t('table.quantity') }}</b-th>
          <b-th class="text-right" colspan="2">{{ $t('table.sum') }}</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-if="prepayment">
          <b-td class="text-left" colspan="3">{{ $t('text.prepayment') }}</b-td>
          <b-td class="text-right" colspan="2">1</b-td>
          <b-td class="text-right" colspan="2">{{ getPrepaymentAmount() }}</b-td>
        </b-tr>
        <b-tr v-for="item in transaction.articleslist" v-bind:key="item.articleCode">
          <b-td class="text-left" colspan="3">{{ item.articleDescription }}</b-td>
          <b-td class="text-right" colspan="2">{{ item.qtyWeight }}</b-td>
          <b-td v-if="item.formattedDiscountedPrice == undefined" class="text-right" colspan="2">{{ item.formattedPrice }}</b-td>
          <b-td v-if="item.formattedDiscountedPrice != undefined" class="text-right" colspan="2">{{ item.formattedDiscountedPrice }}</b-td>
        </b-tr>
      </b-tbody>
      <b-tfoot foot-variant="dark">
        <b-tr>
          <b-td class="text-left" variant="secondary" colspan="5"><b>{{ $t('table.sum') }}</b></b-td>
          <b-td class="text-right" variant="secondary" colspan="2"><b>{{ transaction.formattedPaymentTotal }}</b></b-td>
        </b-tr>
      </b-tfoot>
    </b-table-simple>

    <b-table-simple v-if="config.TRANSACTION_HISTORY.showModalPaymentDetails && transaction.paymentsList != undefined && transaction.paymentsList.length > 0" small borderless caption-top responsive>
      <b-thead head-variant="light">
        <b-tr>
          <b-th class="text-left" colspan="7">{{ $t('table.paymentDetails') }}</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="item in transaction.paymentsList" v-bind:key="item.paymentCode">
          <b-td class="text-left" colspan="5">{{ item.paymentDescription }}</b-td>
          <b-td class="text-right" colspan="2">{{ item.formattedAmount }}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </b-container>
</template>

<script>
export default {
    setup() {
        
    },
    data(){
        return {
        }
    },
    computed:{
        prepayment(){
            return this.transaction.prepayment;
        },
        config(){
            return window.config;
        }
    },
    props:{
        transaction:{
            Type:Object,
            required:true
        },
    },
    methods:{
        getPrepaymentAmount(){           
            return this.transaction.paymentsList != undefined && this.transaction.paymentsList.length > 0 ? this.transaction.paymentsList.filter(function(x) { return x.isPrepayment })[0].formattedAmount : undefined;
        }
    }
}
</script> 

<style scoped>

</style>