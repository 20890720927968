<template>
  <div class="loginPageWrapper">
    <loading :active.sync="isLoading"
             :can-cancel="true"
             :is-full-page="true"
             loader='bars'></loading>

    <div class="">
      <b-container fluid>
        <b-row class="rowGap20 gap10">
          <b-col id="loginCol" cols="12" md="6">
            <FlexContainer :title="$t('title.login')" #content>
              <b-form @submit.stop.prevent>
                <b-form-row>
                  <b-form-group class="form-group fontField w-100" label-for="emailAddr">
                    <template v-slot:label>
                      {{ $t('field.email') }}
                    </template>
                    <!--<label for="emailAddr" class="d-block" id="emailAddrLabel">-->
                    <!--  {{ $t('field.email') }}-->
                    <!--</label>-->
                    <b-form-input class="form-control form-control-lg" id="emailAddr" name="emailAddr" type="email" v-model="$v.form.emailAddr.$model"
                                  :state="validateState('emailAddr')" aria-describedby="emailAddr-feedback"
                                  :maxLength="config.CUSTOMER_DATA.FIELDS.emailAddr.maxLength" />
                    <b-form-invalid-feedback class="text-left" id="emailAddr-feedback">{{ $t('validation.invalidEmail') }}</b-form-invalid-feedback>
                  </b-form-group>
                  <!--<b-col cols="12">-->
                  <!--</b-col>-->
                </b-form-row>
                <b-form-row>
                  <b-form-group class="form-group fontField w-100" label-for="password" :label="$t('field.password')">
                    <!--<template v-slot:label>-->
                    <!--  {{ $t('field.password') }}-->
                    <!--</template>-->
                    <b-input-group>
                      <b-form-input v-if="hidePassword" class="form-control form-control-lg" id="password" name="password" type="password"
                                    v-model="$v.form.password.$model"
                                    :state="validateState('password')"
                                    aria-describedby="password-feedback" />
                      <b-form-input v-else class="form-control form-control-lg" id="password" name="password" type="text" v-model="$v.form.password.$model"
                                    :state="validateState('password')"
                                    aria-describedby="password-feedback" />
                      <b-form-invalid-feedback class="text-left order-last" id="password-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback> <!-- ! important: order-last because corner gets croped else -->
                      <b-input-group-append>
                        <b-button v-if="config.SHOW_PASSWORD_VISIBILITY_SWITCH" variant="outline-secondary" type="button" @click="showPassword()"
                                  :aria-label="hidePassword ? $t('ariaLabel.showPasswordNew') : $t('ariaLabel.hidePasswordNew')">
                          <b-icon-eye-fill v-if="hidePassword" aria-hidden="true" :aria-label="$t('ariaLabel.showPasswordNew')"></b-icon-eye-fill>
                          <b-icon-eye-slash-fill v-else aria-hidden="true" :aria-label="$t('ariaLabel.hidePasswordNew')"></b-icon-eye-slash-fill>
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-form-row>
                <b-form-row>
                  <b-button type="submit" @click.stop="onClickCaptcha" :disabled="submitStatus === 'PENDING'"
                            class="btn btn-primary btn-lg btn-block btnSubmit g-recaptcha mx-0" style="width: 100%">{{ $t('button.login') }}
                  </b-button>
                  <b-button type="submit" @click.stop.prevent="loginSSO" v-if="SSOEnabled" :disabled="submitStatus === 'PENDING'" style="width: 100%"
                            class="btn btn-dark btn-lg btn-block btnSubmit"></b-button>
                  <b-link class="forgot-password-link" href="#" @click.prevent="goToForgotPassword">{{ $t('link.forgotPassword') }}</b-link>
                </b-form-row>
              </b-form>
            </FlexContainer>
              <!--<h3>{{ $t('subTitle.login') }}</h3>-->
          </b-col>
          <b-col id="registerCol" cols="12" md="6">
            <FlexContainer :title="$t('title.register')" #content class="h-100">
              <b-form @submit.stop.prevent>
                <b-form-row class="flex-column">
                  <label style="" v-if="!tableMode" v-html="$t('text.registerInfo')" class="text-left"></label>
                  <b-button id="afRegister" class="btn btn-primary btn-lg btn-block btnSubmit g-recaptcha mx-0 w-100 noMargin" type="submit" @click="goToRegister()" :disabled="submitStatus === 'PENDING'">{{$t('button.register')}}</b-button>
                  <b-button v-if="config.REGISTER_PARENT.enabled" class="btn btn-primary btn-lg btn-block btnSubmit g-recaptcha mx-0 w-100 noMargin" type="submit" @click="goToRegisterAsParent()" :disabled="submitStatus === 'PENDING'">{{$t('button.registerAsParent')}}</b-button>
                  <b-button v-if="!isCartEmpty && config.GUEST_CHECKOUT.enabled" class="btn btn-primary btn-lg btn-block btnSubmit g-recaptcha mx-0 w-100 noMargin" type="submit" @click="goToGuest()" :disabled="submitStatus === 'PENDING'">{{$t('button.registerGuest')}}</b-button>
                </b-form-row>
              </b-form>
            </FlexContainer>
            <!--<h3>{{ $t('subTitle.register') }}</h3>-->
            <b-table-simple class="tableResponsive" small borderless caption-top responsive :aria-label="$t('title.register')" aria-flowto="afRegister" v-if="false"> <!-- todo remove this -->
              <b-thead head-variant="dark">
                <b-tr>
                  <b-th class="text-left" colspan="12">{{ $t('subTitle.register') }}</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr class="text-left" colspan="12">
                  <b-td>
                    <b-form @submit.stop.prevent>
                      <b-form-row class="flex-column">
                        <label style="" v-if="!tableMode" v-html="$t('text.registerInfo')" class="text-left"></label>
                        <b-button id="afRegister" class="btn btn-primary btn-lg btn-block btnSubmit g-recaptcha mx-0 w-100 noMargin" type="submit" @click="goToRegister()" :disabled="submitStatus === 'PENDING'">{{$t('button.register')}}</b-button>
                        <b-button v-if="config.REGISTER_PARENT.enabled" class="btn btn-primary btn-lg btn-block btnSubmit g-recaptcha mx-0 w-100 noMargin" type="submit" @click="goToRegisterAsParent()" :disabled="submitStatus === 'PENDING'">{{$t('button.registerAsParent')}}</b-button>
                        <b-button v-if="!isCartEmpty && config.GUEST_CHECKOUT.enabled" class="btn btn-primary btn-lg btn-block btnSubmit g-recaptcha mx-0 w-100 noMargin" type="submit" @click="goToGuest()" :disabled="submitStatus === 'PENDING'">{{$t('button.registerGuest')}}</b-button>
                      </b-form-row>
                    </b-form>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import $ from 'jquery'
import { BIconEyeFill, BIconEyeSlashFill } from 'bootstrap-vue'
import VueLoading from 'vue-loading-overlay'
import {UserTypes} from "@/constants/constants";
import FlexContainer from "@/components/page-components/templates/FlexContainer.vue";
import Utils from "@/utils/utils";
import {mapActions} from "vuex";

export default {
    data() {
        return {
            submitStatus: null,
            alert: {
                message: "hello world"
            },
            form: {
                emailAddr: undefined,
                password: undefined,
            },
            formErrorMessage : null,
            siteKey: "",
            responseToken: "",
            hidePassword: true,
            rememberMe: false,
            isLoading: false,
            submit: false,
        }
	},
    computed: {
        config(){
            return window.config;
        },
        tableMode(){
            if(window.TableMode == true)
                return true;
            else
                return false;
        },
        SSOEnabled(){
            return this.config.SSO.enabled;
        },
        isCartEmpty()
        {
            if(window.TableMode == true)
            {
                return false;
            }

            if(window.shoppingCart !== undefined && window.shoppingCart !== null)
            {
                if(window.shoppingCart.length == 0)
                    return true;
                else
                    return false;
            }
            else
            {
                return true;
            }
        }
    },
    validations: {
       form: {
          emailAddr: {
              required,
              email
          },
         password: {
           required,
         },
      },
    },
    components: {
      FlexContainer,
        'loading': VueLoading,
        BIconEyeFill,
        BIconEyeSlashFill,
    },
    methods: {
      ...mapActions('locale', ['setLocale']),

      async changeLanguage(newLocale) {
        await this.setLocale(newLocale);
      },
      validateState(name) {
        const {$dirty, $error} = this.$v.form[name];
        return $dirty ? !$error : null;
      },
      // eslint-disable-next-line no-undef
      onClickCaptcha(e) {
          this.submit = true;
          console.log("onClickCaptcha");
          e.preventDefault();
          if(window.config.G_CAPTCHA.enabled == true)
          {
              // eslint-disable-next-line no-undef
              grecaptcha.ready(function() {
                  // eslint-disable-next-line no-undef
                  grecaptcha.execute(window.config.G_CAPTCHA.siteKey, {action: 'submit'}).then(function(token) {
                      this.responseToken = token;
                      this.onSubmit(e);
                  });
              });
          }
          else
          {
              this.onSubmit(e);
          }

      },
      showPassword() {
          this.hidePassword = !this.hidePassword;
          return;
      },
      handleLoginSuccess: async function (result) {
        if (result.errorCode < 0) {
          return;
        }

        const newLocale = !Utils.isEmpty(result.customer.locale) ? result.customer.locale : this.config.LANGUAGE_LIST[0].value;
        await this.changeLanguage(newLocale.replace('-', '_'));
        window.customerLoggedIn = result.loginUsername;
      },
      goBack(){
          this.$router.go(-2);
      },
      goToForgotPassword() {
        this.$navigateToForgotPassword();
      },
      goToRegister() {
        this.$navigateToRegister();
      },
      goToGuest() {
        this.$navigateToGuest();
      },
      goToRegisterAsParent() {
        this.$navigateToRegisterAsParent();
      },
      loginSSO() {
          window.location.href = window.config.SSO.login_url;
      },
      onSubmit: async function (event) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.makeToast('danger', this.$t('notification.fillAllRequiredFields'));
          return;
        }

        this.formErrorMessage = null;
        var config = window.config;
        event.preventDefault();
        this.isLoading = true;

        const result = await this.doPost('TCPLoginSubmitJ.action', {
          json: "true",
          loginUsername: this.form.emailAddr,
          password: this.form.password,
          responseToken: this.responseToken != "" ? this.responseToken : undefined,
          rememberMe: this.rememberMe
        });

        this.isLoading = false;

        if (result.exception !== undefined) {
          this.makeToast('danger', this.$t('notification.loginError'));
          return;
        }

        if (result.page == "tcp.catalog.change.password.page") {
          this.$navigateToPasswordReset();
          return;
        }

        if (result.errorCode >= 0) {
          if (config.USE_ALLERGEN_FILTERS && result.wondCustomer.dietaryInfo != undefined) {
            window.dietaryInfo = result.wondCustomer.dietaryInfo;
          }

          await this.handleLoginSuccess(result);

          if (UserTypes.PARENT === result.customer.custom3) {
            this.$navigateToStudentAccounts(true);
            return;
          }
          this.$navigateToHome();
        } else {
          if (this.config.USE_SPECIFIC_ERROR_MESSAGE_TPCLOGIN) {
            if (result.errorCode == -1 || result.errorCode == -2 || result.errorCode == -3 || result.errorCode == -4 || result.errorCode == -6) {

              this.formErrorMessage = result.errorMsg;

              const h = this.$createElement;
              const message = h('div', [
                this.$t('notification.loginError'),
                h('br'),
                h('br'),
                this.$t('notification.loginErrorSpecific'),
              ]);

              this.makeToast('danger', [message]);
            } else {
              this.formErrorMessage = result.errorMsg;
              this.makeToast('danger', this.$t('notification.loginError'));
            }
          } else {
            this.formErrorMessage = result.errorMsg;
            this.makeToast('danger', this.$t('notification.loginError'));
          }
        }
      }
    },
    async created() {
      const result = await this.doGet('TCPLoginJ.action', {
        json: "true"
      });

      if (result.xsrfToken != undefined) {
        window.xtoken = atob(result.xsrfToken);
      }

      if (result.exception !== undefined) {
        this.makeToast('danger', this.$t('notification.loginError'));
        return;
      }

      if (result.page == "tcp.catalog.afterlogin.page") {
        window.customerLoggedIn = true;
        console.log(window.history.length);
        if (window.history.length > 2)
          this.$router.go(-1);
        return;
      }

      if (window.customerLoggedIn != undefined && window.customerLoggedIn != null) {
        this.$router.push({path: '/Account'});
        return;
      }
    },
    mounted(){
    //    $(".header").addClass("header-big");
    //         $(".header").addClass("animTransition");
    //         $(".content").addClass("header-big-content-margin");
    //         $(".content").removeClass("header-small-content-margin");
        $(".content").addClass("header-small-content-margin");
        // $(".click").on('click', goToForgotPasswort());
        this.siteKey = window.config.G_CAPTCHA.siteKey;
    },
}
</script>

<style scoped>
  input[type="password"]::-ms-reveal {
    display: none;
  }

  .loginRightBlock p
  {
      padding: 30px 10% 30px 10%;
      display: inline-block;
      font-size: 18px;
  }

  .forgot-password-link {
    text-decoration: none;
    color: var(--main-color);
    font-weight: bold;
    margin-left: 6px;
  }

  .forgot-password-link:hover {
    color: var(--secondary-color);
  }
  @media screen and (max-width: 768px) {
    form {
      padding: 10px 20px;
    }
  }
</style>