import axios from 'axios';

const axiosClient = axios.create({
    baseURL: window.config.API_BASE,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }
});

export default axiosClient