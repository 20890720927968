<template>
    <form @submit.prevent id="addressForm">

        <loading :active.sync="isLoading"
                 :can-cancel="true"
                 :is-full-page="true"
                 loader='bars'></loading>

        <div class="col-12">

            <div class="row">
                <div class="form-group col-12 col-md-6" v-show="config.ADDRESS_FORM_FIELDS.firstName ==''">
                    <label class="fontField">{{ $t('field.firstName')}}<span v-if="status($v.addressObj.firstName)" style="color:var(--main-color);">*</span></label>
                    <input type="text" maxlength="40" class="form-control form-control-lg" v-model="addressObj.firstName" />
                    <!-- <div class="inputError" role="alert" v-if="!$v.edit.firstName.required">Please enter your name</div>           -->
                </div>

                <div class="form-group col-12 col-md-6" v-show="config.ADDRESS_FORM_FIELDS.lastName ==''">
                    <label class="fontField">{{ $t('field.lastName')}}<span v-if="status($v.addressObj.lastName)" style="color:var(--main-color);">*</span></label>
                    <input type="text" class="form-control form-control-lg"  maxlength="40"  v-model="addressObj.lastName" />
                    <!-- <div class="inputError" role="alert" v-if="!$v.edit.lastName.required">Please enter your last name</div>           -->
                </div>
            </div>
            <div class="row">
                <div class="form-group col-12 col-md-6" v-show="config.ADDRESS_FORM_FIELDS.streetAddress == ''">
                    <label class="fontField">{{ $t('field.street')}}<span v-if="status($v.addressObj.streetAddress)" style="color:var(--main-color);">*</span></label>
                    <input type="text" maxlength="40" class="form-control form-control-lg" v-model="addressObj.streetAddress" />
                </div>
            </div>

            <div class="row">
                <div class="form-group col-12 col-md-6" v-show="config.ADDRESS_FORM_FIELDS.postcode == ''">
                    <label class="fontField">{{ $t('field.postalCode')}}<span v-if="status($v.addressObj.postcode)" style="color:var(--main-color);">*</span></label>
                    <input type="text" class="form-control form-control-lg"  maxlength="10"  v-model="addressObj.postcode" inputmode="numeric"/>
                </div>

                <div class="form-group col-12 col-md-6" v-show="config.ADDRESS_FORM_FIELDS.city == ''">
                    <label class="fontField">{{ $t('field.city')}}<span v-if="status($v.addressObj.city)" style="color:var(--main-color);">*</span></label>
                    <input type="text"  class="form-control form-control-lg" maxlength="50" v-model="addressObj.city" />
                </div>
            </div>

            <div class="row">
                <div class="form-group col-12 col-md-6" v-show="config.ADDRESS_FORM_FIELDS.countryId  == ''">
                    <label class="fontField">{{$t('field.country')}}</label>
                    <input type="text" class="form-control form-control-lg" v-model="addressObj.countryId" />
                </div>
            </div>

            <div class="row">
                <div class="form-group col-12 col-md-6" v-if="countriesLoaded && config.ADDRESS_FORM_FIELDS.country !== undefined">
                    <label class="fontField">{{$t('field.country')}}</label>
                    <!-- <input type="text" class="form-control form-control-lg" v-model="registration.country" />
                    <div class="inputError" role="alert" v-if="!$v.registration.country.required">Please enter your country</div> -->
                    <!-- <select-picker-country class="countrySelect" v-if="countriesLoaded" :options="countries" :callback="selectCountry" :preselectedId="selectedCountryId" ></select-picker-country> -->
                    <select-picker-country class="countrySelect" :options="countries" :callback="selectCountry" :preselectedId="selectedCountryId" ></select-picker-country>
                </div>

                <div class="form-group col-12 col-md-6" v-show="config.ADDRESS_FORM_FIELDS.state == ''">
                    <label class="fontField">{{ $t('field.state')}}<span v-if="status($v.addressObj.state)" style="color:var(--main-color);">*</span></label>
                    <input type="text" class="form-control form-control-lg" v-model="addressObj.state" />
                </div>
            </div>

            <div class="row">
                <div class="form-group col-12 col-md-6" v-show="config.ADDRESS_FORM_FIELDS.telephoneNumber == ''">
                    <label class="fontField">{{ $t('field.telephoneNumber')}}<span v-if="status($v.addressObj.telephoneNumber)" style="color:var(--main-color);">*</span></label>
                    <input type="text" class="form-control form-control-lg" maxlength="40" inputmode="tel" v-model="addressObj.telephoneNumber" />
                </div>
            </div>

        </div>
        <div class="col-12">
            <b-button @click="onSubmit" class="btn btn-primary btn-lg btn-block btnSubmit floatRight w-100 noMargin">
                {{ submitText }}
            </b-button>
        </div>
    </form>
</template>

<script>
// import $ from 'jquery'
import VueLoading from 'vue-loading-overlay'
import selectPickerCountry from '../sub-components/SelectPickerCountry.vue'
import { required } from 'vuelidate/lib/validators'
export default {
    data(){
        return {
            addressObj: {
                Type:Object
            },
            countriesLoaded: false,
            countries: {
                Type: Array,
                default:[]
            },
            selectedCountry: {
                Type:Object
            },
            zones: {
                Type: Array
            },
            selectedCountryId: 0,
            isLoading: false
        }
    },
    validations: { // STANDARD
        addressObj: {
            firstName:{
                required,
            },
            lastName:{
                required,
            },
            streetAddress:{
                required,
            },
            streetAddress1:{
            },
            state:{
            },
            city:{
                required,
            },
            postcode:{
                required,
            },
            title:{
                // required
            },
            suburb:{
                // required
            },
            countryId:{
                // required
            },
            telephoneNumber:{
                //required
            }
        }
    },
    components:{
        'loading': VueLoading,
        'select-picker-country' : selectPickerCountry
    },
    props:{
        isEdit: {
            Type:Boolean
        },
        addressModel: {
            Type: Object,
            default: { id: 0 }
        },
        addressId: { default: 0 },
        callbackClose: { Type: Function },
        customer: { Type: Object },
        submitText:{
            type: String,
            default: null,
        },
    },
    computed: {
        config() {
            return window.config;
        },
        message(){
            if(this.submitText === null){
                return this.$t('button.submit');
            } else {
                return this.submitText;
            }
        },
    },
    methods:{
        status(validation)
        {
            console.log(validation);
            // var result = {
            //     error: validation.$error,
            //     dirty: validation.$dirty
            // }
            return validation.$error;
        },
        validate(){
            this.$v.$touch();
            this.$forceUpdate();
        },
        selectCountry(countryId) {
            console.log("selectCountry");
            console.log(countryId);
            this.addressObj.countryId = countryId;
        },
        async onSubmit() {
            this.isLoading = true;
            this.$v.$touch();
            console.log(this.$v);

            if (this.$v.$invalid) {
                this.isLoading = false;
                this.makeToast('warning', this.$t('notification.fillAllRequiredFields'));
                return;
            }

            if (this.isEdit && this.addressId > 0) {
                var vueInstance = this;
                vueInstance.addressObj.json = true;

                console.log(vueInstance.customer);
                console.log(vueInstance.addressModel);

                await this.doPost(this.addressModel.isPrimary ? 'TCPEditCustomerSubmitJ.action' : 'TCPEditAddrSubmitJ.action', {
                    gender: vueInstance.addressObj.gender ?? vueInstance.customer.gender,
                    firstName: vueInstance.addressObj.firstName ?? vueInstance.customer.firstName,
                    lastName: vueInstance.addressObj.lastName ?? vueInstance.customer.lastName,
                    city: vueInstance.addressObj.city ?? vueInstance.customer.city,
                    company: vueInstance.addressObj.company ?? vueInstance.customer.company,
                    countryId: vueInstance.addressObj.countryId.toString() ?? vueInstance.customer.countryId.toString(),
                    postcode: vueInstance.addressObj.postcode ?? vueInstance.customer.postcode,
                    state: vueInstance.addressObj.state ?? vueInstance.customer.state,
                    streetAddress: vueInstance.addressObj.streetAddress ?? vueInstance.customer.streetAddress,
                    streetAddress1: vueInstance.addressObj.streetAddress1 ?? vueInstance.customer.streetAddress1,
                    suburb: vueInstance.addressObj.suburb ?? vueInstance.customer.suburb,
                    email: vueInstance.addressObj.email ?? vueInstance.customer.email,
                    countryChange: "0",
                    json: "true",
                    xsrfToken: window.xtoken != undefined ? btoa(window.xtoken) : undefined,
                    telephoneNumber: this.addressModel.isPrimary ? vueInstance.addressObj.telephoneNumber ?? vueInstance.customer.telephoneNumber : undefined,
                    telephoneNumber1: this.addressModel.isPrimary ? vueInstance.addressObj.telephoneNumber1 ?? vueInstance.customer.telephoneNumber1 : undefined,
                    phone: !this.addressModel.isPrimary ? vueInstance.addressObj.phone ?? vueInstance.customer.phone : undefined,
                    phone1: !this.addressModel.isPrimary ? vueInstance.addressObj.phone1 ?? vueInstance.customer.phone1 : undefined,
                });

                vueInstance.isLoading = false;
                vueInstance.callbackClose();
            } else {
                vueInstance = this;
                let config = window.config;
                vueInstance.addressObj.json = true;

                const result = await this.doPost('TCPNewAddrSubmitJ.action', {
                    gender: (config.ADDRESS_FORM_FIELDS.gender != "" ? config.ADDRESS_FORM_FIELDS.gender : vueInstance.customer.gender) ?? vueInstance.addressObj.gender,
                    firstName: (config.ADDRESS_FORM_FIELDS.firstName != "" ? config.ADDRESS_FORM_FIELDS.firstName : vueInstance.addressObj.firstName) ?? vueInstance.customer.firstName,
                    lastName: (config.ADDRESS_FORM_FIELDS.lastName != "" ? config.ADDRESS_FORM_FIELDS.lastName : vueInstance.addressObj.lastName) ?? vueInstance.customer.lastName,
                    city: (config.ADDRESS_FORM_FIELDS.city != "" ? config.ADDRESS_FORM_FIELDS.city : vueInstance.addressObj.city) ?? vueInstance.customer.city,
                    company: (config.ADDRESS_FORM_FIELDS.company != "" ? config.ADDRESS_FORM_FIELDS.company : vueInstance.addressObj.company) ?? vueInstance.customer.company,
                    countryId: (config.ADDRESS_FORM_FIELDS.countryId != "" ? config.ADDRESS_FORM_FIELDS.countryId : vueInstance.addressObj.countryId) ?? vueInstance.customer.addresses[0].countryId.toString(),
                    postcode: (config.ADDRESS_FORM_FIELDS.postcode != "" ? config.ADDRESS_FORM_FIELDS.postcode : vueInstance.addressObj.postcode) ?? vueInstance.customer.postcode,
                    state: (config.ADDRESS_FORM_FIELDS.state != "" ? config.ADDRESS_FORM_FIELDS.state : vueInstance.addressObj.state) ?? vueInstance.customer.state,
                    streetAddress: (config.ADDRESS_FORM_FIELDS.streetAddress != "" ? config.ADDRESS_FORM_FIELDS.streetAddress : vueInstance.addressObj.streetAddress) ?? vueInstance.customer.streetAddress,
                    streetAddress1: (config.ADDRESS_FORM_FIELDS.streetAddress1 != "" ? config.ADDRESS_FORM_FIELDS.streetAddress1 : vueInstance.addressObj.streetAddress1) ?? vueInstance.customer.streetAddress1,
                    suburb: (config.ADDRESS_FORM_FIELDS.suburb != "" ? config.ADDRESS_FORM_FIELDS.suburb : vueInstance.addressObj.suburb) ?? vueInstance.customer.suburb,
                    telephoneNumber: (config.ADDRESS_FORM_FIELDS.telephoneNumber != "" ? config.ADDRESS_FORM_FIELDS.telephoneNumber : vueInstance.addressObj.telephoneNumber) ?? vueInstance.customer.telephoneNumber,
                    phone1: (config.ADDRESS_FORM_FIELDS.phone1 != "" ? config.ADDRESS_FORM_FIELDS.phone1 : vueInstance.addressObj.phone1) ?? vueInstance.customer.phone1,
                    email: (config.ADDRESS_FORM_FIELDS.email != "" ? config.ADDRESS_FORM_FIELDS.email : vueInstance.addressObj.email) ?? vueInstance.customer.email,
                    countryChange: "0",
                    json: "true",
                    xsrfToken: window.xtoken != undefined ? btoa(window.xtoken) : undefined
                });

                vueInstance.isLoading = false;
                vueInstance.addressModel = result;
                vueInstance.callbackClose();
            }
        }
    },
    async mounted() {
        this.addressObj = this.addressModel;
        if (this.isEdit && this.addressId > 0) {
            var vueInstance = this;

            await vueInstance.doGet('TCPEditAddrJ.action', {json: "true", addrId: vueInstance.addressId});

            vueInstance.validate();
        } else {
            vueInstance = this;

            await vueInstance.doGet('TCPNewAddrJ.action', {json: "true"});

            vueInstance.validate();
        }
    },
    async beforeMount() {
        var vueInstance = this;

        const result = await vueInstance.doGet('TCPCustomerRegistrationJ.action', {json: "true", countryChange: 0});

        vueInstance.countries = result.countries;
        vueInstance.selectedCountryId = result.selectedCountry.id;
        vueInstance.countriesLoaded = true;
        vueInstance.$forceUpdate();
    }
}
</script>

<style scoped>
</style>