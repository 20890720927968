<template>
  <div>
    <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="true" loader="bars"></loading>

    <shop-chooser :isShopSelected="isShopSelected" id="mealPlanShopChooser" :callback="navigateTo" />
  </div>
</template>

<script>
import VueLoading from "vue-loading-overlay";
import Utils from "@/utils/utils";
import ShopChooser from "@/components/main-components/ShopChooser.vue";
import {mapActions} from "vuex";
import {OrderTypes} from "@/constants/constants";

export default {
  components: {
    ShopChooser,
    loading: VueLoading,
  },
  data() {
    return {
      isLoading: false,
      isShopSelected: false,
      selectedShopId: null, // Store selected shop ID
    };
  },
  props: {
    isMealPlan: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    Utils() {
      return Utils;
    },
  },
  methods: {
    ...mapActions('orders', ['updateOrderType']), // Map the action to the method
    ...mapActions('shops', ['selectShop']), // Map the action to the method
    setOrderType(type) {
      this.updateOrderType(type); // Call the action to set orderType
    },
    setSelectedShop(shop) {
      this.selectShop(shop)
    },
    navigateTo(shop) {
      if (this.isMealPlan) {
        this.setOrderType(OrderTypes.DINE_IN);
        this.setSelectedShop(shop);

        this.$navigateToMealPlanner(false, shop);
      } else {
        this.$navigateToMenu();
      }
    },
  },
  mounted() {
    Utils.adjustInnerScrollHeight();
    window.addEventListener('resize', Utils.adjustInnerScrollHeight);
  },
};
</script>

<style scoped>
</style>
