<!--
Usage:
<FlexContainer title="Hello World">
  My Full Content
</FlexContainer>

<FlexContainer title="Hello World" #content>
  Content inside of [.container-content]
</FlexContainer>

<FlexContainer>
  <template v-slot:content>
  </template>
  <template v-slot:default>
  </template>
</FlexContainer>
-->

<template>
  <!--<div class="grid-container">-->
  <!--  &lt;!&ndash; First container &ndash;&gt;-->
  <!--  <div class="container" role="region" :aria-labelledby="containerId" v-if="visible">-->
      <div class="flexible-container" :aria-label="title" v-if="visible">
        <!-- Accessible Container Header -->
        <div class="container-header" :id="containerId">
          <h2 >{{title}}</h2>
        </div>
        <!-- Container Content -->
        <div v-if="$slots.content" class="container-content">
          <slot name="content">This is the default content of the container.</slot>
        </div>
        <slot/>
      </div>
  <!--  </div>-->
  <!--</div>-->
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    containerId: {
      type: String,
      default: 'container-title'
    },
    title: {
      type: String,
      default: ''
    }
  }
};
</script>

<style scoped>
.flexible-container {
  border: 2px solid var(--container-header-background-color);
  border-radius: var(--container-header-border-radius);
  overflow: hidden;
}

/* Grid container */
.grid-container {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(2, 1fr);
}

/* Container styles */
.container {
  display: grid;
  //margin-top: 10px;
  //padding: var(--container-padding);
  background-color: var(--container-background-color);
  border-radius: var(--container-border-radius);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.container:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Header styles */
.container-header {
  background-color: var(--container-header-background-color);
  color: var(--container-header-text-color);
  font-size: var(--container-header-font-size);
  padding: var(--container-header-padding);
  text-align: left;
  font-weight: bold;
  & > h2 {
    font-size: 22px;
    margin: 0;
  }
  //border-radius: var(--container-header-border-radius);
}

.container-header:focus {
  outline: 4px solid var(--container-header-focus-color);
  outline-offset: 2px;
}

/* Content styles */
.container-content {
  padding: 10px;
  //margin-top: 20px;
  font-size: 16px;
  line-height: 1.6;
  color: var(--container-content-text-color);
  text-align: left;
}

/* Ensure focus visibility for keyboard navigation */
a:focus,
button:focus,
.container-header:focus {
  outline: 3px solid var(--container-header-focus-color);
}

/* Responsive layout for smaller screens */
@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}
</style>