// src/store/modules/customers.js

const state = {
    customers: [],        // List of all customers
    selectedCustomer: null, // Currently selected customer
};

const getters = {
    getCustomers: (state) => state.customers,       // Retrieve all customers
    selectedCustomer: (state) => state.selectedCustomer, // Retrieve the selected customer
};

const actions = {
    // Fetch customers from an API or other data source
    async fetchCustomers({ commit }) {
        try {
            const customers = await fetch('/api/customers'); // Replace with actual API call
            const customerData = await customers.json();
            commit('setCustomers', customerData);
        } catch (error) {
            console.error("Error fetching customers", error);
        }
    },

    // Action to set the selected customer
    selectCustomer({ commit }, customer) {
        commit('setSelectedCustomer', customer);
    },

    // Clear the selected customer
    clearSelectedCustomer({ commit }) {
        commit('setSelectedCustomer', null);
    },
};

const mutations = {
    // Set the list of customers in the state
    setCustomers: (state, customers) => {
        state.customers = customers;
    },

    // Set the selected customer in the state
    setSelectedCustomer: (state, customer) => {
        state.selectedCustomer = customer;
    },
};

export default {
    namespaced: true, // Enables calling actions like 'customers/fetchCustomers'
    state,
    getters,
    actions,
    mutations,
};