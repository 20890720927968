<template>
    <div style="height:100%; width:100%; z-index:999999999999;">
        <div style="top:45%;position:inherit;" v-if="success">            
            <p>{{ message }}</p>
            <b-button @click="$router.push({path:'/Shop'})" class="btn btn-primary">{{ $t('button.backToShop') }}</b-button>
        </div>      
    </div>
</template>

<script>
export default {
    data() {
        return {
            success: null,
            message:"",
        }
    },
    computed:{
        config(){
            return window.config;
        }
    },
    props:{
        k: {
            Type: String
        },
        m: {
            Type: String
        },
        i: {
            Type: String
        }
    },
    async beforeMount() {
      var vueInstance = this;

      const result = await vueInstance.doPost('TCPEnableCustomerJ.action', {
        json: "true",
        key: this.k,
        m: this.m,
        i: this.i
      });

      vueInstance.success = true;

      if (result.errorCode == 0) {
        vueInstance.message = vueInstance.$t('text.emailConfirmed1') + " " + result.emailAddr + " " + vueInstance.$t('text.emailConfirmed2');
        return;
      }
      vueInstance.message = result.message;
    }
}

</script> 