<template>
    <div>
      <b-form class="noPadding">
        <b-form-row>
          <b-col cols="12" md="12">
            <b-form-group class="form-group fontField" label-for="emailAddr">
              <template v-slot:label>
                {{ $t('field.email') }} <span class="text-danger">*</span>
              </template>
              <b-form-input class="form-control form-control-lg" id="emailAddr" name="emailAddr" type="email" required v-model="$v.form.emailAddr.$model"
                            :state="validateState('emailAddr')" aria-describedby="emailAddr-feedback" :maxLength="config.CUSTOMER_DATA.FIELDS.emailAddr.maxLength"/>
              <b-form-invalid-feedback class="text-left" id="emailAddr-feedback">{{ $t('validation.invalidEmail') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" md="12">
            <b-button type="submit" @click.stop="onClickCaptcha" :disabled="submitStatus === 'PENDING' || $v.$invalid"
                      class="btn btn-primary btn-block btnSubmit col col-md-auto g-recaptcha w-100 noMargin">{{ $t('button.submit') }}
            </b-button>
          </b-col>
        </b-form-row>
      </b-form>
      <!--<div class="">-->
      <!--  <b-container fluid>-->
      <!--  </b-container>-->
      <!--</div>-->
    </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'

export default {
    mounted() {
      this.$refs.backButton.focus();
    },
     data() {
       return {
         form: {
           emailAddr: undefined,
         },
         submitStatus: null,
         responseToken: "",
       }
     },
    computed: {
      config() {
        return window.config;
      },
    },
    validations: {
      form: {
        emailAddr: {
          required,
          email,
        },
      },
    },
    methods: {
      validateState(name) {
        const {$dirty, $error} = this.$v.form[name];
        return $dirty ? !$error : null;
      },
        // eslint-disable-next-line no-undef
        onClickCaptcha(e) {
            console.log("onClickCaptcha");
            e.preventDefault();
            if(window.config.G_CAPTCHA.enabled == true)
            {
                // eslint-disable-next-line no-undef
                grecaptcha.ready(function() {
                    // eslint-disable-next-line no-undef
                    grecaptcha.execute(window.config.G_CAPTCHA.siteKey, {action: 'submit'}).then(function(token) {
                        this.responseToken = token;
                        this.onSubmit(e);          
                    });
                });
            }
            else
            {
                this.onSubmit(e);
            }
       
        },
        goBack(){
            this.$router.go(-1);
        },
       onSubmit: async function (event) {
         event.preventDefault();

         this.$v.$touch();
         if (this.$v.$invalid) {
           this.submitStatus = 'ERROR';
           return;
         } else {
           this.submitStatus = 'OK'
         }

         await this.doPost('TCPForgotPasswordSubmitJ.action', {
           json: "true",
           emailAddr: this.form.emailAddr,
           xsrfToken: window.xtoken != undefined ? btoa(window.xtoken) : undefined,
           responseToken: this.responseToken != "" ? this.responseToken : undefined
         });

         // Show toast and trigger a callback for navigation
         this.makeToast(
             'success',
             this.$t('notification.forgotPasswordSuccessful'),
             this.navigateToLogin // Pass navigateToLogin as a callback
         );
       }, 
      navigateToLogin() {
        this.$navigateToLogin();
      }
  },
}
</script>