<template>
  <div id="shopChooser" class="shopChooser">
    <b-container fluid>
      <b-row v-if="!isShopSelected || isShopChange">
        <b-table-simple class="tableResponsive" small borderless caption-top responsive>
          <b-thead head-variant="dark">
            <b-tr>
              <b-th class="text-left" colspan="12">{{ $t('subTitle.chooseShop') }}</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td>
                <div>
                  <b-button v-if="isShopChange" v-b-tooltip :title="$t('button.close')" variant="primary" @click="changeShop(false)">
                    <font-awesome-icon :icon="['fas', 'shop-slash']"/> {{ $t('button.close') }}
                  </b-button>
                </div>
                <div>
                  <shop-card v-for="shop in shops" v-bind:key="shop.id" :id="shop.id" :addresses="shop.addresses" :image="shop.image" :name="shop.name" :url="shop.custom10" :code="shop.custom2" :callback="selectShop"></shop-card>
                </div>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-row>
      <b-row v-if="isShopSelected">
        <b-table-simple class="tableResponsive" small borderless caption-top responsive>
          <b-thead head-variant="dark">
            <b-tr>
              <b-th class="text-left" colspan="12">{{ $t('subTitle.currentShop') }}</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr class="text-left" colspan="12">
              <b-td>
                <!-- Small Card for Current Selected Shop -->
                <b-card v-if="isShopSelected" class="mb-2" style="padding: 0">
                  <div class="selectedShopRow">
                    <b-button v-if="!isShopChange" v-b-tooltip :title="$t('button.switchShop')" variant="primary" @click="changeShop(true)">
                      <font-awesome-icon :icon="['fas', 'shop']" />
                    </b-button>
                    <div>
                      <h5 class="mb-1">{{ selectedShop.name }}</h5>
                    </div>
                  </div>

                </b-card>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ShopCard from "@/components/main-components/ShopCard.vue";
import Utils from "@/utils/utils";
import {shopService} from "@/services/api/shopService";

export default {
  name: 'shop-chooser',
  computed: {
    Utils() {
      return Utils
    }
  },
  components: { ShopCard },
  data() {
    return {
      showShops : true,
      isShopSelected: false,
      isShopChange: false,
      isLoading: false,
      shops: {
        Type: Array
      },
      selectedShop: {
        Type: Object
      },
    };
  },
  props: {
    callback: {
      Type: Function
    },
  },
  methods: {
    changeShop(value) {
      this.isShopChange = value;
    },
    async getShops() {
      this.isLoading = true;
      const response = await shopService.getShops(true);
      this.isLoading = false;

      if (response.shops.empty) {
        this.makeToast('danger', this.$t('notification.noShopsAvailable'));
        return;
      }

      if (response.emptyBasket) {
        window.shoppingCart = response.basketTile.basketItems;
        window.shoppingTile = response.basketTile;
        // this.updateShoppingCart();
      }

      this.shops = response.shops;

      if (this.shops.length === 1 && this.config.FLAG_AUTOSELECTSHOP) {
        const shop = this.shops[0];
        await this.selectShop(shop.id, shop.custom1);
        this.isShopSelected = true;
      }
    },
    async selectShop(shopId) {
      this.isLoading = true;
      await shopService.selectShop(shopId);
      this.isLoading = false;

      this.selectedShop = this.shops.find(shop => shop.id === parseInt(shopId));
      this.isShopSelected = true;
      this.isShopChange = false;

      this.callback(this.selectedShop);
    },
  },
  created() {
    this.getShops();
  }
};
</script>

<style >

.shopChooser .card-body {
  padding: 0;
}

.shopChooser .btn-primary {
  margin-top: 5px;
}

.shopChooser td {
  padding-left: 0px;
  padding-right: 0px;
}

.selectedShopRow {
  display: flex;
  align-items: center; /* Zentriert den Text und Button vertikal */
  justify-content: space-between;
}

.selectedShopRow button {
  max-width: 150px; /* Maximale Breite des Buttons */
  width: 100%; /* Button kann kleiner werden, wenn der Platz begrenzt ist */
  box-sizing: border-box;  /* Verhindert, dass der Button verkleinert wird */
}

.selectedShopRow div {
  flex-grow: 1; /* Nimmt den restlichen Platz ein */
  margin-left: 10px; /* Abstand zwischen dem Button und dem Text */
  white-space: nowrap; /* Verhindert Zeilenumbrüche */
  overflow: hidden; /* Versteckt Text, wenn er keinen Platz mehr hat */
  text-overflow: ellipsis; /* Zentriert den Text innerhalb des verfügbaren Platzes */
}

</style>
