<template>
  <b-container fluid class="d-flex flex-column" style="row-gap: 7px">
    <!-- Check if product exists before rendering the image -->
    <b-row v-if="product" class="product-image">
      <img :src="product.image1" :alt="product.name" />
    </b-row>
    <b-row v-if="product && product.longDesc">
      <b-card class="custom-card" no-body>
        <b-card-header class="small-header" role="tab">
          <b-row>
            <b-col>
              <b-button block v-b-toggle.accordion-product-details class="btn btn-accordion d-flex justify-content-between align-items-center">
                <span>{{ $t('subTitle.productDetails') }}</span>
                <font-awesome-icon :icon="['fas', 'circle-plus']" class="ml-2" />
              </b-button>
            </b-col>
          </b-row>
        </b-card-header>
        <b-collapse id="accordion-product-details" accordion="accordion-product-details" role="tabpanel">
          <b-card-body class="product-details">
            <!-- Check if longDesc exists -->
            <b-row>
              <b-col>
                <p>{{ product.longDesc }}</p>
              </b-col>
            </b-row>
          </b-card-body>
        </b-collapse>
      </b-card>
    </b-row>
    <b-row v-if="product && product.properties && product.properties.length > 0">
      <b-card class="custom-card" no-body>
        <b-card-header class="small-header" role="tab">
          <b-row>
            <b-col>
              <b-button block v-b-toggle.accordion-properties class="btn btn-accordion d-flex justify-content-between align-items-center">
                <span>{{ $t('subTitle.properties') }}</span>
                <font-awesome-icon :icon="['fas', 'circle-plus']" class="ml-2" />
              </b-button>
            </b-col>
          </b-row>
        </b-card-header>
        <b-collapse id="accordion-properties" accordion="accordion-properties" role="tabpanel">
          <b-card-body>
            <b-tr class="allergens">
              <b-td v-for="property in product.properties" :key="property.id">
                <b-tr>
                  <img :src="property.image" :alt="property.name" class="allergen-img" />
                </b-tr>
                <b-tr>
                  {{ property.name }}
                </b-tr>
              </b-td>
            </b-tr>
          </b-card-body>
        </b-collapse>
      </b-card>
    </b-row>
    <b-row v-if="product && product.allergens && product.allergens.length > 0">
      <b-card class="custom-card" no-body>
        <b-card-header class="small-header" role="tab">
          <b-row>
            <b-col>
              <b-button block v-b-toggle.accordion-allergens class="btn btn-accordion d-flex justify-content-between align-items-center">
                <span>{{ $t('subTitle.allergens') }}</span>
                <font-awesome-icon :icon="['fas', 'circle-plus']" class="ml-2" />
              </b-button>
            </b-col>
          </b-row>
        </b-card-header>
        <b-collapse id="accordion-allergens" accordion="accordion-allergens" role="tabpanel">
          <b-card-body>
            <b-tr class="allergens">
              <b-td v-for="allergen in product.allergens" :key="allergen.id">
                <b-tr>
                  <img :src="allergen.image" :alt="allergen.name" class="allergen-img" />
                </b-tr>
                <b-tr>
                  {{ allergen.name }}
                </b-tr>
              </b-td>
            </b-tr>
          </b-card-body>
        </b-collapse>
      </b-card>
    </b-row>
    <b-row v-if="product && product.nutriValues && product.nutriValues.length > 0">
      <b-card class="custom-card" no-body>
        <b-card-header class="small-header" role="tab">
          <b-row>
            <b-col>
              <b-button block v-b-toggle.accordion-nutriValues class="btn btn-accordion d-flex justify-content-between align-items-center">
                <span>{{ $t('subTitle.nutriValues') }}</span>
                <font-awesome-icon :icon="['fas', 'circle-plus']" class="ml-2" />
              </b-button>
            </b-col>
          </b-row>
        </b-card-header>
        <b-collapse id="accordion-nutriValues" accordion="accordion-nutriValues" role="tabpanel">
          <b-card-body>
            <b-tr class="allergens" v-for="nutriValue in product.nutriValues" :key="nutriValue.id">
              <span class="text-left">
                  {{ nutriValue.name }}
              </span>
              <span class="text-right">
                {{ nutriValue.value }}
              </span>
            </b-tr>
          </b-card-body>
        </b-collapse>
      </b-card>
    </b-row>
    <b-row v-if="subItemsSingle">
      <FlexMultiDropdown
          label="Test"
          :options="subItemsSingle"
          v-model="selectedOption"
          placeholder="Select your favorite fruits"
      />
    </b-row>
    <b-row v-if="subItemsMultiple">
      <FlexMultiDropdown
          label="item.name"
          :options="subItemsMultiple"
          v-model="selectedOption"
          placeholder="Select your favorite fruits"
      />
    </b-row>

    <div v-if="!viewMode">
      <b-row cols="12">
        <b-form-spinbutton v-model="quantity" min="1" max="10" />
      </b-row>
      <b-row>
        <b-button class="btn btn-accordion btn-primary" @click="addToBasket">
          <span>{{ formattedTotalPrice }}</span>
          <font-awesome-icon class="addIconMenu" :icon="['fas', 'cart-plus']" />
        </b-button>
      </b-row>
    </div>
  </b-container>
</template>

<script>

import Utils from "@/utils/utils";
import {productService} from "@/services/api/productService";
import FlexMultiDropdown from "@/components/page-components/templates/MultiSelectDropdown.vue";

export default {
  props: {
    productId: {
      type: Number,
      required: true,
    },
    viewMode: {
      type: Boolean
    }
  },
  data() {
    return {
      product: null,
      selectedSubitems: {}, // Tracks selected subitems for each group
      quantity: 1,
      selectedOption: null,  // Correct binding for dropdown v-model
      subItemsSingle: [],
      subItemsMultiple: []
    };
  },
  async mounted() {
    if (this.productId) {
      this.loading = true;
      const result = await productService.getProductDetails(this.productId, true, true, true, true, true)
      this.loading = false;

      if (result.exception || !result.product) {
        this.makeToast('warning', this.$t('notification.getItemFailed'));
        return;
      }

      this.product = result.product;

      // Initialize subitem selection based on product structure
      if (this.product.hasChildren) {
        this.extractSubItems();
      }
    }
  },
  components: {
    FlexMultiDropdown
  },
  computed: {
    formattedTotalPrice() {
      if (!this.product) {
        return Utils.formatCurrency(0, window.config.ACCOUNT_BALANCE_CURRENCY);
      }

      const basePrice = this.product.price || 0;
      const additionsPrice = this.calculateAdditionsPrice();
      const totalPrice = this.quantity * (basePrice + additionsPrice);
      return Utils.formatCurrency(totalPrice, window.config.ACCOUNT_BALANCE_CURRENCY);
    },
    filteredOptions() {
      return this.product.items.filter(item => !item.addition).map(item => ({
        value: item.lineId,
        text: item.name,
      }));
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    extractSubItems() {
      const items = this.product.items || []; // Access the 'items' array from 'product'

      // Initialize an empty array for subitems
      let subItemsSingle = [];
      let subItemsMultiple = [];

      // Loop through the main items and collect all the nested 'items'
      items.forEach(item => {
        if (item.items && Array.isArray(item.items)) {
          if (!item.additional) {
            subItemsSingle = subItemsSingle.concat(item.items);
          } else {
            subItemsMultiple = subItemsMultiple.concat(item.items);
          }
        }
      });

      this.subItemsSingle = subItemsSingle;
      this.subItemsMultiple = subItemsMultiple;
    },
    calculateAdditionsPrice() {
      let additionsPrice = 0;
      for (const [lineId, subitems] of Object.entries(this.selectedSubitems)) {
        for (const [kkProdId, isSelected] of Object.entries(subitems)) {
          if (isSelected) {
            const subitem = this.product.items
                .find(item => item.lineId === lineId)
                ?.items.find(subitem => subitem.kkProdId === kkProdId);
            if (subitem && subitem.addition) {
              additionsPrice += parseFloat(subitem.price) || 0;
            }
          }
        }
      }
      return additionsPrice;
    },
    isSingleChoiceGroup(group) {
      // Returns true if minQty = 1 and maxQty = 1 (i.e., only one choice allowed)
      return group.minQty === 1 && group.maxQty === 1;
    },
    isOptionalChoiceGroup(group) {
      // Returns true if minQty = 1 and maxQty = 1 (i.e., only one choice allowed)
      return group.minQty === 0;
    },
    isMultipleChoiceGroup(group) {
      // Returns true if minQty = 1 and maxQty = 1 (i.e., only one choice allowed)
      return group.minQty > 0;
    },
    onSubitemChange(group, item = null) {
      // Handle changes in the selection of subitems.
      if (this.isSingleChoiceGroup(group)) {
        // Ensure only one option is chosen for single-choice groups.
        this.selectedSubitems[group.lineId] = item ? item.kkProdId : this.selectedSubitems[group.lineId];
      }
    },
    /*onSubitemChange(group, item) {
      // Ensure that the total selected subitems within a group does not exceed maxQty
      const selectedCount = Object.values(this.selectedSubitems[group.lineId]).filter(Boolean).length;
      if (selectedCount > group.maxQty) {
        this.$set(this.selectedSubitems[group.lineId], item.kkProdId, false); // Revert selection
        this.$bvToast.toast(`You can only select up to ${group.maxQty} items in the ${group.name} group.`, {
          title: 'Selection Limit',
          variant: 'danger',
          solid: true,
        });
      }
    },*/
    isSubitemLocked(item) {
      return item.locked;
    },
    isSubitemLimitReached(groupId) {
      const group = this.product.items.find((g) => g.lineId === groupId);
      const selectedCount = Object.values(this.selectedSubitems[groupId]).filter(Boolean).length;
      return selectedCount >= group.maxQty;
    },
    addToBasket() {
      // Validate minimum quantities for subitems
      if (this.product.hasChildren) {
        for (const item of this.product.items) {
          const selectedCount = Object.values(this.selectedSubitems[item.lineId]).filter(Boolean).length;
          if (selectedCount < item.minQty) {
            this.$bvToast.toast(`Please select at least ${item.minQty} items from the ${item.name} group.`, {
              title: 'Selection Required',
              variant: 'warning',
              solid: true,
            });
            return;
          }
        }
      }

      // Emit an event to the parent component to add the product and subitems to the cart
      this.product.quantity = this.quantity;
      const payload = { product: this.product };
      if (this.selectedSubitems && this.selectedSubitems.length > 0) {
        payload.selectedSubitems = this.selectedSubitems;
      }

      this.$emit('add-to-basket', payload);

      // Close modal after adding to cart
      this.closeModal();
    },
  },
};
</script>

<style scoped>
.modal-body .product-image {
  aspect-ratio: 1 / 1;
}

.product-details {
  display: flex;
}

.product-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  aspect-ratio: 1 / 1;
}

.allergens {
  display: flex;
  gap: 10px;
}

.allergen-img {
  max-width: 30px;
  margin-right: 5px;
}

.small-header {
  font-size: 0.9em; /* Small header font size */
  text-align: left; /* Align header text to the left */
  background: none;
  padding: 0;
}

.btn-accordion {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  margin-top: 5px;
  margin-right: 0;
  margin-left: 0;
  width: 100%;
  background-color: var(--main-color-button);
}

.addIconMenu {
  margin-left: 5px;
}

/* Align radio buttons and checkboxes to the left */
.b-form-radio-group,
.b-form-checkbox-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.b-form-radio,
.b-form-checkbox {
  margin-left: 0; /* Align radio buttons and checkboxes to the left */
}

/* Add padding to b-card-header title */
.b-card-header {
  padding-left: 1rem; /* Adjust as needed to match collapse button padding */
}
</style>