<template>
  <div class="editCustomerPageWrapper" style="padding-left: 15px; padding-right: 15px;">
    <loading :active.sync="isLoading"
             :can-cancel="true"
             :is-full-page="true"
             loader='bars'></loading>

    <div class="">
        <date-search-table :useFutureFilters="true" :callback="retrieveOrders" style="padding-left: 0; padding-right: 0;"  />
        <b-container fluid>
        <b-row class="d-none d-md-flex">
          <b-table-simple small borderless responsive striped hover>
            <b-thead head-variant="dark">
              <b-tr>
                <b-th class="text-left">{{ $t('text.transDate') }}</b-th>
                <b-th class="text-left">{{ $t('table.shop') }}</b-th>
                <b-th class="text-left">{{ $t('table.status') }}</b-th>
                <b-th class="text-right">{{ $t('table.amount') }}</b-th>
                <b-th class="text-left"></b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-if="!Utils.isEmpty(recentOrders)" class="va-middle">
              <b-tr v-for="item in recentOrders" v-bind:key="item.guid" @click="openOrderDetails(item.guid, item.shopId)" v-on:keydown.enter="openOrderDetails(item.guid, item.shopId)">
                <b-td class="text-left">{{ item.formattedDate }}</b-td>
                <b-td class="text-left">{{ item.shopDescription }}</b-td>
                <b-td class="text-left">{{ item.status }}</b-td>
                <b-td class="text-right">{{ item.formattedTotal }}</b-td>
                <b-td class="d-flex align-items-center justify-content-center">
                  <b-button class="btn btn-block btn-sm" v-b-tooltip :title="$t('button.details')" @click="openOrderDetails(item.guid, item.shopId)">
                    <font-awesome-icon class="addIconMenu" :icon="['fas', 'circle-info']" />
                  </b-button>
                </b-td>
              </b-tr>
            </b-tbody>
            <b-tbody v-else>
              <b-tr>
                <b-td class="text-left" colspan="5">{{ $t('table.noData') }}</b-td>
              </b-tr>
            </b-tbody>
            <b-tfoot v-if="!Utils.isEmpty(recentOrders)" foot-variant="dark">
              <b-tr>
                <b-td class="text-left" variant="secondary" colspan="3"><b>{{ $t('table.sum') }}</b></b-td>
                <b-td class="text-right" variant="secondary"><b>{{ ordersFormattedTotal }}</b></b-td>
                <b-td variant="secondary"/>
              </b-tr>
            </b-tfoot>
          </b-table-simple>
        </b-row>
        <b-row class="d-md-none">
          <b-table-simple small borderless responsive striped hover>
            <b-thead head-variant="dark">
              <b-tr>
                <b-th class="text-left">{{ $t('table.date') }}</b-th>
                <b-th class="text-left">{{ $t('table.shop') }}</b-th>
                <b-th class="text-left">{{ $t('table.status') }}</b-th>
                <b-th class="text-right">{{ $t('table.amount') }}</b-th>
                <b-th class="text-left"></b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-if="!Utils.isEmpty(recentOrders)" class="va-middle">
              <b-tr v-for="item in recentOrders" v-bind:key="item.guid" @click="openOrderDetails(item.guid, item.shopId)" v-on:keydown.enter="openOrderDetails(item.guid, item.shopId)">
                <b-td class="text-left">{{ item.formattedDate }}</b-td>
                <b-td class="text-left">{{ item.status }}</b-td>
                <b-td class="text-right">{{ item.formattedTotal }}</b-td>
                <b-td class="d-flex align-items-center justify-content-center">
                  <b-button class="btn btn-block btn-sm" v-b-tooltip :title="$t('button.details')" @click="openOrderDetails(item.guid, item.shopId)">
                    <font-awesome-icon class="addIconMenu" :icon="['fas', 'circle-info']" />
                  </b-button>
                </b-td>
                <b-td/>
              </b-tr>
            </b-tbody>
            <b-tbody v-else>
              <b-tr>
                <b-td class="text-left" colspan="5">{{ $t('table.noData') }}</b-td>
              </b-tr>
            </b-tbody>
            <b-tfoot v-if="!Utils.isEmpty(recentOrders)" foot-variant="dark">
              <b-tr>
                <b-td class="text-left" variant="secondary"><b>{{ $t('table.sum') }}</b></b-td>
                <b-td class="text-right" variant="secondary" colspan="2"><b>{{ ordersFormattedTotal }}</b></b-td>
                <b-td variant="secondary" colspan="3"></b-td>
              </b-tr>
            </b-tfoot>
          </b-table-simple>
        </b-row>
      </b-container>
    </div>

    <modal ref="orderDetailsModal">
      <template v-slot:header>
        <h2 id="modalTitle">{{ $t('title.order') }}</h2>
      </template>

      <template v-slot:body>
        <order-details :order="currentOrderSelected"></order-details>
        <div class="align-items-center justify-content-between">
          <b-button class="btn btn-secondary mr-2" @click="$refs.orderDetailsModal.closeModal()">{{ $t("button.cancel") }}</b-button>
        </div>
      </template>
    </modal>
    <!--<modal ref="orderDetailsModal">
      <template v-slot:header>
        <h2 id="modalTitle">{{ $t('title.order') }}</h2>
      </template>

      <template v-slot:body>
        <order-details :order="currentOrderSelected" :callback-close="closeOrderDetails"></order-details>
        <b-button v-if="config.SHOW_ACCOUNT_STORNO" class="btn btn-secondary mr-2" @click="$refs.orderDetailsModal.closeModal()">{{ $t("button.cancel") }}</b-button>
        <b-button v-if="config.SHOW_ACCOUNT_STORNO" class="btn btn-danger" @click="cancelOrder()">{{ $t("button.cancel") }}
        </b-button>
      </template>
    </modal>-->
  </div>
</template>

<script>

import OrderDetails from '../sub-components/OrderDetails.vue'
import Modal from "../sub-components/ModalAccessible.vue";
import Utils from "@/utils/utils";
import DateSearchTable from "@/components/main-components/DateSearchTable.vue";
import Swal from "sweetalert2";
import VueLoading from "vue-loading-overlay";

export default {
  data() {
    return {
      isLoading: false,
      recentOrders: {
        Type: Array,
        default: []
      },
      currentOrderSelected: {
        Type: Object
      },
      ordersFormattedTotal: 0.00,
    }
  },
  computed: {
    Utils() {
      return Utils
    },
    config() {
      return window.config;
    }
  },
  components: {
    'loading': VueLoading,
    DateSearchTable,
    'modal': Modal,
    'order-details': OrderDetails,
  },
  methods: {
    async retrieveOrders(dateFrom, dateTo) {
      this.isLoading = true;
      const result = await this.doGet('TCPRetrieveOrdersJ.action', {
        dateFrom: dateFrom,
        dateTo: dateTo,
      });
      this.isLoading = false;

      if (result.requiresLogin) {
        this.$navigateToLogin();
      }

      if (result.errorCode < 0) {
        this.makeToast('danger', result.errorMsg);
        return;
      }

      this.recentOrders = result.orders;
      this.ordersFormattedTotal = result.ordersFormattedTotal;
    },
    async openOrderDetails(guid, shopId) {
      this.isLoading = true;
      const result = await this.doGet('TCPOrderDetailsJ.action', {
        guid: guid,
        json: "true",
        shopId: shopId
      });
      this.isLoading = false;

      this.currentOrderSelected = result.order;
      this.currentOrderSelected.guid = result.guid;
      this.$refs.orderDetailsModal.openModal();
    },
    closeOrderDetails() {
      this.$refs.orderDetailsModal.closeModal();
      this.currentOrderSelected = null;
    },
    goBack() {
      this.$router.go(-1);
    }
  },
  async created() {
    await this.retrieveOrders(Utils.getStartOfMonth(), Utils.getEndOfMonth());
  },
  beforeRouteLeave (to,from,next) {

    Swal.close();
    next();
  }
}
</script>

<style scoped>

.fas {
  margin-right: 5px;
}

.va-middle td{
  vertical-align: middle;
}

</style>