<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="true"
             :is-full-page="true"
             loader='bars'></loading>

    <div>
      <TitledCard v-if="isChild" :title="$t('subTitle.customerCardInfos')" class="gap10">
        <div class="d-flex flex-column noPadding text-left">
          <p><strong>{{ $t('text.cardHolder') }}:</strong> {{ fullName(wondCustomer) }}</p>
          <p><strong>{{ $t('text.cardNumber') }}:</strong> {{ wondCustomer.cardNum }}</p>
          <p><strong>{{ $t('text.customerCardBalance') }}</strong> {{ wondCustomer.formattedPrepayBalanceCash }}</p>
        </div>
      </TitledCard>
      <b-container fluid>
        <b-row v-if="!showTopUpPayment" class="gap10">
          <b-col v-if="!Utils.isEmpty(config.TOPUP.predefinedValues)" id="predefinedTopUpValues" cols="12" :md="columnConfig.md">
            <TitledCard :title="$t('subTitle.chargeAmount')">
              <div class="d-flex flex-column noPadding">
                <div v-for="predefinedValue in config.TOPUP.predefinedValues" :key="predefinedValue">
                  <b-button class="btn btn-primary topUpBtn" v-bind:key="predefinedValue" @click="confirmTopUp(predefinedValue)" :aria-label="$t('ariaLabel.chargeAmount') + predefinedValue + ' ' + config.ACCOUNT_BALANCE_CURRENCY">{{ Utils.formatCurrency(predefinedValue, config.ACCOUNT_BALANCE_CURRENCY, true, config.ACCOUNT_BALANCE_SEPARATOR) }}</b-button>
                </div>
              </div>
            </TitledCard>
            <!--<div class="titledCard" role="group">-->
            <!--  <h3>{{ $t('subTitle.chargeAmount') }}</h3>-->
            <!--</div>-->
            <!--
            <b-table-simple class="tableResponsive" simple small borderless caption-top responsive>
              <b-thead head-variant="dark">
                <b-tr>
                  <b-th colspan="12" class="text-left">{{ $t('subTitle.chargeAmount') }}</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="predefinedValue in config.TOPUP.predefinedValues" :key="predefinedValue">
                  <b-button class="btn btn-primary topUpBtn" v-bind:key="predefinedValue" @click="confirmTopUp(predefinedValue)" :aria-label="$t('ariaLabel.chargeAmount') + predefinedValue">{{ Utils.formatCurrency(predefinedValue, config.ACCOUNT_BALANCE_CURRENCY) }}</b-button>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            -->
          </b-col>
          <b-col v-if="!showTopUpPayment && config.TOPUP.customField.enabled" cols="12" md="6">
            <TitledCard :title="$t('subTitle.chargeCustomAmount')">
              <div class="d-flex flex-column noPadding">
                <b-input-group class="input-group topUpInputField" :prepend="Utils.getCurrencySymbol(config.ACCOUNT_BALANCE_CURRENCY)">
                  <currency-input class="form-control" id="chargeAmount" name="chargeAmount" :aria-label="$t('ariaLabel.rechargeHelpText')"
                                  v-model="chargeAmount"
                                  :options="{
                                      currency: config.ACCOUNT_BALANCE_CURRENCY,
                                      currencyDisplay: 'hidden',
                                      valueRange: { min: config.TOPUP.customField.minValue, max: config.TOPUP.customField.maxValue }}"
                                  aria-describedby="chargeAmount-feedback">
                  </currency-input>
                </b-input-group>
                <b-button type="button" @click.stop="confirmTopUp(chargeAmount)" class="btn btn-primary topUpBtn">{{ $t('button.confirm') }}</b-button>
              </div>
            </TitledCard>
            <!--
            <b-table-simple class="tableResponsive" simple small borderless caption-top responsive>
              <b-thead head-variant="dark">
                <b-tr>
                  <b-th colspan="12" class="text-left">{{ $t('subTitle.chargeCustomAmount') }}</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-input-group class="input-group topUpInputField" :prepend="Utils.getCurrencySymbol(config.ACCOUNT_BALANCE_CURRENCY)">
                    <currency-input class="form-control" id="chargeAmount" name="chargeAmount"
                                    v-model="chargeAmount"
                                    :options="{
                                      currency: config.ACCOUNT_BALANCE_CURRENCY,
                                      currencyDisplay: 'hidden',
                                      valueRange: { min: config.TOPUP.customField.minValue, max: config.TOPUP.customField.maxValue }}"
                                    aria-describedby="chargeAmount-feedback">
                    </currency-input>
                  </b-input-group>
                </b-tr>
                <b-tr>
                  <b-button type="button" @click.stop="confirmTopUp(chargeAmount)" class="btn btn-primary topUpBtn">{{ $t('button.confirm') }}</b-button>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            -->
          </b-col>
        </b-row>
        <b-row v-if="showTopUpPayment && paymentList.length > 1">
          <b-col id="paymentMethods" cols="12" md="12">
            <TitledCard :title="$t('subTitle.paymentSelect')">
              <div class="d-flex flex-column noPadding">
                <div v-for="payment in paymentList" :key="payment.subCode == undefined ? payment.code : (payment.code + '~~' + payment.subCode)">
                  <b-button v-if="payment.paymentType == 4 && paymentList.length > 1" style="margin-top: auto" class="btn btn-primary col-12" v-on:click="onSubmit">{{ $t('button.payByCreditCard') }}</b-button>
                </div>
              </div>
            </TitledCard>
            <!--
            <b-table-simple class="tableResponsive" small borderless caption-top responsive>
              <b-thead head-variant="dark">
                <b-tr>
                  <b-th colspan="12" class="text-left">{{ $t('subTitle.paymentSelect') }}</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="payment in paymentList" :key="payment.subCode == undefined ? payment.code : (payment.code + '~~' + payment.subCode)">
                  <b-td colspan="12" class="justify-content-center" style="padding-left: 0; padding-right: 0;">
                    <b-button v-if="payment.paymentType == 4 && paymentList.length > 1" style="margin-top: auto" class="btn btn-primary col-12" v-on:click="onSubmit">{{ $t('button.payByCreditCard') }}</b-button>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            -->
          </b-col>
        </b-row>
      </b-container>

      <credit-card-payment-saferpay v-if='showSaferpay == true' :subCode="currentPaymentSubCode"></credit-card-payment-saferpay>
      <credit-card-payment-securepay v-if='showSecurepay == true' :subCode="currentPaymentSubCode" ></credit-card-payment-securepay>
      <credit-card-payment-novalnet v-if='showNovalnet == true' :subCode="currentPaymentSubCode"></credit-card-payment-novalnet>
      <credit-card-payment-payone v-if='showPayone == true' :subCode="currentPaymentSubCode" :callback="closeTopUp" ></credit-card-payment-payone>
      <credit-card-payment-adyen v-if='showAdyen == true' :isTopup="true" @closeTopUpModal="closeTopUp"></credit-card-payment-adyen>
    </div>
  </div>
</template>

<script>
import Utils from "@/utils/utils";
import CreditCardPaymentComponentSaferpay from "@/components/main-components/CreditCardPaymentComponentSaferpay.vue";
import CreditCardPaymentComponentSecurepay from "@/components/main-components/CreditCardPaymentComponentSecurepay.vue";
import CreditCardPaymentComponentNovalnet from "@/components/main-components/CreditCardPaymentComponentNovalnet.vue";
import CreditCardPaymentComponentPayone from "@/components/main-components/CreditCardPaymentComponentPayone.vue";
import CreditCardPaymentComponentAdyen from "@/components/main-components/CreditCardPaymentComponentAdyen.vue";
import CurrencyInput from "@/components/sub-components/CurrencyInput.vue";
import TitledCard from "@/components/barrier-free-template-components/TitledCard.vue";
import VueLoading from "vue-loading-overlay";
import {accountService} from "@/services/api/accountService";
import {mapActions} from "vuex";

export default {
  name: "top-up",
  components: {
    'loading' : VueLoading,
    'currency-input': CurrencyInput,
    'credit-card-payment-saferpay': CreditCardPaymentComponentSaferpay,
    'credit-card-payment-securepay': CreditCardPaymentComponentSecurepay,
    'credit-card-payment-novalnet': CreditCardPaymentComponentNovalnet,
    'credit-card-payment-payone': CreditCardPaymentComponentPayone,
    'credit-card-payment-adyen': CreditCardPaymentComponentAdyen,
    TitledCard
  },
  data() {
    return {
      isLoading: false,
      wondCustomer: {},
      paymentList: [],
      showTopUpPayment: false,
      currentPayment: "",
      currentPaymentSubCode: "",
      showSaferpay: false,
      showSecurepay: false,
      showNovalnet: false,
      showPayone: false,
      showAdyen: false,
      chargeAmount: null,
    };
  },
  computed: {
    columnConfig() {
      return {
        md: this.config.TOPUP.customField.enabled ? 6 : 12,
      };
    },
    Utils() {
      return Utils
    },
    config(){
      return window.config;
    },
    isChild() {
      return this.$route.query.isChild;
    },
    currentlySelectedPayment (){
      if(this.currentPayment == "Saferpay")
      {
        console.log(this.currentPayment + "~~" + this.currentPaymentSubCode);
        return this.currentPayment + "~~" + this.currentPaymentSubCode;
      }
      else if(this.currentPayment == "PayOne")
      {
        if(this.currentPaymentSubCode !== "")
        {
          return this.currentPayment + "~~" + this.currentPaymentSubCode;
        }
        else
        {
          return this.currentPayment;
        }
      }
      else
      {
        return this.currentPayment;
      }
    },
  },
  props: {
    customerId: {
      type: [String, Number],
    }
  },
  methods: {
    ...mapActions('payments', ['setPaymentType']),

    goBack() {
      this.$router.go(-1);
    },
    fullName(customer) {
      const nameParts = [];

      if (customer.description) {
        nameParts.push(customer.description);
      }

      if (customer.firstName) {
        nameParts.push(customer.firstName);
      }

      return nameParts.join(' ').trim();
    },
    async selectChild(id) {
      this.isLoading = true;
      const result = await accountService.selectChild(id);
      this.isLoading = false;

      if (result.requiresLogin) {
        this.$navigateToLogin();
      }

      if (result.exception !== undefined || result.errorCode < 0) {
        this.makeToast('danger', this.$t('notification.selectChildError'));
        return;
      }

      return result.wondChild;
    },
    async confirmTopUp(amount) {
      if (!Utils.isEmpty(this.chargeAmount)) {
        amount = this.chargeAmount;
      }

      this.loading = true;
      await this.doPost('TCPConfigurePrepaymentJ.action', {
        json: "true",
        amount: amount.toString(),
        xsrfToken: window.xtoken != undefined ? btoa(window.xtoken) : undefined
      });
      this.loading = false;

      await this.confirmAndPayOrder();
    },
    async confirmAndPayOrder() {
      this.loading = true;
      const result = await this.doGet('TCPPaymentJ.action', {
        json: "true",
        orderType: window.orderType,
        comment: this.comment
      });
      this.loading = false;

      if (result.exception !== undefined) {
        return;
      }

      if (result.requiresLogin == true) {
        return;
      }

      if (Utils.isEmpty(result.paymentDetails)) {
        this.makeToast('danger', this.$t('notification.noPaymentMethods'));
        return;
      }
      this.paymentList = result.paymentDetails.filter(function (x) {
        return (x.code != "customercard" && x.code != "cod")
      });

      if (this.paymentList.length == 1) {
        this.onSubmitTopUp(this.paymentList[0]);
      }
      this.showTopUpPayment = true;

      window.onmessage = async function (e) {
        let result = e.data.toString();
        console.log("WINDOW ONMESSAGE EVENT " + result);

        if (result == "S") {
          if (this.currentPaymentSubCode == "P") {
            //TODO
            await this.closeTopUp(0);
            return;
          }

          await this.doPost('ProcessSaferpayJ.action', {
            json: "true",
            vresult: result
          });

          //TODO
          await this.closeTopUp(0);
        } else if (result == "A") {
          this.showSaferpay = false;
          this.showSecurepay = false;
          this.showNovalnet = false;
          this.showPayone = false;
          this.showAdyen = false;

          setTimeout(async () => {
            this.makeToast('success', this.$t('notification.paymentAbort'));
          }, 300);

          if (this.isChild) {
            this.$navigateToStudentAccounts(true);
          } else {
            this.$navigateToAccount(true);
          }
        } else if (result == "F") {
          this.showSaferpay = false;
          this.showSecurepay = false;
          this.showNovalnet = false;
          this.showPayone = false;
          this.showAdyen = false;

          setTimeout(async () => {
            this.makeToast('danger', this.$t('notification.paymentError'));
          }, 300);

          if (this.isChild) {
            this.$navigateToStudentAccounts(true);
          } else {
            this.$navigateToAccount(true);
          }
        } else if (result.startsWith("payoneA")) {

          await this.doGet('ProcessPayOneJ.action', {
            json: "true",
            vresult: result.replace('payone', '')
          });

        } else if (result.startsWith("payoneB")) {
          setTimeout(async () => {
            this.makeToast('danger', this.$t('notification.paymentAbort'));
          }, 300);

          if (this.isChild) {
            this.$navigateToStudentAccounts(true);
          } else {
            this.$navigateToAccount(true);
          }
        } else if (result.startsWith("payoneD")) {
          setTimeout(async () => {
            this.makeToast('danger', this.$t('notification.paymentError'));
          }, 300);

          if (this.isChild) {
            this.$navigateToStudentAccounts(true);
          } else {
            this.$navigateToAccount(true);
          }
        }
      }
    },
    onSubmitTopUp(paymentMethod){
      this.currentPayment = paymentMethod.code;
      if(paymentMethod.subCode != null || paymentMethod.subCode != undefined)
      {
        if( paymentMethod.subCode != ""){
          this.currentPaymentSubCode = paymentMethod.subCode;
        }
      }
      this.onSubmit();
    },
    async onSubmit() {
      this.loading = true;
      const result = await this.doPost('TCPPaymentSubmitJ.action', {
        deliveryAddress: "0",
        paymentMethod: this.currentlySelectedPayment,
        json: "true",
        xsrfToken: window.xtoken != undefined ? btoa(window.xtoken) : undefined
      });
      this.loading = false;

      if (result.paymentMethod == "Saferpay~~P" || result.paymentMethod == "Saferpay~~T") {
        console.log(this.currentPayment);
        this.showSaferpay = true;
        this.$forceUpdate();
        return;
      }

      if (result.paymentMethod == "Securepay") {
        console.log(this.currentPayment);
        this.showSecurepay = true;
        this.$forceUpdate();
        return;
      }

      if (result.paymentMethod == "Novalnet") {
        console.log(this.currentPayment);
        this.showNovalnet = true;
        this.$forceUpdate();
        return;
      }

      if (result.paymentMethod == "PayOne") {
        this.showPayone = true;
        console.log(this.currentPayment);
        this.$forceUpdate();
        return;
      }

      if (result.paymentMethod == "Adyen") {
        console.log(this.currentPayment);
        this.showAdyen = true;
        this.$forceUpdate();
        return;
      }

      if (result.paymentMethod == "StripeModule") {
        return;
      }

      if (result.exception !== undefined) {
        this.makeToast('danger', this.$t("notification.chargeError"));
        this.goBack();
      }

      window.shoppingCart = null;
      this.$navigateToPayment();
    },
    async closeTopUp(status){
      if(status >= 0)
      {
        setTimeout(async () => {
          this.makeToast('success', this.$t('notification.chargeSuccessful'));
          await this.doGet('TCPStartJ.action', {
            json: "true",
            emptyBasket: "true"
          });
          window.shoppingCart = null;
        }, 2000);

        if (this.isChild) {
          this.$navigateToStudentAccounts(true);
        } else {
          this.$navigateToCustomerCard(true);
        }
      }
    }
  },
  async mounted() {
    if (this.isChild) {
      this.wondCustomer = await this.selectChild(this.customerId);
    }

    await this.setPaymentType("TOPUP");
  },
};
</script>
<style scoped>

.topUpBtn {
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 0;
  width: 100%;
}

.topUpInputField{
  margin-left: 0;
  margin-right: 0;
  margin-top: 15px;
  width: 100%;
  height: 44px;
  & .input-group-text, & input {
    font-size: 20px;
    height: 100%;
  }
}
</style>