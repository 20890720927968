export const PaymentMixin = {
    methods: {
        doPayment(payment)
        {
            this.currentPayment = payment.code;
            this.currentPaymentSubCode = payment.subCode != undefined ? payment.subCode : undefined;

            if (payment.code == 'cod' || payment.code == 'customercard' || payment.code == 'free' || payment.code == 'TCPDebitor')
            {
                return;
            }
            //this.paymentSubmit(checkCod);
        },
        async doPaymentSubmit(checkCod) {
            // AP 20220504 The vue-loading-overlay does not always correctly block multiple submits
            // Therefore a second failsafe check is needed to stop multiple submits.
            if (this.isLoading) {
                return;
            }

            this.showSaferpay = false;
            this.showSecurepay = false;
            this.showNovalnet = false;
            this.showPayone = false;
            this.showAdyen = false;

            if (this.currentlySelectedPayment == "customercard" && this.basketTile.basketTotal > this.prepayBalanceCash) {
                this.makeToast('danger', this.$t("balanceNotEnough"));
                return;
            }

            if ((this.currentlySelectedPayment == "cod" || this.currentlySelectedPayment == "customercard" || this.currentlySelectedPayment == "TCPDebitor") && checkCod) {
                return;
            }

            this.isLoading = true;

            const result = await this.doPost('TCPPaymentSubmitJ.action', {
                deliveryAddress: "0",
                paymentMethod: this.currentlySelectedPayment,
                json: "true",
                xsrfToken: window.xtoken != undefined ? btoa(window.xtoken) : undefined
            });

            if (result.paymentMethod == "Saferpay~~P" || result.paymentMethod == "Saferpay~~T") {
                this.isLoading = false;
                this.showSaferpay = true;
                this.$forceUpdate();
                return;
            }

            if (result.paymentMethod == "Securepay") {
                this.isLoading = false;
                this.showSecurepay = true;
                this.$forceUpdate();
                return;
            }

            if (result.paymentMethod == "Novalnet") {
                this.isLoading = false;
                this.showNovalnet = true;
                this.$forceUpdate();
                return;
            }

            if (result.paymentMethod == "PayOne") {
                this.showPayone = true;
                this.isLoading = false;
                this.$forceUpdate();

                setTimeout(() => {
                    var objDiv = document.getElementsByClassName("container")[0];
                    var iFrame = document.getElementById("payoneCheckoutContainer");
                    objDiv.scrollTop = objDiv.scrollHeight;
                    iFrame.scrollIntoView({behavior: "smooth"});
                }, 300);

                return;
            }

            if (result.paymentMethod == "Adyen") {
                this.isLoading = false;
                this.showAdyen = true;
                this.$forceUpdate();
                return;
            }

            if (result.paymentMethod == "StripeModule") {
                return;
            }

            if (result.exception !== undefined) {
                this.isLoading = false;
                this.makeToast('danger', this.$t("exceptionMessage"));
                return;
            }

            if (result.page == "tcp.catalog.checkoutfinished.page") {
                if (this.currentlySelectedPayment == "cod" || this.currentlySelectedPayment == "customercard" || this.currentlySelectedPayment == "free") {
                    window.shoppingCart = null;

                    await this.doGet('TCPCheckoutFinishedJ.action', {
                        json: "true"
                    });
                }

                this.isLoading = false;
                window.shoppingCart = undefined;
                this.$router.push({path: '/CheckoutFinished'});
                return;
            }

            this.isLoading = false;
            window.shoppingCart = null;
            this.$router.push({path: '/Payment'});
        },
    }
}