<template>
    <div class="creditCardPaymentWrapper" id="credit-card-payment">

        <loading :active.sync="isLoading" 
            :can-cancel="true"        
            :is-full-page="true"
            loader='bars'></loading>

        <iframe v-if=" transactionInterface" frameborder="0" width="100%" height="500px" :onload="onLoad" style="margin-top:70px;" id="ExternalPaymentFormFrame" name="ExternalPaymentFormFrame" :src="externalUrl"></iframe> 
        <iframe v-if=" !transactionInterface " frameborder="0" width="100%" height="1000px" :onload="onLoad" style="margin-top:70px;" id="ExternalPaymentFormFrame" name="ExternalPaymentFormFrame" :src="externalUrl"></iframe> 

    </div>
</template>

<script>
import VueLoading from 'vue-loading-overlay'

export default {
    data(){
        return {
            externalUrl : "",
            isLoading: true,
            vaultedCards: null,
            showSaferpayFrame: false,
            noVaultedCards: true,
            creditCardId: "",
            showVaultedCardsNotation: false,
            transactionInterface: false,
        }
    },
    props: {
        subCode: {
            Type: String
        }
    },
    computed:{
        config(){
            return window.config;
        },
        isTableMode(){
            if(window.TableMode == undefined)
            {
                return false;
            }

            return window.TableMode;
        }
    },
    methods: {
        scrollFrameIntoView(override = false){
                  setTimeout(() => {                       
                        var objDiv = document.getElementsByClassName("content")[0];
                        var iFrame = document.getElementById("ExternalPaymentFormFrame");
                        var creditCardsWrapper = document.getElementById("credit-cards-wrapper");                                 
                        
                        if(this.vaultedCards != null && this.vaultedCards.length > 0 && !override)
                        {
                            objDiv.scrollTop = objDiv.scrollHeight;
                            creditCardsWrapper.scrollIntoView({behavior: "smooth"});    
                        }
                        else
                        {
                            if(window.screen.width < 700)
                            {
                                objDiv.scrollTop = objDiv.scrollHeight;
                                if(iFrame != null)
                                    iFrame.scrollIntoView({behavior: "smooth"});   
                                setTimeout(() => {
                                    objDiv.scrollBy({
                                        top: -100,
                                        left: 0,
                                        behavior: 'smooth'
                                    });
                                }, 800); 
                            }
                            else
                            {
                                objDiv.scrollTop = objDiv.scrollHeight;
                                if(iFrame != null)
                                    iFrame.scrollIntoView({behavior: "smooth"});   
                                setTimeout(() => {
                                    objDiv.scrollBy({
                                        top: -100,
                                        left: 0,
                                        behavior: 'smooth'
                                    });
                                }, 800); 
                            }                                          
                        }                                                                                         
                    }, 200);
        },
        onLoad() {
            this.isLoading = false;
        },
        async loadSecurepay() {
          var vueInstance = this;

          const result = await vueInstance.doGet('SecurepayJ.action', {
            json: "true"
          });

          if (result.errorCode < 0) {
            vueInstance.makeToast('danger', result.errorMsg);
            return;
          }

          vueInstance.externalUrl = result.redirectURL;
          vueInstance.scrollFrameIntoView();
          vueInstance.isLoading = false;
        }
    },
    components:{
        'loading' : VueLoading
    },
    mounted() {
        var vueInstance = this;

        vueInstance.loadSecurepay();
    
        document.getElementById('ExternalPaymentFormFrame').onload = function() {
            vueInstance.onLoad();    
        }
    }
}
</script>

<style>
@media screen and (max-width:600px) {
    #credit-cards-wrapper{
        padding-top:20px !important;
    }    
}
</style>