// store/modules/products.js

const state = {
    productCount: 0, // Overall product count across meal plans
    products: [],    // List of products with quantities
}

const getters = {
    getProductCount: (state) => state.productCount,  // Get the total product count
    getProducts: (state) => state.products,          // Get all products with quantities

    // Get product by ID
    getProductById: (state) => (productId) => {
        return state.products.find(product => product.id === productId);
    },
}

const actions = {
    // Increment the product count
    incrementProductCount({ commit }, { productId, quantity = 1 }) {
        commit('INCREMENT_PRODUCT_COUNT', { productId, quantity });
    },

    // Decrement the product count
    decrementProductCount({ commit }, { productId, quantity = 1 }) {
        commit('DECREMENT_PRODUCT_COUNT', { productId, quantity });
    },

    // Set the product count explicitly
    setProductCount({ commit }, { productId, quantity }) {
        commit('SET_PRODUCT_COUNT', { productId, quantity });
    },

    // Add a new product with a specified quantity
    addProduct({ commit }, product) {
        commit('ADD_PRODUCT', product);
    },

    // Remove a product by ID
    removeProduct({ commit }, productId) {
        commit('REMOVE_PRODUCT', productId);
    },
}

const mutations = {
    // Increment product count and update product quantity
    INCREMENT_PRODUCT_COUNT: (state, { productId, quantity }) => {
        const existingProduct = state.products.find(product => product.id === productId);
        if (existingProduct) {
            existingProduct.quantity += quantity;
        } else {
            state.products.push({ id: productId, quantity });
        }
        state.productCount += quantity;
    },

    // Decrement product count and update product quantity
    DECREMENT_PRODUCT_COUNT: (state, { productId, quantity }) => {
        const existingProduct = state.products.find(product => product.id === productId);
        if (existingProduct && existingProduct.quantity > 0) {
            const decrementQuantity = Math.min(existingProduct.quantity, quantity);
            existingProduct.quantity -= decrementQuantity;
            state.productCount -= decrementQuantity;

            if (existingProduct.quantity <= 0) {
                // Remove the product if quantity reaches 0
                state.products = state.products.filter(product => product.id !== productId);
            }
        }
    },

    // Set the product count directly
    SET_PRODUCT_COUNT: (state, { productId, quantity }) => {
        const existingProduct = state.products.find(product => product.id === productId);
        if (existingProduct) {
            state.productCount += quantity - existingProduct.quantity;
            existingProduct.quantity = quantity;
        } else {
            state.products.push({ id: productId, quantity });
            state.productCount += quantity;
        }
    },

    // Add a new product
    ADD_PRODUCT: (state, product) => {
        const existingProduct = state.products.find(p => p.id === product.id);
        if (!existingProduct) {
            state.products.push(product);
            state.productCount += product.quantity;
        }
    },

    // Remove a product by ID
    REMOVE_PRODUCT: (state, productId) => {
        const product = state.products.find(p => p.id === productId);
        if (product) {
            state.productCount -= product.quantity;
            state.products = state.products.filter(p => p.id !== productId);
        }
    },
}

export default {
    namespaced: true, // Use the module with its name
    state,
    getters,
    actions,
    mutations,
}