<template>
  <b-container fluid class="d-flex flex-column">
    <!-- Shop Image -->
    <b-row cols="2" v-if="shop.image" class="shop-image">
      <b-col>
        <img :src="shop.image" :alt="shop.name" />
      </b-col>
      <b-col>
        <div class="shop-address fontTitle">
          {{ $t('subTitle.address') }}
        </div>
        <div style="text-align:start" v-html="shop.addresses[0].formattedAddress"></div>
      </b-col>
    </b-row>

    <!-- Custom Content (e.g., description) -->
    <b-row v-if="shop.custom6">
      <div v-html="shop.custom6"></div>
    </b-row>
  </b-container>
</template>

<script>
export default {
  props: {
    shop: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
/* Ensure the image fits within the row nicely */
.shop-image img {
  max-width: 100%;
  max-height: 100%; /* Adjust height as needed */
  object-fit: cover;
  border-radius: 5px; /* Optional for rounded corners */
}

/* Style for the address block */
.shop-address {
  font-weight:bold;
  font-size: 20px;
}
</style>