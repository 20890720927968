<template>
  <div class="creditCardPaymentWrapper" id="credit-card-payment">

    <loading :active.sync="isLoading"
             :can-cancel="true"
             :is-full-page="true"
             loader='bars'></loading>

    <div class="col-12">
      <div class="col-1" />
      <div id="PaymentPage" class="col-10 checkoutPadding" :ref="`dropin`"></div>
      <div class="col-1" />
    </div>

  </div>
</template>

<script>
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import VueLoading from "vue-loading-overlay";

export default {
  data() {
    return {
      isLoading: true
    }
  },
  props: {
    isTopup: {
      Type: Boolean
    }
  },
  components:{
    'loading' : VueLoading,

  },
  async mounted() {
    var vueInstance = this;

    const response = await vueInstance.doGet('AdyenJ.action', { json: "true" });
    const checkout = await vueInstance.createAdyenCheckout(response, window.config.ADYEN_CLIENT_KEY);
    checkout.create('dropin').mount(vueInstance.$refs['dropin']);

    vueInstance.isLoading = false;
    vueInstance.scrollFrameIntoView();
  },
  methods: {
    async createAdyenCheckout(response, clientKey) {
      const openBankingConfiguration = {
        showImage: false
      };
      const paypalConfiguration  = {
        environment: response.environment,
        blockPayPalPayLaterButton: true
      };

      const configuration = {
        clientKey: clientKey,
        locale: window.locale,
        environment: response.environment,
        showPayButton: true,
        session: {
          id: response.id,
          sessionData: response.sessionData
        },
        paymentMethodsConfiguration: {
          card: {
            hasHolderName: true,
            holderNameRequired: true,
          },
          paypal: paypalConfiguration,
          paybybank: openBankingConfiguration
        },
        onPaymentCompleted: (result, component) => {
          this.handleServerResponse(result, component);
        },
        onError: (error, component) => {
          console.error(error.name, error.message, error.stack, component);
          this.makeToast('danger', this.$t('notification.paymentError'));
        }
      };

      return new AdyenCheckout(configuration);
    },
    async handleServerResponse(res, component) {
      if (res.action) {
        console.log(res.action);
        component.handleAction(res.action);
      } else {
        switch (res.resultCode) {
          case "Refused":
            this.isTopup ? this.$emit("closeTopUpModal", -1) : window.location.href = "#/PaymentEnd?p=adyen&r=F";
            break;
          case "Authorised":
            this.isTopup ? this.$emit("closeTopUpModal", 0) : window.location.href = "#/PaymentEnd?p=adyen&r=S";
            break;
          default:
            break;
        }
      }
    },
    scrollFrameIntoView(){
      setTimeout(() => {
        var objDiv = document.getElementsByClassName("content")[0];
        var dropinContainer = document.getElementById("PaymentPage");

        if(window.screen.width < 700)
        {
          objDiv.scrollTop = objDiv.scrollHeight;
          if(dropinContainer != null)
            dropinContainer.scrollIntoView({behavior: "smooth"});
          setTimeout(() => {
            objDiv.scrollBy({
              top: -100,
              left: 0,
              behavior: 'smooth'
            });
          }, 800);
        }
        else
        {
          objDiv.scrollTop = objDiv.scrollHeight;
          if(dropinContainer != null)
            dropinContainer.scrollIntoView({behavior: "smooth"});
          setTimeout(() => {
            objDiv.scrollBy({
              top: -100,
              left: 0,
              behavior: 'smooth'
            });
          }, 800);
        }
      }, 200);
    }
  },
};
</script>
