// store/index.js
import Vue from 'vue'
import Vuex from 'vuex'
import orders from "@/store/modules/orders";
import products from "@/store/modules/products";
import createPersistedState from 'vuex-persistedstate';
import shops from "@/store/modules/shops";
import mealPlans from "@/store/modules/mealPlans";
import customers from "@/store/modules/customers";
import locale from "@/store/modules/locale";
import payments from "@/store/modules/payments";

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        products,
        orders,
        shops,
        mealPlans,
        customers,
        locale,
        payments,
    },
    plugins: [
        createPersistedState({
            storage: window.localStorage, // Use localStorage (or window.sessionStorage for session-only persistence)
            paths: [
                'orders.orderType',
                'products.productCount',
                'shops.selectedShop',
                'mealPlans.selectedMealPlanId',
                'customers.selectedCustomer',
                'locale.locale',
                'payments.paymentType'
            ],
        }),
    ],
})