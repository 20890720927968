<template>
  <div class="cart-button-container">
    <!-- Cart Button -->
    <b-button variant="primary" class="cart-button" @click="goToBasket">
      <span class="cart-icon">
        <font-awesome-icon :icon="['fas', 'cart-shopping']" />
        <!-- Product Counter displayed on the cart icon -->
        <span v-if="productCount > 0" class="product-counter">{{ productCount }}</span>
      </span>
      <span class="cart-text">
        {{ $t('button.goToBasket') }}
        <!-- Total price displayed in parentheses -->
        <span class="cart-price">({{ Utils.formatCurrency(totalPrice, config.ACCOUNT_BALANCE_CURRENCY) }})</span>
      </span>
    </b-button>
  </div>
</template>

<script>
import Utils from "@/utils/utils";

export default {
  name: 'CartButton',
  computed: {
    config() {
      return window.config;
    },
    Utils() {
      return Utils;
    }
  },
  props: {
    mealPlan: {
      type: Object,
      required: false
    },
    productCount: {
      type: Number,
      default: 0,
    },
    totalPrice: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    goToBasket() {
      this.$navigateToOrderConfirmation();
    },
  },
};
</script>

<style scoped>
/* Container for the cart button */
.cart-button-container {
  width: 100%;
  position: relative;
}

/* Cart button styling */
.cart-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #007bff; /* Blue background */
  color: white;
  padding: 12px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 18px;
  width: 100%; /* Full width */
  position: relative;
}

/* Icon with the cart and counter */
.cart-icon {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.product-counter {
  background-color: #ff0000; /* Red background for the counter */
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  margin-left: 5px;
  font-size: 14px;
}

/* Text and price section */
.cart-text {
  display: flex;
  align-items: center;
}

.cart-price {
  margin-left: 5px;
  font-weight: bold;
}

/* Sticky position for mobile devices */
@media (max-width: 768px) {
  .cart-button-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: #f8f9fa;
    padding: 10px;
  }

  .cart-button {
    font-size: 18px;
    padding: 15px;
  }

  .cart-icon {
    margin-right: 8px;
  }

  .cart-price {
    font-size: 18px;
  }
}

/* Desktop view: Centered with fixed width */
@media (min-width: 769px) {
  .cart-button-container {
    max-width: 100%;
    position: relative;
  }

  .cart-button {
    max-width: 400px;
    margin: 0 auto;
    padding: 12px 20px;
  }
}
</style>