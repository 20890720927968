const ToastPlugin = {
    install(Vue) {
        // Define a global method to show toast notifications
        Vue.prototype.$makeToast = function(variant = 'info', message, autoHideDelay = 5000) {
            this.$root.$refs.toast.showToast(message, variant, autoHideDelay);
        };

        // Add the toast component globally
        Vue.component('global-toast', {
            template: `
        <b-toast ref="toast" :auto-hide-delay="autoHideDelay" :variant="variant" :title="title" :no-fade="noFade">
          {{ message }}
        </b-toast>
      `,
            props: {
                message: String,
                variant: {
                    type: String,
                    default: 'info'
                },
                autoHideDelay: {
                    type: Number,
                    default: 5000
                },
                title: String,
                noFade: Boolean
            },
            methods: {
                showToast(message, variant, autoHideDelay) {
                    this.message = message;
                    this.variant = variant;
                    this.autoHideDelay = autoHideDelay;
                    this.$refs.toast.show();
                }
            }
        });
    }
};

export default ToastPlugin;
