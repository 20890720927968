// src/store/modules/orderType.js
const state = {
    orderType: null, // Initial value for orderType
};

const actions = {
    updateOrderType({ commit }, orderType) {
        commit('SET_ORDER_TYPE', orderType); // Action to update orderType
    },
};

const getters = {
    getOrderType: (state) => state.orderType, // Getter for orderType
};

const mutations = {
    SET_ORDER_TYPE(state, orderType) {
        state.orderType = orderType; // Mutation to set orderType
    },
};

export default {
    namespaced: true, // Enable namespacing for this module
    state,
    mutations,
    actions,
    getters,
};