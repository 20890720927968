<template>
    <div id="mealPlans">
        <VueLoading v-if="isLoading" />
        <div class="d-flex flex-row rowGap20 accountPageFlexer flex-wrap">
            <FlexContainer :title="$t('subTitle.myAccount')" #content>
                <div class="d-flex flex-column gap10 align-items-start">
                    <div class="text-left my-1">
                        <span>{{ customer.firstName }} {{ customer.lastName }}</span><br/>
                        <span>{{ customer.emailAddr }}</span><br/>
                        <span>{{ customer.telephonNumber }}</span>
                    </div>

                    <!-- Edit Account Data -->
                    <b-button v-if="config.CUSTOMER_DATA.editable" class="w-100 noMargin text-left" variant="primary" @click="navigateToEditCustomer">
                        <!--<font-awesome-icon :icon="['fas', 'pen-to-square']" />-->
                        {{ $t('button.editCustomerData')}}
                    </b-button>

                    <b-button v-if="config.CHANGE_PASSWORD.enabled" class="w-100 noMargin text-left" variant="primary" @click="navigateToPasswordReset">
                        <!--<font-awesome-icon :icon="['fas', 'pen-to-square']" />-->
                        {{ $t('button.changePassword')}}
                    </b-button>
                </div>
            </FlexContainer>
            <FlexContainer :title="$t('subtitle.deliveryAddress')" #content :visible="!config.IS_PURE_ACCOUNTMANAGER && config.SHOW_ACCOUNT_PAGE_ADRESSES">
                <div class="d-flex flex-column gap10 align-items-start">

                    <select class="vueselect-wrapper noPadding" id="addressPicker" ref="addressPickerValue" aria-controls="btnEditAddress">
                        <option v-for="address in addresses" :key="address.id" :value="address.id" v-html="address.firstName + ' ' + address.lastName + ', \u000a' + address.streetAddress + ', \u000a' + address.postcode + ' ' +  address.city + ', ' + address.countryName + ' - \u000a' + (address.telephoneNumber ? address.telephoneNumber: '') "></option>
                    </select>

                    <button class="btn btn-primary text-left mx-0 w-100 noMargin" @click="editAddress" id="btnEditAddress" aria-describedby="addressPicker">
                        {{$t('subTitle.editAddress')}}
                    </button>

                    <button class="btn btn-primary text-left mx-0 w-100 noMargin" @click="addNewAddress">
                        <!--<font-awesome-icon :icon="['fas', 'pen-to-square']" />-->
                        {{$t('subTitle.addAddress')}}
                    </button>
                </div>
            </FlexContainer>

            <FlexContainer :title="$t('subtitle.configuration')" #content :visible="config.USE_ALLERGEN_FILTERS">
                <div class="d-flex flex-column gap10 align-items-start">
                    <!-- todo after storing the Alergens Data; show the Icons here -->
                    <!--<div class="d-flex flex-row flex-wrap gap10">-->
                    <!--  <img v-for="alergene of diataryInfo" :key="alergene" src="{}" alt="">-->
                    <!--</div>-->
                    {{$t('text.allergensAndPropertiesSelected',[diataryInfoCount])}}

                    <b-button v-if="config.USE_ALLERGEN_FILTERS" class="w-100 noMargin text-left" variant="primary" @click="viewAllergenDietaryFilter" aria-label="Edit customer allergen and dietary infos">
                        <!--<font-awesome-icon :icon="['fas', 'pen-to-square']" />-->
                        {{ $t('button.changeAllergens') }}
                    </b-button>
                </div>
            </FlexContainer>
        </div>

        <!--
            <div class="d-flex gap10 flex-column">
              <div id="customer-info-container" class="meal-container">
                <div class="meal-group">
                  <h1 class="meal-group-title">{{ $t('subTitle.customerInfos') }}</h1>
                </div>
                <div class="grid-layout">
                  <div>
                    <div class="meal-item">
                      <div class="d-flex flex-column gap10 flex-grow-1 justify-content-between">
                        <div class="meal-details" @click="navigateToEditCustomer">
                          <div class="meal-description">
                            <span>{{ customer.firstName }} {{ customer.lastName }}</span>
                          </div>
                          <div class="meal-description">
                            <span>{{ customer.telephonNumber }}</span>
                          </div>
                        </div>
                        <div class="d-flex flex-row gap10 w-100 flex-wrap">
                          <div v-if="config.CUSTOMER_DATA.editable"
                              class="button"
                              @click="navigateToEditCustomer"
                              role="button"
                              tabindex="0"
                              aria-label="Edit customer infos"
                          >
                            <font-awesome-icon :icon="['fas', 'pen-to-square']" />
                          </div>
                          <div v-if="config.USE_ALLERGEN_FILTERS"
                               class="button"
                              @click="viewAllergenDietaryFilter"
                              role="button"
                              tabindex="0"
                              aria-label="Edit customer allergen and dietary infos"
                          >
                            <font-awesome-icon :icon="['fas', 'hand-dots']" />
                          </div>
                          <div style="background-color: red"
                               class="button"
                               @click="logout"
                               role="button"
                               tabindex="0"
                               aria-label="Logout customer"
                          >
                            <font-awesome-icon :icon="['fas', 'power-off']" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="config.CHANGE_PASSWORD.enabled" id="login-info-container" class="meal-container">
                <div class="meal-group">
                  <h1 class="meal-group-title">{{ $t('subTitle.loginInfos') }}</h1>
                </div>
                <div class="grid-layout">
                  <div class="meal-item">
                    <div class="d-flex flex-column gap10 flex-grow-1 justify-content-between">
                      <div class="meal-details" @click="navigateToPasswordReset">
                        <div class="meal-description">
                          <p>{{ customer.emailAddr }}</p>
                          <p>*********************</p>
                        </div>
                      </div>
                      <div class="d-flex flex-row gap10 w-100 flex-wrap">
                        <div
                            class="button"
                            @click="navigateToEditCustomer"
                            role="button"
                            tabindex="0"
                            aria-label="Edit customer infos"
                        >
                          <font-awesome-icon :icon="['fas', 'pen-to-square']" @click="navigateToPasswordReset" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="customer-addresses-container" class="meal-container">
                <div class="meal-group">
                  <h1 class="meal-group-title">{{ $t('subTitle.customerAddresses') }}</h1>
                </div>
                <div class="grid-layout">
                  <div v-for="address in customer.addresses" :key="address.id">
                    <div :id="address.id" class="meal-item">
                      <div class="d-flex flex-column gap10 flex-grow-1 justify-content-between">
                        <div class="meal-details" @click="editAddress">
                          <div class="meal-description">
                            <span>{{ address.firstName }} {{ address.lastName }}</span>
                            <span>{{ address.streetAddress }}</span>
                            <span>{{ address.postalcode }} {{ address.city }}</span>
                            <span v-if="config.SHOW_CUSTOMER_COUNTRY_NAME">{{ address.countryName }}</span>
                            <span v-if="address.isPrimary"><strong>(Primary Address)</strong></span>
                          </div>
                        </div>
                        <div class="d-flex flex-row gap10 w-100 flex-wrap">
                          <div
                              class="button"
                              @click="editAddress(address)"
                              role="button"
                              tabindex="0"
                              aria-label="Edit address"
                          >
                            <font-awesome-icon :icon="['fas', 'pen-to-square']" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        -->
        <div class="">

            <!--
            <div class="col-12 customerJustify" style="padding-left: 0;">
              <div class="col-12 customerData">

                <div style="padding-bottom:50px;" v-if="config.SHOW_ACCOUNT_PAGE_ADVANCED_CUSTOMER_AREA">
                  <div v-for="item in pointCampaignsDefinitions" v-bind:key="item.campaignId"  style="text-align:left;  background:white; padding: 5px; border-bottom:0.2px solid var(&#45;&#45;main-color); margin:5px;">
                    <span style="font-size:20px;">{{ getCampaignName(item.id) }}</span>
                    <br/>
                    <span v-if="item.points > 0">
                                <span style="font-size:15px;"> {{ $t('text.rewardPointsBalance') }} </span>
                                <span style="color:var(&#45;&#45;main-color); font-size:20px;"> {{ item.points }} </span>
                            </span>
                  </div>
                </div>
                <h5 v-if="!config.IS_PURE_ACCOUNTMANAGER && config.SHOW_ACCOUNT_PAGE_ADRESSES">{{$t('subTitle.customerAddresses')}}</h5>
                <div v-if="!config.IS_PURE_ACCOUNTMANAGER && config.SHOW_ACCOUNT_PAGE_ADRESSES" style="margin-bottom:120px;">
                  <select class="vueselect-wrapper col-xl-8 col-sm-7" id="addressPicker" style="padding:0;"  @change="closeAddressForm">
                    <option v-for="address in addresses" :key="address.id" :value="address.id" v-html="address.firstName + ' ' + address.lastName + ', ' + address.streetAddress + ', ' + address.postcode + ' ' +  address.city + ', ' + address.countryName + ' - ' + (address.telephoneNumber ? address.telephoneNumber: '') "></option>
                  </select>

                  <b-button type="button" @click="editAddress" class="btn btn-primary btnAddress col-2" style="margin: 0px 0px 5px 0px;height: 48px; padding:0;">
                    <i class="editIcon fa fa-pencil">   </i>
                  </b-button>

                  <b-button type="button" @click="addNewAddress" class="btn btn-primary btnAddress col-2" style="margin: 0px 0px 5px 0px;height: 48px; padding:0;">
                    <i class="editIcon fa fa-plus">   </i>
                  </b-button>
                </div>

                <div id="addressFormWrapper">
                  <div class="col-12 addressFormPadding"  v-if="showEditAddressForm">
                    <h2>{{$t('subTitle.editAddress')}}</h2>
                    <b-button type="button" v-if="selectedAddress.isPrimary == false" @click="deleteAddress" class="btn btn-inverted col-1" style="margin: 0px 0px 5px 0px;height: 48px;min-width:10%;">
                      <i class="editIcon fa fa-trash">   </i>
                    </b-button>
                    <address-form :addressId="selectedAddress.id" :addressModel="selectedAddress" :isEdit="true" :callbackClose="closeAddressForm" :customer="customer"></address-form>
                  </div>
                  <div class="col-12 addressFormPadding"  v-if="showNewAddressForm">
                    <h2>{{$t('subTitle.addAddress')}}</h2>
                    <address-form :addressId="0" :addressModel="addAddress" :isEdit="false" :callbackClose="closeAddressForm" :customer="customer"></address-form>
                  </div>
                </div>
              </div>
            </div>
            -->
        </div>

        <modal ref="autoTopupModal">
            <template v-slot:header>
                <h2 id="modalTitle">{{$t('title.autoCharge')}}</h2>
            </template>

            <template v-slot:body>

                <b-form @submit="autoTopupFormSubmit">
                    <!-- <b-form-group id="input-group-1"> -->
                    <b-form-checkbox
                        id="checkbox-1"
                        v-model="autoTopupForm.active"
                        name="checkbox-1"
                        value="1"
                        unchecked-value="0"
                        switch
                    >
                        {{$t('text.enabled')}}
                    </b-form-checkbox>
                    <!-- </b-form-group> -->

                    <b-form-group
                        id="input-group-2"
                        :label="$t('text.threshold')"
                        label-for="input-2"
                        :description="$t('text.thresholdDescription')"
                        :append="' ' + config.ACCOUNT_BALANCE_CURRENCY"
                    >
                        <b-form-input
                            id="input-2"
                            v-model="autoTopupForm.thresholdValue"
                            type="number"
                            placeholder="0"
                            step="0.01"
                            required
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-3" :label="$t('text.rechargeAmount')" label-for="input-3" :description="$t('text.rechargeAmountDescription')">
                        <b-form-input
                            id="input-3"
                            v-model="autoTopupForm.rechargeValue"
                            type="number"
                            placeholder="0"
                            step="0.01"
                            required
                        ></b-form-input>
                    </b-form-group>

                    <b-button type="submit" variant="primary">{{$t("button.confirm")}}</b-button>
                    <b-button type="reset" variant="primary" @click="$refs.autoTopupModal.closeModal()">{{$t("button.cancel")}}</b-button>
                </b-form>
            </template>

            <template v-slot:footer>
                <!-- <div class="d-flex align-items-center justify-content-between">
                <b-button class="btn btn--secondary" @click="$refs.autoTopupModal.closeModal()">Cancel</b-button>
                <b-button class="btn btn--primary" @click="$refs.autoTopupModal.closeModal()">Save</b-button>
                </div> -->
            </template>
        </modal>

        <!-- Modal for allergen and dietary filters -->
        <b-modal id="allergen-dietary-filter-modal" hide-footer :title="$t('title.dietaryInfo')">
            <AllergenDietaryFilter @edit-customer-dietary-attributes="handleEditCustomerDietaryAttributes" />
        </b-modal>
    </div>
</template>

<script>
import Modal from '../sub-components/ModalAccessible.vue'
import $ from 'jquery'
// import AddressForm from '../main-components/AddressForm.vue'
import Utils from "@/utils/utils";
import {accountService} from "@/services/api/accountService";
import VueLoading from "vue-loading-overlay";
import AllergenDietaryFilter from "@/components/sub-components/AllergenDietaryFilter.vue";
import {customerService} from "@/services/api/customerService";
import FlexContainer from "@/components/page-components/templates/FlexContainer.vue";

export default {
    data(){
        return {
            isLoading: false,
            customer: {},
            autoTopupForm: {
                active: false,
                thresholdValue: 0,
                rechargeValue: 0,
            },
            selectedAllergens: [],
            previousSelectedAllergens: [],
            alergenOptions: [],
            selectedProperties: [],
            previousSelectedProperties: [],
            propertiesOptions: [],
            addresses:[]
        }
    },
    computed:{
        Utils() {
            return Utils
        },
        config(){
            return window.config;
        },
        diataryInfoCount(){
            // todo - move to session stored value
            return window.dietaryInfo && window.dietaryInfo.length || 0;
        },
    },
    components:{
        AllergenDietaryFilter,
        // 'address-form' : AddressForm,
        'modal': Modal,
        VueLoading,
        FlexContainer
    },
    methods:{
        async handleEditCustomerDietaryAttributes(attributes) {
            this.loading = true;
            const result = await customerService.editCustomerDietaryAttributes(attributes);
            this.loading = false;

            window.dietaryInfo = result.wondCustomer.dietaryInfo;

            this.$bvModal.hide("allergen-dietary-filter-modal");
            this.makeToast('success', this.$t('notification.dietaryInfosConfigUpdate'));
        },
        navigateToEditCustomer() {
            this.$navigateToEditCustomer();
        },
        navigateToPasswordReset() {
            this.$navigateToPasswordReset();
        },
        async openAutoTopupModal() {
            this.isLoading = true;

            const result = await this.doGet('TCPEditCustomerJ.action', {
                json: "true",
            });

            let recurringPaymentData = result.recurringPayment;
            if (recurringPaymentData.active != undefined) {
                this.autoTopupForm.active = recurringPaymentData.active;
            }
            if (recurringPaymentData.thresholdValue != undefined) {
                this.autoTopupForm.thresholdValue = recurringPaymentData.thresholdValue;

            }
            if (recurringPaymentData.rechargeValue != undefined) {
                this.autoTopupForm.rechargeValue = recurringPaymentData.rechargeValue;
            }
            this.isLoading = false;

            this.$refs.autoTopupModal.openModal()
        },
        viewAllergenDietaryFilter() {
            this.$bvModal.show("allergen-dietary-filter-modal");
        },
        async autoTopupFormSubmit(event) {
            this.isLoading = true;

            event.preventDefault();

            await this.doPost('TCPEditCustomerSubmitJ.action', {
                json: "true",
                recurringPayment: {
                    active: this.autoTopupForm.active,
                    thresholdValue: this.autoTopupForm.thresholdValue,
                    rechargeValue: this.autoTopupForm.rechargeValue,
                }
            });

            this.isLoading = false;

            this.makeToast('success', this.$t('notification.autoChargeConfigUpdate'));

            this.$refs.autoTopupModal.closeModal();
        },
        // checkEmpty(stringToCheck) {
        //   if(stringToCheck.indexOf("---") > -1)
        //   {
        //     return "";
        //   }
        //   else
        //   {
        //     return stringToCheck;
        //   }
        // },
        // getCampaignName(campaignId){
        //   for(var i  = 0; i < this.pointCampaignsDefinitions.length; i++)
        //   {
        //     if(this.pointCampaignsDefinitions[i].id == campaignId)
        //     {
        //       return this.pointCampaignsDefinitions[i].description;
        //     }
        //   }
        //
        //   return "";
        // },
        async updateCustomerObject() {
            const result = await this.doGet('TCPAfterLoginJ.action', {
                json: "true",
            });

            if (result.exception !== undefined) {
                console.log(result.exception);
                return;
            }

            this.customer = result.customer;
            window.customer = this.customer;
            window.isGuestCheckout = result.customer != undefined && result.customer.type != undefined && result.customer.type === 2;
        },
        // closeAddressForm(){
        //   setTimeout(() => {
        //     this.showEditAddressForm = false;
        //     this.showNewAddressForm = false;
        //   }, 100);
        //
        //   this.refreshAddresses();
        // },
        // async deleteAddress() {
        //   await this.doGet('TCPDeleteAddrJ.action', {
        //     json: "true",
        //     addrId: this.selectedAddress.id
        //   });
        //
        //   this.confirmDeleteAddress();
        // },
        // async confirmDeleteAddress() {
        //   await this.doPost('TCPDeleteAddrSubmitJ.action', {
        //     json: "true",
        //     xsrfToken: window.xtoken != undefined ? btoa(window.xtoken) : undefined
        //   });
        //
        //   this.makeToast('success', this.$t('notification.deleteSuccessful'));
        //   this.closeAddressForm();
        // },
        editAddress() {
            // this.showEditAddressForm = true;
            // this.showNewAddressForm = false;

            var selectedAddressId = $('#addressPicker').val();
            var selectedAddr = this.addresses.filter(function(x) { return x.id == selectedAddressId })[0];
            this.selectedAddress = selectedAddr;

            this.$router.push({
                name: 'EditAddress',
                params: {
                    address: selectedAddr,
                    customer: this.customer,
                    isAddressPrimary: selectedAddr.isPrimary,
                    isAddressNew: false,
                },
            });
        } ,
        addNewAddress(){
            // this.showEditAddressForm = false;
            // this.showNewAddressForm = true;
            this.$router.push({
                name: 'EditAddress',
                params: {
                    address: { // create dummy address object
                        "id": 0, // required for new addresses
                        "city": "",
                        "countryId": 0,
                        "countryName": "",
                        "customerId": this.customer.id,
                        "lastName": this.customer.lastName,
                        "firstName": this.customer.firstName,
                        "gender": this.customer.gender,
                        "isPrimary": false,
                        "postcode": "",
                        "streetAddress": "",
                        "telephoneNumber": "",
                        "useZoneId": false,
                        "vendorId": 0,
                        "zoneId": 0
                    },
                    customer: this.customer,
                    isAddressPrimary: false,
                    isAddressNew: true,
                },
            });
        },
        async refreshAddresses() {
            let currentlySelectedAddressId = $('#addressPicker').val();

            const result = await this.doGet('TCPAddrBookJ.action', {
                json: "true",
            });

            if (result.requiresLogin) {
                this.$navigateToLogin();
                return;
            }

            if (result.exception !== undefined) {
                this.$router.go(-1);
                return;
            }

            // this.customer = result.customer;
            this.addresses = result.addresses;
            if (currentlySelectedAddressId === null || currentlySelectedAddressId === undefined) {
                this.selectedAddress = result.addresses.sort((a,b)=>b.isPrimary-a.isPrimary)[0]; // if no address has been selected, try to select the Primary Address
            } else {
                this.selectedAddress = result.addresses.filter(addr => addr.id.toString() === currentlySelectedAddressId.toString())[0];
            }
            this.selectedAddressId = this.selectedAddress.id;
            await this.updateCustomerObject();
            this.$forceUpdate();
        },
        goBack(){
            this.$router.back();
        },
        async logout() {
            const result = await accountService.logout();
            if (result.errorCode < 0) {
                this.makeToast('danger', result.errorMsg);
                return;
            }

            window.customerLoggedIn = null;
            this.$navigateToLogin();
        },
    },
    async created() {
        const config = this.config;

        this.isLoading = true;
        const result = await this.doGet('TCPAfterLoginJ.action', {
            json: "true",
        });
        this.isLoading = false;

        if (result.requiresLogin) {
            this.$navigateToLogin();
            return;
        }

        if (result.exception !== undefined) {
            this.$router.go(-1);
            return;
        }
        this.refreshAddresses();

        if (config.USE_ALLERGEN_FILTERS && result.wondCustomer.allergenIds != undefined) {
            this.selectedAllergens = result.wondCustomer.allergenIds;
        }

        if (config.USE_ALLERGEN_FILTERS && result.wondCustomer.propertyIds != undefined) {
            this.selectedProperties = result.wondCustomer.propertyIds;
        }

        this.customer = result.customer;
    },
}
</script>

<style scoped>
.accountPageFlexer > * {
    flex: 1 1 350px;
    max-width: 100%;
}
.meal-container {
    background-color: var(--page-container-background);
    border-radius: var(--button-border-radius);
    margin-top: 10px
}

.meal-group {
    background-color: var(--page-group-header);
    text-align: left;
    margin: 0 auto;
    padding: 0 10px;
    border-radius: var(--button-border-radius);
}

.meal-group-title {
    font-size: 16px;
    color: var(--page-group-title);
}

.meal-item {
    display: flex;
    flex: 1 0 360px;
    flex-wrap: wrap;
    gap: 10px;
    text-align: left;
    align-items: stretch;
    padding: 10px;
    border-radius: var(--button-border-radius);
    background-color: var(--page-group-item);
    transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition effect */
    height: 100%;
}

.meal-item:hover {
    background-color: var(--page-group-item-hover);
    transform: scale(1.02); /* Slightly scale up the item */
    cursor: pointer; /* Add pointer cursor */
}

.meal-image {
    width: 120px;
    height: auto;
    margin-right: unset;
    border-radius: var(--button-border-radius);
    object-fit: cover;
}

.meal-details {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.meal-description {
    font-weight: bold;
    text-align: left;
}

.button {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    user-select: none; /* Prevent text selection */
    transition: background-color 0.3s; /* Smooth background change */
}

.button:hover,
.button:focus {
    background-color: #0056b3; /* Darker blue on hover/focus */
}

.button:active {
    background-color: #004494; /* Even darker on click */
}
</style>