<template>
  <div
      :class="['alert', alertTypeClass]"
      role="alert"
      :aria-labelledby="labelledbyId"
      :aria-label="ariaLabel"
      aria-live="assertive"
      aria-atomic="true"
      :title="titleText"
  >
    <div class="alert-icon" aria-hidden="true">
      <font-awesome-icon :icon="alertIcon" />
    </div>
    <div class="alert-text" :id="alertId">{{ message }}</div>
    <button
        class="close-btn"
        @click="closeAlert"
        aria-label="Close alert"
        :aria-describedby="alertId"
        title="Close this alert"
    >
      &times;
    </button>
  </div>
</template>

<script>
export default {
  name: "FlexAlert",
  props: {
    type: {
      type: String,
      default: "info",
      validator: function (value) {
        return ["success", "error", "info", "warning"].includes(value);
      },
    },
    message: {
      type: String,
      required: true,
    },
    alertLabel: {
      type: String,
      default: "", // External label for aria-labelledby, if provided
    },
    ariaLabel: {
      type: String,
      default: "", // Custom aria-label for the alert
    },
    titleText: {
      type: String,
      default: "", // Tooltip for the alert
    },
  },
  computed: {
    alertTypeClass() {
      return `alert-${this.type}`;
    },
    alertIcon() {
      switch (this.type) {
        case "success":
          return "fas fa-check-circle";
        case "error":
          return "fas fa-times-circle";
        case "warning":
          return "fas fa-exclamation-triangle";
        default:
          return "fas fa-info-circle";
      }
    },
    alertId() {
      return `alert-${Math.random().toString(36).substring(2, 9)}`;
    },
    labelledbyId() {
      return this.alertLabel ? this.alertLabel : this.alertId; // If alertLabel is provided, use it for aria-labelledby
    },
  },
  methods: {
    closeAlert() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.alert {
  display: flex;
  align-items: center;
  padding: 15px;
  margin: 10px 0;
  border-radius: 5px;
  font-family: Arial, sans-serif;
  position: relative;
  line-height: 1.5;
}

/* Icon Styles */
.alert-icon {
  font-size: 24px;
  margin-right: 10px;
}

/* Success Alert */
.alert-success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

/* Error Alert */
.alert-error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

/* Info Alert */
.alert-info {
  background-color: #d1ecf1;
  color: #0c5460;
  border: 1px solid #bee5eb;
}

/* Warning Alert */
.alert-warning {
  background-color: #fff3cd;
  color: #856404;
  border: 1px solid #ffeeba;
}

.alert-text {
  flex: 1;
}

.close-btn {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: inherit;
  position: absolute;
  right: 10px;
  top: 10px;
}

.close-btn:focus {
  outline: 2px solid #000;
  outline-offset: 2px;
}
</style>