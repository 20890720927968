import { apiUtils } from "@/utils/apiUtils";

export const customerService = {
    async editCustomerDietaryAttributes(attributes) {
        try {
            const result = await apiUtils.doPost('/TCPEditCustomerDietaryAttributesSubmitJ.action', {
                json: "true",
                attributes: attributes
            });

            return result;
        } catch (error) {
            console.error("Error editing customer dietary attributes:", error);
            throw error;
        }
    },
    async changeCustomerLocale(locale) {
        try {
            const result = await apiUtils.doPost('/TCPSetLocaleJ.action', {
                json: "true",
                appLocale: locale,
            });

            return result;
        } catch (error) {
            console.error("Error changing customer locale:", error);
            throw error;
        }
    }
};
