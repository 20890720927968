<template>

  <b-container fluid class="footer">
    <!-- footer: Desktop -->
    <b-row class="d-none d-md-flex">
      <b-col cols="9">
        <div id="footerLinks" class="text-center text-md-left footer-col" v-for="link in config.FOOTER.links" :key="link.Key">
          <a class="mainColor" :href="link.Url" target="_blank">{{ $t(link.Text) }}</a>
        </div>
      </b-col>
      <b-col cols="3">
        <div class="sr-only" v-if="!config.USE_EXTERNAL_LANGUAGE">
          <!-- DO NOT REMOVE ! this is a ScreenReader only element. Screen Readers can Read out elements inside here and Elements are Focusable with Tab. -->
          <div class="float-right">
            <b-button-group v-if="config.LANGUAGE_LIST != undefined && config.LANGUAGE_LIST.length > 1" id="languagesToolbar">
              <b-button id="btnLanguages" class="btn btn-inverted-header" v-for="entry in config.LANGUAGE_LIST" :key="entry.value" v-on:click="changeLocale(entry.value)" :aria-label="$t('ariaLabel.changeLanguageNative', entry.value)" v-bind:aria-pressed="isLanguageActive(entry.value) ? 'true' : 'false'" :lang="entry.value">{{entry.title}}</b-button>
            </b-button-group>
          </div>
        </div>
        <div class="mainColor text-right" v-if="config.SHOW_HEADER_LOGOUT_TIMER" role="timer" aria-live="off">{{ timeRemaining }}</div>
      </b-col>
    </b-row>
    <b-row class="d-none d-md-flex" v-if="config.FOOTER.company.enabled">
      <b-col cols="12">
        <div class="text-center text-md-left footer-col">
          <div class="text-left mainColor" v-html="config.FOOTER.company.html" />
        </div>
      </b-col>
    </b-row>

    <!-- footer: Mobile -->
    <b-row class="d-md-none">
      <b-col cols="4">
        <b-button class="float-left btn btn-inverted-header" v-b-toggle.collapse-footer v-model="isOpen" v-on:click="setToggleState" :aria-label="$t(isOpen?'ariaLabel.footerClose':'ariaLabel.footerOpen')"><b-icon :icon="isOpen ? 'chevron-up' : 'chevron-down'"></b-icon></b-button>
      </b-col>
      <b-col cols="8">
        <div class="sr-only" v-if="!config.USE_EXTERNAL_LANGUAGE">
          <div class="float-right">
            <b-button-group v-if="config.LANGUAGE_LIST != undefined && config.LANGUAGE_LIST.length > 1" id="languagesToolbar">
              <b-button id="btnLanguages" class="btn btn-inverted-header" v-for="entry in config.LANGUAGE_LIST" :key="entry.value" v-on:click="changeLocale(entry.value)" :aria-label="$t('ariaLabel.changeLanguageNative', entry.value)" v-bind:aria-pressed="isLanguageActive(entry.value) ? 'true' : 'false'" :lang="entry.value">{{entry.title}}</b-button>
            </b-button-group>
          </div>
        </div>
        <div class="mainColor text-right" v-if="config.SHOW_HEADER_LOGOUT_TIMER" role="timer" aria-live="off">{{timeRemaining}}</div>
      </b-col>
      <b-collapse id="collapse-footer">
        <b-col cols="12">
          <div class="text-left text-md-left footer-col" v-for="link in config.FOOTER.links" :key="link.Key">
            <a :href="link.Url" class="text-center mainColor" target="_blank">{{ $t(link.Text) }}</a>
          </div>
        </b-col>
        <b-col cols="12" v-if="config.FOOTER.company.enabled">
          <div class="text-left text-md-left footer-col">
            <div class="text-left mainColor" v-html="config.FOOTER.company.html" />
          </div>
        </b-col>
      </b-collapse>
    </b-row>
  </b-container>
</template>

<script>
import { SessionTimer } from "@/store/SessionTimer";
import {mapActions} from "vuex";

export default {
  data() {
    return {
      isOpen: false, // Initially opened
      timer: undefined,
    };
  },
  name:"global-footer-old",
  methods: {
    ...mapActions('locale', ['setLocale']),
    setToggleState() {
      this.isOpen = !this.isOpen;
    },
    isLanguageActive(language)
    {
      return this.$i18n.locale === language;
    },
    changeLocale(locale) {
      this.setLocale(locale);
    },
  },
  props: {
    footerLinks : {
      Type: Array
    },
    config: {
      Type: Array
    },
  },
  computed: {
    activeTime() { // is cached, but updates once "this.timer" or "this.timer.activeTimer" changes
      if (this.timer === undefined)
        return 0;
      return this.timer.activeTimer;
    },
    timeRemaining(){
      if (this.activeTime > 0) {
        var timeString = "";
        let minutesLeft = this.activeTime / 60000;
        if (minutesLeft > 1) {
          timeString = Math.round(minutesLeft) + " min";
        } else {
          timeString = "< 1 min";
        }
        return this.$t('text.timeRemaining') + "" + timeString;
      }
      return "";
    },
  },
  mounted() {
    this.timer = SessionTimer(); // create Memory Store for SessionTimer.
    this.timer.onMount(); // Start Updating Timer with Intervalls
  },
  beforeDestroy() {
    this.timer.onUnmount(); // Stop Updating
  }
}
</script>

<style scoped>
.mainColor {
  color: var(--global-footer-element-color);
}

.footer {
  background-color: var(--global-footer-bg);
  box-shadow: 0 8px 11px 7px black;
  -o-box-shadow: 0 8px 11px 7px black;
  -moz-box-shadow: 0 8px 11px 7px black;
  color: var(--global-footer-color);
  padding: 10px 15px;
  background-blend-mode: darken;
}

.footer-col {
}

@media (max-width: 1200px) {
  .footer {
    width:100%;
  }
}
</style>