<template>
  <!--<div class="editCustomerPageWrapper">-->
  <!--  <div>-->
  <!--    <b-container fluid>-->
      <b-form @submit.stop.prevent>
        <b-form-row v-show="config.CUSTOMER_DATA.FIELDS.firstName.visible || config.CUSTOMER_DATA.FIELDS.lastName.visible" >
          <b-col cols="12" md="6">
            <b-form-group class="form-group fontField" label-for="firstName">
              <template v-slot:label>
                {{ $t('field.firstName') }} <span v-if="config.CUSTOMER_DATA.FIELDS.firstName.required" class="text-danger">*</span>
              </template>
              <b-form-input class="form-control form-control-lg"
                            id="firstName" name="firstName"
                            type="text"
                            v-model="$v.form.firstName.$model"
                            :state="validateState('firstName')"
                            aria-describedby="firstName-feedback"
                            :maxLength="config.CUSTOMER_DATA.FIELDS.firstName.maxLength" />
              <b-form-invalid-feedback class="text-left" id="firstName-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group class="form-group fontField" label-for="lastName">
              <template v-slot:label>
                {{ $t('field.lastName') }} <span v-if="config.CUSTOMER_DATA.FIELDS.lastName.required" class="text-danger">*</span>
              </template>
              <b-form-input class="form-control form-control-lg"
                            id="lastName"
                            name="lastName"
                            type="text"
                            v-model="$v.form.lastName.$model"
                            :state="validateState('lastName')"
                            aria-describedby="lastName-feedback"
                            :maxLength="config.CUSTOMER_DATA.FIELDS.lastName.maxLength" />
              <b-form-invalid-feedback class="text-left" id="lastName-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row v-show="config.CUSTOMER_DATA.FIELDS.streetAddress.visible">
          <b-col cols="12" md="6">
            <b-form-group class="form-group fontField" label-for="streetAddress">
              <template v-slot:label>
                {{ $t('field.street') }} <span v-if="config.CUSTOMER_DATA.FIELDS.streetAddress.required" class="text-danger">*</span>
              </template>
              <b-form-input class="form-control form-control-lg"
                            id="streetAddress"
                            name="streetAddress" type="text"
                            v-model="$v.form.streetAddress.$model"
                            :state="validateState('streetAddress')"
                            aria-describedby="streetAddress-feedback"
                            :maxLength="config.CUSTOMER_DATA.FIELDS.streetAddress.maxLength"/>
              <b-form-invalid-feedback class="text-left" id="streetAddress-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row v-show="config.CUSTOMER_DATA.FIELDS.postcode.visible || config.CUSTOMER_DATA.FIELDS.city.visible" >
          <b-col cols="12" md="6">
            <b-form-group class="form-group fontField" label-for="postcode">
              <template v-slot:label>
                {{ $t('field.postalCode') }} <span v-if="config.CUSTOMER_DATA.FIELDS.postcode.required" class="text-danger">*</span>
              </template>
              <b-form-input class="form-control form-control-lg"
                            id="postcode"
                            name="postcode"
                            type="text"
                            v-model="$v.form.postcode.$model"
                            :state="validateState('postcode')"
                            aria-describedby="postcode-feedback"
                            :maxLength="config.CUSTOMER_DATA.FIELDS.postcode.maxLength"/>
              <b-form-invalid-feedback class="text-left" id="postcode-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group class="form-group fontField" label-for="city">
              <template v-slot:label>
                {{ $t('field.city') }} <span v-if="config.CUSTOMER_DATA.FIELDS.city.required" class="text-danger">*</span>
              </template>
              <b-form-input class="form-control form-control-lg"
                            id="city"
                            name="city"
                            type="text"
                            v-model="$v.form.city.$model"
                            :state="validateState('city')"
                            aria-describedby="city-feedback"
                            :maxLength="config.CUSTOMER_DATA.FIELDS.city.maxLength" />
              <b-form-invalid-feedback class="text-left" id="city-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row v-show="config.CUSTOMER_DATA.FIELDS.countryId.visible">
          <b-col cols="12" md="6">
            <b-form-group class="form-group fontField" label-for="countryId">
              <template v-slot:label>
                {{ $t('field.country') }} <span v-if="config.CUSTOMER_DATA.FIELDS.countryId.required" class="text-danger">*</span>
              </template>
              <select-picker-country
                  id="customerCountry"
                  name="customerCountry"
                  class="countrySelect"
                  v-if="countriesLoaded"
                  :options="countries"
                  :callback="selectCountry"
                  :preselectedId="selectedCountryId"
                  aria-describedby="countryId-feedback"
                  :state="validateState('countryId')" />
              <b-form-invalid-feedback class="text-left" id="countryId-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row v-show="config.CUSTOMER_DATA.FIELDS.telephoneNumber.visible">
          <b-col cols="12" md="6">
            <b-form-group class="form-group fontField" label-for="telephoneNumber">
              <template v-slot:label>
                {{ $t('field.telephoneNumber') }} <span v-if="config.CUSTOMER_DATA.FIELDS.telephoneNumber.required" class="text-danger">*</span>
              </template>
              <b-form-input class="form-control form-control-lg" id="telephoneNumber" name="telephoneNumber" type="text" v-model="$v.form.telephoneNumber.$model"
                            :state="validateState('telephoneNumber')" aria-describedby="telephoneNumber-feedback"/>
              <b-form-invalid-feedback class="text-left" id="telephoneNumber-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row v-show="config.CUSTOMER_DATA.FIELDS.notes1.visible || config.CUSTOMER_DATA.FIELDS.notes2.visible" >
          <b-col cols="12" md="6">
            <b-form-group class="form-group fontField" label-for="notes1">
              <template v-slot:label>
                {{ $t('field.notes1') }} <span v-if="config.CUSTOMER_DATA.FIELDS.notes1.required" class="text-danger">*</span>
              </template>
              <b-form-input class="form-control form-control-lg" id="notes1" name="notes1" type="text" v-model="$v.form.notes1.$model"
                            :state="validateState('notes1')" aria-describedby="notes1-feedback" :maxLength="config.CUSTOMER_DATA.FIELDS.notes1.maxLength"/>
              <b-form-invalid-feedback class="text-left" id="notes1-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group class="form-group fontField" label-for="notes2">
              <template v-slot:label>
                {{ $t('field.notes2') }} <span v-if="config.CUSTOMER_DATA.FIELDS.notes2.required" class="text-danger">*</span>
              </template>
              <b-form-input class="form-control form-control-lg" id="notes2" name="notes2" type="text" v-model="$v.form.notes2.$model"
                            :state="validateState('notes2')" aria-describedby="notes2-feedback" :maxLength="config.CUSTOMER_DATA.FIELDS.notes2.maxLength"/>
              <b-form-invalid-feedback class="text-left" id="notes2-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row v-show="config.CUSTOMER_DATA.FIELDS.notes3.visible || config.CUSTOMER_DATA.FIELDS.notes4.visible" >
          <b-col cols="12" md="6">
            <b-form-group class="form-group fontField" label-for="notes3">
              <template v-slot:label>
                {{ $t('field.notes3') }} <span v-if="config.CUSTOMER_DATA.FIELDS.notes3.required" class="text-danger">*</span>
              </template>
              <b-form-input class="form-control form-control-lg" id="notes3" name="notes3" type="text" v-model="$v.form.notes3.$model"
                            :state="validateState('notes3')" aria-describedby="notes3-feedback" :maxLength="config.CUSTOMER_DATA.FIELDS.notes3.maxLength"/>
              <b-form-invalid-feedback class="text-left" id="notes3-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group class="form-group fontField" label-for="notes4">
              <template v-slot:label>
                {{ $t('field.notes4') }} <span v-if="config.CUSTOMER_DATA.FIELDS.notes4.required" class="text-danger">*</span>
              </template>
              <b-form-input class="form-control form-control-lg" id="notes4" name="notes4" type="text" v-model="$v.form.notes4.$model"
                            :state="validateState('notes4')" aria-describedby="notes4-feedback" :maxLength="config.CUSTOMER_DATA.FIELDS.notes4.maxLength"/>
              <b-form-invalid-feedback class="text-left" id="notes4-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row v-show="config.CUSTOMER_DATA.FIELDS.otherInfos.visible">
          <b-col cols="12" md="12">
            <b-form-group class="form-group fontField" label-for="otherInfos">
              <template v-slot:label>
                {{ $t('field.otherInfos') }} <span v-if="config.CUSTOMER_DATA.FIELDS.otherInfos.required" class="text-danger">*</span>
              </template>
              <b-form-input class="form-control form-control-lg" id="otherInfos" name="otherInfos" type="text" v-model="$v.form.otherInfos.$model"
                            :state="validateState('otherInfos')" aria-describedby="otherInfos-feedback" :maxLength="config.CUSTOMER_DATA.FIELDS.otherInfos.maxLength"/>
              <b-form-invalid-feedback class="text-left" id="otherInfos-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col cols="12" md="12">
            <b-button class="btn btn-primary btn-lg btnSubmit floatRight w-100" type="submit" @click="onSubmit" variant="primary">{{$t('button.submit') }}</b-button>
          </b-col>
          <!-- TODO check for future and make it configurable
          <b-col cols="12" md="6">
            <b-button class="btn btn-primary btn-lg btnSubmit floatRight" @click="resetForm()">{{ $t('button.reset') }}</b-button>
          </b-col>-->
        </b-form-row>
      </b-form>
  <!--  </b-container>-->
  <!--  </div>-->
  <!--</div>-->
</template>

<script>
import selectPickerCountry from "@/components/sub-components/SelectPickerCountry.vue";
import {requiredIf} from "vuelidate/lib/validators";

export default {
  data() {
    return {
      form: {
        firstName: undefined,
        lastName: undefined,
        streetAddress: undefined,
        postcode: undefined,
        city: undefined,
        countryId: undefined,
        telephoneNumber: undefined,
        notes1: undefined,
        notes2: undefined,
        notes3: undefined,
        notes4: undefined,
        otherInfos: undefined,
      },
      countriesLoaded: false,
      countries: {
        Type: Array
      },
      selectedCountry: {
        Type:Object
      },
      selectedCountryId: 0,
    }
  },
  computed: {
    config() {
      return window.config;
    }
  },
  components:{
    'select-picker-country': selectPickerCountry,
  },
  validations: {
    form: {
      firstName: {
        required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.firstName.required),
      },
      lastName: {
        required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.lastName.required),
      },
      streetAddress: {
        required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.streetAddress.required),
      },
      postcode: {
        required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.postcode.required),
      },
      city: {
        required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.city.required),
      },
      countryId: {
        required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.countryId.required),
      },
      telephoneNumber: {
        required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.telephoneNumber.required),
      },
      notes1: {
        required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.notes1.required),
      },
      notes2: {
        required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.notes2.required),
      },
      notes3: {
        required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.notes3.required),
      },
      notes4: {
        required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.notes4.required),
      },
      otherInfos: {
        required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.otherInfos.required),
      },
    }
  },
  methods: {
    validateState(name) {
      const {$dirty, $error} = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    selectCountry(countryId) {
      this.form.countryId = countryId;
    },
    goBack() {
      this.$router.go(-1);
    },
    resetForm() {
      let vueInstance = this;
      vueInstance.fillForm();

      vueInstance.$nextTick(() => {
        vueInstance.$v.$reset();
      });

      vueInstance.makeToast('success', vueInstance.$t('notification.reset'));
    },
    async fillForm() {
      let vueInstance = this;

      const result = await vueInstance.doGet('TCPEditCustomerJ.action', {
        json: "true",
      });

      vueInstance.form.firstName = result.firstName;
      vueInstance.form.lastName = result.lastName;
      vueInstance.form.streetAddress = result.streetAddress,
      vueInstance.form.postcode = result.postcode,
      vueInstance.form.city = result.city;
      vueInstance.form.countryId = result.countryId,
      vueInstance.form.telephoneNumber = result.telephoneNumber,
      vueInstance.form.notes1 = result.notes1,
      vueInstance.form.notes2 = result.notes2,
      vueInstance.form.notes3 = result.notes3,
      vueInstance.form.notes4 = result.notes4,
      vueInstance.form.otherInfos = result.otherInfos,

      vueInstance.countries = result.countries;
      vueInstance.selectedCountry = result.selectedCountry;
      vueInstance.form.countryId = result.selectedCountry.id;
      vueInstance.selectedCountryId = result.selectedCountry.id;
      vueInstance.countriesLoaded = true;
    },
    async onSubmit() {
      let vueInstance = this;

      vueInstance.$v.form.$touch();
      if (vueInstance.$v.form.$anyError) {
        return;
      }

      const result = await vueInstance.doPost('TCPEditCustomerSubmitJ.action', {
        json: "true",
        xsrfToken: window.xtoken != undefined ? btoa(window.xtoken) : undefined,
        firstName: vueInstance.form.firstName,
        lastName: vueInstance.form.lastName,
        telephoneNumber: vueInstance.form.telephoneNumber,
        city: vueInstance.form.city,
        countryId: vueInstance.form.countryId,
        postcode: vueInstance.form.postcode,
        state: vueInstance.form.state,
        streetAddress: vueInstance.form.streetAddress,
        countryChange: "0",
        notes1: vueInstance.form.notes1,
        notes2: vueInstance.form.notes2,
        notes3: vueInstance.form.notes3,
        notes4: vueInstance.form.notes4,
        otherInfos: vueInstance.form.otherInfos,
        privacy: 1,
        tc: 1,
      });

      if (result.exception !== undefined) {
        vueInstance.makeToast('danger', vueInstance.$t('notification.editCustomerError'));
        return;
      }

      vueInstance.makeToast('success', vueInstance.$t('notification.saveSuccessful'));
    }
  },
  created() {
    let vueInstance = this;
    vueInstance.fillForm();
  },
}
</script>