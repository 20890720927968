<template>
  <div class="articleCard"
       @click.stop="viewDetails"
       :style="{'transition': 'height 1s ease-out;', 'box-shadow': product.locked ? 'none' : ''}"
       :class="{ 'articleCardDisabled': quantity < 1 }">

    <div class="articleCardImageGlow background-glow">
      <div v-if="hasArticleImage"
           class="articleCardImage"
           :style="{'background-image': 'url(' + imageUrl + ')', 'filter': product.locked ? 'grayscale(100%)' : ''}" />
    </div>

    <div :class="{'fontArticleTitle articleCartTitle col-12 text-truncate': truncateText, 'fontArticleTitle articleCartTitle col-12': !truncateText}">
      {{ product.name }}
    </div>

    <div style="padding:0; min-height: 55px;">
      <div class="col-12 noMargin" style="padding:0;">
        <div class="fontArticlePrice articleCartPrice col-12" v-if="product.locked">{{ $t('text.articleLocked') }}</div>
        <div class="fontArticlePrice articleCartPrice col-12" v-else>
          {{ product.formattedPrice }}
        </div>
      </div>

      <div class="col-12 noMargin articleCardButtonPlacer">
        <div class="col-12" v-if="!product.locked">
          <b-button class="col-4 btn-override onHover articleCardButton"
                    @click.stop="viewDetails"
                    v-if="isValidSelection && quantity > 0">
            <font-awesome-icon class="addIconMenu" :icon="['fas', 'circle-info']" />
          </b-button>
          <div class="col-4 btn-override onHover articleCardButton"
               v-show="!isInShoppingCart && isValidSelection"></div>
          <b-button class="col-4 btn-override onHover articleCardButton"
                    @click.stop="removeFromBasket"
                    v-show="isInShoppingCart && isValidSelection && quantity > 0">
            <font-awesome-icon class="addIconMenu" :icon="['fas', 'trash-can']" />
          </b-button>
          <b-button class="col-4 btn-override onHover articleCardButton"
                    @click.stop="addToBasket"
                    v-if="isValidSelection && quantity > 0">
            <svg class="addIconMenu" xmlns="http://www.w3.org/2000/svg" width="40.971" height="28.709" viewBox="0 0 40 28">
              <g transform="translate(-1490.289 -272.683)">
                <path d="M1518.26,276.8h-21.411l-.85-4.119h-5.709v2.74h3.477l3.529,17.065h17.2v-2.739h-12.179l13.237-2.128Zm-19.2,10.693-1.645-7.952h17.333l-1.412,5.658Z" style="fill: var(--global-header-color);"></path>
                <path d="M1500.583,294.538a2.958,2.958,0,1,0,2.957,2.959h0A2.96,2.96,0,0,0,1500.583,294.538Zm0,4.119a1.161,1.161,0,1,1,1.161-1.161A1.162,1.162,0,0,1,1500.583,298.657Z" transform="translate(-0.405 -1.208)" style="fill: var(--global-header-color);"></path>
                <path d="M1512.593,294.693a2.958,2.958,0,1,0,2.957,2.959h0A2.961,2.961,0,0,0,1512.593,294.693Zm0,4.119h0a1.161,1.161,0,1,1,1.161-1.161A1.162,1.162,0,0,1,1512.593,298.812Z" transform="translate(-1.069 -1.217)" style="fill: var(--global-header-color);"></path>
              </g>
              <text dominant-baseline="middle" text-anchor="middle" fill="var(--btn-text-color)" font-size="14" x="24" y="19" v-if="shopCartQuantity > 0">{{ shopCartQuantity }}</text>
            </svg>
          </b-button>
          <b-button class="btn-override articleCardButton"
                    @click.stop
                    v-if="quantity < 1">{{ $t('button.notAvailable') }}</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      isValidSelection: false,
      quantity: 100,
      articleOutOfStock: "articleCardDisabled",
      decimalQuantity: false,
      shopCartQuantity: 0,
      selectedProduct: null,
    };
  },
  props: {
    mealPlanId: {
      type: Number,
      default: 0
    },
    product: {
      type: Object,
      required: true
    },
    cartQuantity: {
      type: Number,
      default: 0
    },
  },
  computed: {
    hasArticleImage() {
      return this.product.image1 !== undefined;
    },
    imageUrl() {
      return this.product.image1;
    },
    isInShoppingCart() {
      return this.shopCartQuantity > 0;
    },
    truncateText() {
      return window.config.ARTICLE_CARD_TRUNCATE_DESCRIPTION;
    }
  },
  mounted() {
    this.shopCartQuantity = this.cartQuantity;
  },
  async created() {
    if (!this.product.items) {
      this.isValidSelection = true;
    }
    this.quantity = 100;
  },
  methods: {
    viewDetails() {
      this.$emit('view-details', this.product);
    },
    removeFromBasket() {
      this.$emit('remove-from-basket', this.product);
    },
    addToBasket() {
      if (this.product.locked) {
        return;
      } else if (this.product.custom6 === 1) {
        this.viewDetails();
      } else {
        this.$emit('add-to-basket', this.product);
      }
    },
    baseUrl() {
      return window.imageBaseUrl;
    },
    trimDescription(description) {
      const trimTo = window.config.TRIM_ARTICLE_DESCRIPTION_TO;
      return description.length <= trimTo ? description : description.substring(0, trimTo) + "...";
    },
  }
};
</script>

<style scoped>
.onHover {
  stroke: var(--secondary-color) !important;
  fill: white !important;
}

.onHover:hover {
  background-color: var(--secondary-color);
  color: white;
  stroke: white !important;
  fill: var(--secondary-color) !important;
}

.btn-override {
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  border-radius: 0;
  background-color: transparent;
  border: 0.5px solid transparent;
  font-size: 1rem;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.articleCardButtonPlacer {
  padding: 0;
}

.articleCartPrice {
  text-align: center;
}

.articleCardImageGlow {
  max-height: 100%;
  height: 150px;
  background-size: var(--article-card-image-fit);
  background-repeat: no-repeat;
  background-position: center;
}

@media screen and (max-width: 776px) {
  .articleCardImageGlow {
    height: 100px;
  }
}

.addIconMenu {
  height: 22px;
  margin: 5px 0;
}

.articleCardImage {
  max-height: 100%;
  height: 100%;
  background-size: var(--article-card-image-fit);
  background-repeat: no-repeat;
  background-position: center;
}

.articleCardDisabled {
  opacity: 0.5;
}
</style>
